/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, Component } from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Table,
  Modal,
  Form,
  TextArea,
  Button,
} from 'semantic-ui-react';
import moment from 'moment';
import { get, filter } from 'lodash';
import feedbackIcon from '../../../assets/images/feedback-icon.svg';
import satisfied from '../../../assets/images/satisfied.svg';
import notSatisfied from '../../../assets/images/not-satisfied.svg';
import {
  programUsersRequested,
  sessionUserFeedbackUpdate,
} from '../../../state/actions/programUsersAction';
import programUsersApi from '../../../api/programUsersApi';

const screenWidth = document.documentElement.clientWidth;
const isLargeWideMonitor = screenWidth >= 1200 && screenWidth < 1440;
const ratings = [1, 2, 3, 4, 5];
class ProgramSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      openModal: false,
    };
    this.fetchData();
  }

  fetchData = () => {
    const { program } = this.props;
    const variationId = get(program, 'data.data.versionId') || 0;
    const programId = get(program, 'data.data._id') || 0;
    this.props.fetchUserProgram(programId, variationId, this.props.id);
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  orderSteps = (session, units) => {
    // const sortedArray = session.steps.sort((a,b) =>  {
    //   return moment(a.start).format('h:mm a') -  moment(b.start).format('h:mm a');
    // });
    // const sortedArray = session.steps.sort((a, b) => {
    //   return (moment(a.start).diff(moment(b.start)));
    // });
    const converting = session.steps.map((e) => {
      e.start = new Date(e.start).toISOString();
      return e;
    });
    const sortedArray = converting.sort((a, b) => {
      return a.start.localeCompare(b.start);
    });
    return sortedArray.map((step, indexValue) => (
      <>
        <Table.Row>
          <Table.Cell>
            {step.id === 'preheat' ? 'Preheat' : `Step-${indexValue}`}
          </Table.Cell>
          <Table.Cell>{moment(get(step, 'start')).format('h:mm a')}</Table.Cell>
          <Table.Cell rowSpan="2" textAlign="center">
            {step.mode.name}
          </Table.Cell>
          <Table.Cell rowSpan="2" textAlign="center">
            {step.temperature
              ? `${step.temperature}${
                  get(units, 'temperature') === 'fahrenheit' ? '°F' : '°C'
                }`
              : ''}
          </Table.Cell>
          {get(session, 'type') === 'probe' ? (
            <Table.Cell>
              {step.startInternalTemperature
                ? `${step.startInternalTemperature}${
                    get(units, 'temperature') === 'fahrenheit' ? '°F' : '°C'
                  }`
                : ''}
            </Table.Cell>
          ) : (
            ''
          )}
        </Table.Row>
        <Table.Row>
          <Table.Cell />
          <Table.Cell>
            {step.end ? moment(get(step, 'end')).format('h:mm a') : '-'}
          </Table.Cell>
          {/* <Table.Cell /> */}
          {/* <Table.Cell /> */}
          {get(session, 'type') === 'probe' ? (
            <Table.Cell>
              {step.endInternalTemperature
                ? `${step.endInternalTemperature}°F`
                : ''}
            </Table.Cell>
          ) : (
            ''
          )}
        </Table.Row>
      </>
    ));
  };

  handleSubmit = async (program, index) => {
    const { programUsers } = this.props;
    const programId = get(program, 'data.data._id');
    const feedbackData = get(programUsers, 'data');
    this.setState({ openModal: false });
    const internal = feedbackData[index].internalFeedback;
    const payload = {
      internalFeedback: {
        rating: internal.rating,
        feedback: internal.feedback,
      },
    };
    const response = await programUsersApi.userUpdateFeedback(
      payload,
      programId
    );
    if (response.error) {
      // console.log(response.error);
      // return;
    }
  };

  render() {
    const { activeIndex, openModal } = this.state;
    const { programUsers, program, postFormData } = this.props;
    const sessionData = get(programUsers, 'data') || 0;
    const units = get(program, 'data.data.units') || 0;
    const completeCount =
      sessionData &&
      sessionData !== 0 &&
      filter(programUsers.data, ['status', 'completed']).length;
    const failedCount =
      sessionData &&
      sessionData !== 0 &&
      filter(programUsers.data, ['status', 'failed']).length;

    return (
      <>
        <div className="d-flex align-center">
          <Header as="h5" className="font-regular m-0">
            Program Sessions
          </Header>
          <div className="graph-label d-flex">
            <div className="mr-20 d-flex align align-center">
              <Label
                size="mini"
                circular
                className="completed"
                content={completeCount}
              />{' '}
              Completed
            </div>
            <div className="d-flex align align-center">
              <Label
                size="mini"
                circular
                className="failed"
                content={failedCount}
              />{' '}
              Failed
            </div>
          </div>
        </div>
        <Accordion styled fluid className="program-session-acc">
          {sessionData && sessionData !== 0 ? (
            sessionData.map((session, i) => (
              <div>
                <Accordion.Title
                  active={activeIndex === i}
                  index={i}
                  onClick={this.handleClick}
                  className="d-flex align-center justify-content-between"
                >
                  <Grid divided>
                    <Grid.Row>
                      <Grid.Column width="5" className="d-flex align-center">
                        <Header as="h6">
                          {get(session, 'oven.brand.name')} -{' '}
                          {get(session, 'oven.vib')}
                        </Header>
                      </Grid.Column>
                      <Grid.Column width="5" className="d-flex align-center">
                        <Header as="h6" className="font-regular">
                          <span className="mr-20">
                            {' '}
                            {moment(get(session, 'startTime')).format(
                              ' Do MMM YYYY, h:mm a'
                            )}
                            {get(session, 'endTime')
                              ? ` - ${moment(get(session, 'endTime')).format(
                                  'h:mm a'
                                )}`
                              : ''}{' '}
                          </span>
                        </Header>
                      </Grid.Column>
                      <Grid.Column
                        width="2"
                        className="d-flex align-center justify-center"
                      >
                        <Header
                          as="h6"
                          className={`${
                            get(session, 'status') === 'failed'
                              ? 'failed'
                              : 'success'
                          } text-capitalize`}
                        >
                          {get(session, 'status')}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Icon name="dropdown" className="pull-right" />
                </Accordion.Title>
                <Accordion.Content active={activeIndex === i} className="p-0">
                  <Grid className="m-0">
                    <Grid.Column width={isLargeWideMonitor ? 16 : 11}>
                      <Grid>
                        <Grid.Row className="bg-grey">
                          <Grid.Column width={5}>
                            <Table basic="very" className="prog-details" padded>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell colSpan="2">
                                    Input
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell>
                                    {get(session, 'input.key')}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {get(session, 'input.value')}
                                    {get(units, get(session, 'input.key'))}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>Quantity</Table.Cell>
                                  <Table.Cell>
                                    {get(session, 'input.quantity')}
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                            <Table basic="very" className="prog-details" padded>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell colSpan="2">
                                    Oven Operations
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell>Probe-based</Table.Cell>
                                  <Table.Cell>
                                    {get(session, 'type') === 'probe'
                                      ? 'Yes'
                                      : 'No'}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>Outcome</Table.Cell>
                                  <Table.Cell>
                                    {get(session, 'outcome.value')}
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </Grid.Column>
                          <Grid.Column width={11} className="pl-0">
                            <Table celled basic>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Steps</Table.HeaderCell>
                                  <Table.HeaderCell>Timeline</Table.HeaderCell>
                                  <Table.HeaderCell>Mode</Table.HeaderCell>
                                  <Table.HeaderCell>Temp.</Table.HeaderCell>
                                  {get(session, 'type') === 'probe' ? (
                                    <Table.HeaderCell>
                                      Probe Temp.
                                    </Table.HeaderCell>
                                  ) : (
                                    ''
                                  )}
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {session &&
                                session.steps &&
                                session.steps.length > 0 ? (
                                  <>{this.orderSteps(session, units)}</>
                                ) : (
                                  <Table.Row>
                                    <Table.Cell colSpan="8" textAlign="center">
                                      <div className="no-data">
                                        No records found
                                      </div>
                                    </Table.Cell>
                                  </Table.Row>
                                )}
                                <Table.Row
                                  className={
                                    get(session, 'status') === 'failed'
                                      ? 'failed'
                                      : 'success'
                                  }
                                >
                                  <Table.Cell className="text-capitalize">
                                    {get(session, 'status')}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {get(session, 'status') === 'completed'
                                      ? session &&
                                        session.steps &&
                                        session.steps.length > 0 &&
                                        moment(
                                          session.steps[
                                            session.steps.length - 1
                                          ].end
                                        ).format('h:mm a')
                                      : session &&
                                        session.steps &&
                                        session.steps.length > 0 &&
                                        moment(
                                          session.steps[
                                            session.steps.length - 1
                                          ].start
                                        ).format('h:mm a')}
                                  </Table.Cell>
                                  <Table.Cell />
                                  <Table.Cell />
                                  {get(session, 'type') === 'probe' ? (
                                    <Table.Cell />
                                  ) : (
                                    ''
                                  )}
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column
                      width={isLargeWideMonitor ? 16 : 5}
                      className="consumer-feedback"
                    >
                      <div className="d-flex align-center justify-content-between">
                        <Header as="h5" className="m-0">
                          Consumer Feedback
                        </Header>
                        {(get(session, 'userFeedback') !== undefined &&
                          get(session, 'userFeedback.rating') ===
                            'satisfied') ||
                        get(session, 'userFeedback.rating') ===
                          'dissatisfied' ? (
                          <Label image color="green">
                            <Image
                              src={
                                get(session, 'userFeedback.rating') ===
                                'satisfied'
                                  ? satisfied
                                  : notSatisfied
                              }
                              className="mr-10"
                            />
                            <span className="text-capitalize">
                              {get(session, 'userFeedback.rating')}
                            </span>
                          </Label>
                        ) : (
                          ''
                        )}
                      </div>
                      <Header as="h6" className="mt-15">
                        Comment
                      </Header>
                      <p>
                        {get(session, 'userFeedback.reason')}
                        <br />
                        {get(session, 'userFeedback.feedback')}
                      </p>
                      <Divider />
                      <div className="bg-grey">
                        <Header
                          as="h5"
                          className="d-flex align-center justify-content-between"
                        >
                          Internal feedback of this session
                          <span
                            className="d-flex align-center"
                            style={{ width: '80px' }}
                          >
                            {get(session, 'internalFeedback.rating') ? (
                              <>
                                <Label
                                  circular
                                  className={
                                    get(session, 'internalFeedback.rating') >= 3
                                      ? 'rating-label light-success'
                                      : 'rating-label light-failed'
                                  }
                                >
                                  {get(session, 'internalFeedback.rating')}
                                </Label>{' '}
                                / 5
                              </>
                            ) : (
                              ''
                            )}
                          </span>
                          <Modal
                            size="small"
                            trigger={
                              <Image
                                src={feedbackIcon}
                                className="pointer ml-10 mr-10 text-right"
                                size="mini"
                              />
                            }
                            className="feedback-modal"
                            onClose={() => this.setState({ openModal: false })}
                            onOpen={() => this.setState({ openModal: true })}
                            open={openModal}
                          >
                            <Modal.Content>
                              <div className="title-wrap d-flex justify-content-between align-center">
                                <div className="d-flex align-center">
                                  <Image src={feedbackIcon} className="mr-10" />
                                  Internal feedback of this session
                                </div>
                                {/* <div>Last updated: 28th Oct 2020 | 13:12</div> */}
                              </div>
                              <Form className="mt-15">
                                <TextArea
                                  rows="5"
                                  value={
                                    get(session, 'internalFeedback.feedback') ||
                                    ''
                                  }
                                  onChange={(e) => {
                                    const userSessionData = get(
                                      programUsers,
                                      'data'
                                    );
                                    if (!userSessionData[i].internalFeedback)
                                      userSessionData[i].internalFeedback = {};
                                    userSessionData[
                                      i
                                    ].internalFeedback.feedback =
                                      e.target.value;
                                    postFormData({ data: userSessionData });
                                  }}
                                />
                                <div className="d-flex justify-content-between align-center mt-15">
                                  <div className="rating-col">
                                    <span className="mr-20">Ratings</span>
                                    {ratings.map((r) => (
                                      <Label
                                        circular
                                        key={r}
                                        active={
                                          Number(
                                            get(
                                              session,
                                              'internalFeedback.rating'
                                            )
                                          ) || 4
                                        }
                                        className={
                                          r ===
                                          (get(
                                            session,
                                            'internalFeedback.rating'
                                          ) || 4)
                                            ? 'light-success pointer rating-label'
                                            : 'pointer rating-label'
                                        }
                                        onClick={(e, data) => {
                                          const userSessionData = get(
                                            programUsers,
                                            'data'
                                          );
                                          if (
                                            !userSessionData[i].internalFeedback
                                          )
                                            userSessionData[
                                              i
                                            ].internalFeedback = {};
                                          userSessionData[
                                            i
                                          ].internalFeedback.rating =
                                            data.children;
                                          postFormData({
                                            data: userSessionData,
                                          });
                                        }}
                                      >
                                        {r}
                                      </Label>
                                    ))}
                                  </div>
                                  <div className="button-wrapper">
                                    <Button
                                      compact
                                      className="link-button"
                                      onClick={() =>
                                        this.setState({ openModal: false })
                                      }
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      secondary
                                      compact
                                      onClick={() =>
                                        this.handleSubmit(program, i)
                                      }
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            </Modal.Content>
                          </Modal>
                        </Header>
                        <div className="d-flex align-center justify-content-between">
                          {/* <p>
                            <span>Last updated </span>
                            <b>28th Oct 2020 | 13:12</b>
                          </p> */}
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid>
                </Accordion.Content>
              </div>
            ))
          ) : (
            <div className="no-data mt-125">No records found</div>
          )}
        </Accordion>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.program.program.isLoading,
    program: state.program.program,
    programUsers: state.programUsers.programUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProgram: (programId, variationId, userId) =>
      dispatch(programUsersRequested({ programId, variationId, userId })),
    postFormData: (data) => dispatch(sessionUserFeedbackUpdate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramSessions);
