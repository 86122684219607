import React, { useState } from 'react';
import { Button, Form, Grid, Header, Input } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import fetchToken from '../../../api/loginApi';
// import logo from '../../../assets/images/B-S-H-black.svg';

import {
  valueValidation,
  getLocalStorageValue,
} from '../../../utils/sharedFunctions';

const code = getLocalStorageValue('accessToken');

const Login = () => {
  const [inputs, setInputs] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});

  const history = useHistory();

  if (code) history.push('/dashboard');
  const handleInputChange = (event) => {
    setInputs((inputvalue) => {
      return { ...inputvalue, [event.target.name]: event.target.value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = valueValidation(inputs);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    if (noErrors) {
      // window.location = loginUrl;
      const user = {
        email: inputs.email,
        password: inputs.password,
      };
      const response = await fetchToken(user);
      if (response.response) {
        history.push('/dashboard');
      }
      if (response.error) {
        // console.log('Message', response.error.message);
        setErrors({ errorMessage: response.error.message });
      }
    } else {
      setErrors(validationErrors);
      // console.log('Errors try again',validationErrors);
    }
  };
  return (
    <div className="login-wrapper">
      {/* <Container> */}
      <Grid columns="2" className="m-0">
        <Grid.Column className="left-side">
          <Header as="h1">
            Autopilot
            <br />
            <span>Oven</span>
          </Header>
        </Grid.Column>
        <Grid.Column className="right-side d-flex align-center justify-center">
          <div className="login-right-sidebar">
            <Header as="h3">
              <span>Login to your account</span>
              <br />
              and get started!
            </Header>
            <Form>
              <Form.Field>
                <Input
                  placeholder="Username/Email"
                  type="email"
                  required
                  onChange={handleInputChange}
                  name="email"
                />
                {errors.email && <p className="error-note">{errors.email}</p>}
              </Form.Field>
              <Form.Field>
                <Input
                  placeholder="Password"
                  type="password"
                  required
                  onChange={handleInputChange}
                  name="password"
                />
                {errors.password && (
                  <p className="error-note">{errors.password}</p>
                )}
              </Form.Field>
              <Button icon labelPosition="center" onClick={handleSubmit}>
                Login
              </Button>
              {errors.errorMessage && (
                <p className="error-note text-center">{errors.errorMessage}</p>
              )}
              {/* <div className="link-wrapper text-center">
                <a href>Forgot password?</a>
              </div> */}
            </Form>
          </div>
        </Grid.Column>
      </Grid>
      {/* </Container> */}
    </div>
  );
};

export default Login;
