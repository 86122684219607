import { put, call, takeLatest } from 'redux-saga/effects';
import filesApi from '../../api/filesApi';
import { FILES_LISTING_REQUESTED } from '../../constants/actions';
import {
  fetchFilesFailure,
  fetchFilesSuccessful,
} from '../actions/fileActions';

function* fetchFiles(action) {
  const { response, error } = yield call(filesApi.fetchFiles, action.payload);
  if (response) {
    yield put(fetchFilesSuccessful(response));
  } else {
    yield put(fetchFilesFailure(error));
  }
}

export default function* filesSagas() {
  yield takeLatest(FILES_LISTING_REQUESTED, fetchFiles);
}
