import {
  FILTER_LISTING_REQUESTED,
  FILTER_LISTING_FAILURE,
  FILTER_LISTING_SUCCESSFUL,
} from '../../constants/actions';

export const fetchFilterRequested = (data) => {
  return {
    type: FILTER_LISTING_REQUESTED,
    payload: data,
  };
};

export const fetchFilterSuccessful = (data) => {
  return {
    type: FILTER_LISTING_SUCCESSFUL,
    payload: data,
  };
};

export const fetchFilterFailure = (data) => {
  return {
    type: FILTER_LISTING_FAILURE,
    payload: data,
  };
};
