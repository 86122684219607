import axios from 'axios';
import { normalize } from 'normalizr';
import { userList } from '../state/schemas';
import ApiUtils from './apiUtils';
import { getLocalStorageValue } from '../utils/sharedFunctions';

const fetchUser = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);

  try {
    const response = await ApiUtils.get(`api/users?${queryString}`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, userList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const userActive = (feedback, id) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/users/${id}/`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .put(tokenUrl, feedback, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

export default {
  fetchUser,
  userActive,
};
