import {
  LOGIN_REQUESTED,
  LOGIN_SUCCESSFULL,
  LOGIN_FAILURE,
  VALIDATE_TOKEN_REQUESTED,
  VALIDATE_TOKEN_SUCCESSFULL,
  VALIDATE_TOKEN_FAILURE,
} from '../../constants/actions';

export const loginRequested = (authCode) => {
  return {
    type: LOGIN_REQUESTED,
    payload: authCode,
  };
};

export const loginSuccessfull = (authInformation) => {
  return {
    type: LOGIN_SUCCESSFULL,
    payload: authInformation,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

export const validateTokenRequested = () => {
  return {
    type: VALIDATE_TOKEN_REQUESTED,
  };
};

export const validateTokenSuccessfull = () => {
  return {
    type: VALIDATE_TOKEN_SUCCESSFULL,
  };
};

export const validateTokenFailure = () => {
  return {
    type: VALIDATE_TOKEN_FAILURE,
  };
};
