import axios from 'axios';
import { normalize } from 'normalizr';
import { get } from 'lodash';
import { programsList } from '../state/schemas';
import ApiUtils from './apiUtils';
import fetchProgramUsers from './programUsersApi';
import { getLocalStorageValue, transformData } from '../utils/sharedFunctions';

const fetchPrograms = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);
  try {
    const response = await ApiUtils.get(`api/programs?${queryString}`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, programsList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const fetchProgram = async (params) => {
  const { id, isTransform = false, requireProgramUser = false } = params;
  try {
    const response = await ApiUtils.get(`api/programs/${id}`);
    const temp = isTransform ? transformData(response) : response;
    const { data } = temp;
    const programId = get(temp, 'data.data._id');
    const versionId = get(temp, 'data.data.versionId');
    const programUserResponse = requireProgramUser
      ? await fetchProgramUsers.fetchProgramUsers(programId, versionId)
      : '';
    const userResponsedata = {
      userResponsedata: get(programUserResponse, 'response'),
    };
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, programsList)
        : [];
    return {
      response: {
        ...temp,
        ...userResponsedata,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const addPrograms = (program) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/programs`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .post(tokenUrl, program, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

const updateProgram = (program, id) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/programs/${id}`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .put(tokenUrl, program, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

export default {
  fetchPrograms,
  fetchProgram,
  addPrograms,
  updateProgram,
};
