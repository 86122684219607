/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  List,
  Modal,
  Segment,
  Sidebar,
  Label,
} from 'semantic-ui-react';
import { get } from 'lodash';
import Select from 'react-select';
import MainHeader from '../../ui/MainHeader';
import Navbar from '../../ui/Navbar';
import MainBreadcrumb from '../../ui/MainBreadcrumb';
import TopSectionCreateProgram from './TopSectionCreateProgram';
import UserInstructionForm from './UserInstructionForm';
import OvenOperationsForm from './OvenOperationsForm';
import { fetchregionRequested } from '../../../state/actions/regionAction';
import { fetchBrandsRequested } from '../../../state/actions/brandsActions';
import CustomFunctions from '../../../utils/customFunctions';
import { programFormRequested } from '../../../state/actions/programAction';
import programsApi from '../../../api/programsApi';

class CreateProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      visible: true,
      selectedRegion: '',
      selectedCountry: '',
      selectedCavity: [],
      commonModes: [],
      cavityModes: {},
      isValid: true,
      units: {},
      isTypeError: {},
      isTypeErrorForProbeTime: [],
      urlError: {
        url1: false,
        url2: false,
      },
    };
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCavity } = this.state;
    if (selectedCavity.length !== prevState.selectedCavity.length) {
      this.handleCommonModes();
    }
  }

  handleCommonModes = () => {
    const count = {};
    const common = [];
    const { postFormData } = this.props;
    const { selectedCavity, cavityModes } = this.state;
    selectedCavity.map((itemId) =>
      cavityModes[itemId].forEach((ele) => {
        if (count[ele.name]) {
          count[ele.name].count += 1;
        } else {
          count[ele.name] = {
            count: 1,
            name: ele.name,
            meatProbe: ele.meatProbe,
            key: ele.key,
          };
        }
      })
    );
    Object.keys(count).forEach((key) => {
      if (count[key].count === selectedCavity.length) common.push(count[key]);
    });
    this.setState({ commonModes: common });
    postFormData({ commonModes: common });
  };

  fetchData = () => {
    this.props.fetchRegion();
    this.props.fetchBrands();
  };

  getCavityandModes = (data) => {
    const cavityModes = {};
    data.forEach((element) => {
      element.cavities.forEach((cavity) => {
        cavityModes[cavity.id] = cavity.modes;
      });
    });
    return cavityModes;
  };

  listOfModes = () => {
    const { commonModes } = this.state;
    const modesOption =
      commonModes && commonModes.length > 0 ? (
        commonModes.map((item) => (
          <List.Item>
            {/* below code is required for developer */}
            {/* <Image src={} verticalAlign='middle' /> */}
            <List.Content>{item.name}</List.Content>
          </List.Item>
        ))
      ) : (
        <p>No modes</p>
      );
    return modesOption;
  };

  getbrands = (brands) => {
    const cavityModes = this.getCavityandModes(brands);
    const formatGroupLabel = (data) => (
      <div>
        <span>{data.label}</span>
      </div>
    );
    const groupOption =
      brands && brands.length > 0
        ? brands.map((brand) => {
            return {
              label: brand.name,
              options:
                brand.cavities && brand.cavities.length > 0
                  ? brand.cavities.map((cavity) => {
                      return { value: cavity.id, label: cavity.name };
                    })
                  : '',
            };
          })
        : '';
    const brandsOption =
      brands && brands.length > 0 ? (
        <Select
          options={groupOption}
          formatGroupLabel={formatGroupLabel}
          isMulti="true"
          onChange={(e) => {
            this.handleCavityChange(e, cavityModes);
          }}
        />
      ) : (
        <Label basic color="red" pointing>
          No cavities
        </Label>
      );
    return brandsOption;
  };

  getcountries = () => {
    const { regions } = this.props;
    const { selectedRegion } = this.state;
    if (!regions || !regions.data) return [];
    const countriesOptions = [];
    regions.data.map((region) => {
      if (selectedRegion === region._id) {
        if (region.countries && region.countries.length > 0) {
          region.countries.map((country) => {
            return countriesOptions.push({
              key: country.id,
              text: country.name,
              value: country.id,
            });
          });
        }
      }
      return [];
    });
    return countriesOptions;
  };

  getregions = () => {
    const { regions } = this.props;
    if (!regions || !regions.data) return [];
    const regionsOptions = [];
    regions.data.map((region) => {
      return regionsOptions.push({
        key: region._id,
        text: region.name,
        value: region._id,
      });
    });
    return regionsOptions;
  };

  validateData = () => {
    const { selectedRegion, selectedCountry, selectedCavity } = this.state;
    const data = this.props.program;
    let isValid = true;
    const isTypeError = {};
    const isTypeErrorForProbeTime = [];
    const urlError = {};
    const selectedRadio = data.type ? data.type : 'probeBased';

    const stepCount = data && data.stepsCount ? data.stepsCount : 1;
    const columnCount = data && data.columnCount ? data.columnCount : 1;

    if (CustomFunctions.checkIfEmpty(data.programTitle)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.internalTitle)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.categoryValue)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.description)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.fileBlobUrl)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(selectedRegion)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(selectedCountry)) {
      isValid = false;
    }

    if (
      CustomFunctions.checkIfEmpty(selectedCavity) ||
      selectedCavity.length === 0
    ) {
      isValid = false;
    }

    if (
      !CustomFunctions.checkIfEmpty(data.refUrlOne) &&
      !CustomFunctions.validateUrl(data.refUrlOne)
    ) {
      urlError.url1 = true;
      isValid = false;
    }

    if (
      !CustomFunctions.checkIfEmpty(data.refUrlTwo) &&
      !CustomFunctions.validateUrl(data.refUrlTwo)
    ) {
      urlError.url2 = true;
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.selectedbaseRadioValue)) {
      isValid = false;
    }

    if (
      !CustomFunctions.checkIfEmpty(data.selectedbaseRadioValue) &&
      !data.selectedbaseRadioValue.match(/^\d+(\.\d{1,2})?$/)
    ) {
      isTypeError.selectedbaseRadioValue = true;
      isValid = false;
    }

    if (data.selectedQuantity === undefined) {
      isValid = false;
    }

    if (
      data.preheat &&
      data.preheat.alert === true &&
      CustomFunctions.checkIfEmpty(data.preheat.value)
    ) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.extraThickness)) {
      isValid = false;
    }
    if (
      data.extraThickness &&
      !data.extraThickness.match(/^\d+(\.\d{1,2})?$/)
    ) {
      isTypeError.extraThickness = true;
      isValid = false;
    }

    if (data.extraQuantity === undefined) {
      isValid = false;
    }

    if (
      CustomFunctions.checkIfEmpty(data.cookingMode) ||
      data.cookingMode.length !== stepCount
    ) {
      isValid = false;
    }

    if (data.inputValue && data.inputValue.length === columnCount) {
      data.inputValue.forEach((inputValue) => {
        if (CustomFunctions.checkIfEmpty(inputValue.outcome)) {
          isValid = false;
        }
        if (CustomFunctions.checkIfEmpty(inputValue.image)) {
          isValid = false;
        }

        if (inputValue.cookingTimeVariation) {
          if (
            CustomFunctions.checkIfEmpty(
              inputValue.cookingTimeVariation.thicknessTime
            )
          ) {
            isValid = false;
          } else if (
            !inputValue.cookingTimeVariation.thicknessTime.match(
              /^\d+(\.\d{1,2})?$/
            )
          ) {
            isValid = false;
          }

          if (
            CustomFunctions.checkIfEmpty(
              inputValue.cookingTimeVariation.quantityTime
            )
          ) {
            isValid = false;
          } else if (
            !inputValue.cookingTimeVariation.quantityTime.match(
              /^\d+(\.\d{1,2})?$/
            )
          ) {
            isValid = false;
          }
        } else {
          isValid = false;
        }
        if (
          inputValue.precedingCookingStep &&
          inputValue.precedingCookingStep.length === stepCount
        ) {
          inputValue.precedingCookingStep.forEach((cookingStep) => {
            if (cookingStep) {
              if (!cookingStep.temperature) {
                isValid = false;
              }

              if (selectedRadio === 'timeBased') {
                if (
                  (cookingStep.cookingTime === undefined &&
                    cookingStep.cookingTimeinMin === undefined) ||
                  (cookingStep.cookingTime === '' &&
                    cookingStep.cookingTimeinMin === '') ||
                  (cookingStep.cookingTime === '' &&
                    cookingStep.cookingTimeinMin === undefined) ||
                  (cookingStep.cookingTime === undefined &&
                    cookingStep.cookingTimeinMin === '')
                ) {
                  isValid = false;
                } else if (
                  (cookingStep.cookingTime &&
                    !cookingStep.cookingTime.match(/^[1-9][0-9]*$/)) ||
                  (cookingStep.cookingTimeinMin &&
                    !cookingStep.cookingTimeinMin.match(/^[1-9][0-9]*$/)) ||
                  (cookingStep.cookingTime &&
                    cookingStep.cookingTimeinMin &&
                    !cookingStep.cookingTime.match(/^[1-9][0-9]*$/) &&
                    !cookingStep.cookingTimeinMin.match(/^[1-9][0-9]*$/))
                ) {
                  isValid = false;
                }
              }

              if (
                selectedRadio === 'probeBased' &&
                !cookingStep.internalTemperature
              ) {
                isValid = false;
              }
            } else if (cookingStep === undefined) {
              isValid = false;
            }
          });
        } else {
          isValid = false;
        }

        if (selectedRadio === 'probeBased') {
          if (inputValue.probeBased) {
            if (
              (inputValue.probeBased.time === '' &&
                inputValue.probeBased.timeinMin === '') ||
              (inputValue.probeBased.time === '' &&
                inputValue.probeBased.timeinMin === undefined) ||
              (inputValue.probeBased.time === undefined &&
                inputValue.probeBased.timeinMin === '')
            ) {
              isValid = false;
            } else if (
              (inputValue.probeBased.time &&
                !inputValue.probeBased.time.match(/^[1-9][0-9]*$/)) ||
              (inputValue.probeBased.timeinMin &&
                !inputValue.probeBased.timeinMin.match(/^[1-9][0-9]*$/)) ||
              (inputValue.probeBased.time &&
                inputValue.probeBased.timeinMin &&
                !inputValue.probeBased.time.match(/^[1-9][0-9]*$/) &&
                !inputValue.probeBased.timeinMin.match(/^[1-9][0-9]*$/))
            ) {
              isTypeErrorForProbeTime.push(true);
              isValid = false;
            } else {
              isTypeErrorForProbeTime.push(false);
            }
          } else if (inputValue.probeBased === undefined) {
            isValid = false;
          }
        }
      });
    } else {
      isValid = false;
    }
    this.setState({
      isValid,
      isTypeError,
      urlError,
      isTypeErrorForProbeTime,
    });
    return isValid;
  };

  resetData = () => {
    this.setState({
      selectedRegion: '',
      selectedCountry: '',
      selectedCavity: [],
      isValid: false,
    });
  };

  handleChange = (e, { value }) => {
    const { postFormData, regions } = this.props;
    const item = regions.data.find((x) => x._id === value);
    this.setState({ selectedRegion: value, units: item.units });
    postFormData({ selectedRegion: value });
  };

  handleCountryChange = (e, { value }) => {
    const { postFormData } = this.props;
    this.setState({ selectedCountry: value });
    postFormData({ selectedCountry: value });
  };

  calculateTimeinMin = (time) => {
    const timeInhr = get(time, 'probeBased.time') || 0;
    const timeInMin = get(time, 'probeBased.timeinMin') || 0;
    const total = Number(timeInMin) + Number(timeInhr) * 60;
    return total;
  };

  cookingTimeCalculation = (step) => {
    const timeInhr = get(step, 'cookingTime') || 0;
    const timeInMin = get(step, 'cookingTimeinMin') || 0;
    const total = Number(timeInMin) + Number(timeInhr) * 60;
    return total;
  };

  createProgram = async () => {
    if (!this.validateData()) return;
    const data = this.props.program;
    const payload = {
      title: data.programTitle,
      internalTitle: data.internalTitle,
      categoryId: data.categoryValue,
      tags: data.selectedTags,
      filters: data.filterValue,
      reference: {
        description: data.description2,
        urls: [data.refUrlOne, data.refUrlTwo],
      },
      description: data.description,
      images: data.fileBlobUrl && data.fileBlobUrl.map((item) => item.id),
      regionId: data.selectedRegion,
      countryId: data.selectedCountry,
      cavities: data.selectedCavity,
      userInstructions:
        data.instructionArr &&
        data.instructionArr.map((item, index) => {
          const image = item.fileBlobUrl ? item.fileBlobUrl.id : null;
          return {
            instruction: item.instruction,
            url: item.refUrl,
            icon: item.selectedIconId,
            image,
            order: index,
          };
        }),
      baseParameters: {
        key: data.selectedbaseRadio,
        value: data.selectedbaseRadioValue,
        quantity: data.selectedQuantity,
      },
      type: data.type === 'timeBased' ? 'time' : 'probe',
      preheat:
        data.preheat && data.preheat.alert === true
          ? { text: data.preheat.value }
          : null,
      operations:
        data.inputValue &&
        data.inputValue.map((e) => {
          return {
            outcome: e.outcome,
            image: e.image && e.image.id,
            time:
              data.type === 'timeBased'
                ? e.precedingCookingStep &&
                  e.precedingCookingStep.reduce((a, b) => {
                    let sum = a;
                    if (b.cookingTime) sum += Number(b.cookingTime * 60);
                    if (b.cookingTimeinMin) sum += Number(b.cookingTimeinMin);
                    return sum;
                  }, 0)
                : this.calculateTimeinMin(e),
            variations: {
              key: data.selectedbaseRadio,
              value: data.extraThickness,
              variation:
                e.cookingTimeVariation && e.cookingTimeVariation.thicknessTime,
              quantity: {
                value: data.extraQuantity,
                variation:
                  e.cookingTimeVariation && e.cookingTimeVariation.quantityTime,
              },
            },
            steps:
              e.precedingCookingStep &&
              e.precedingCookingStep.map((step, i) => {
                return {
                  mode: data.cookingMode && data.cookingMode[i].key,
                  temperature: step.temperature,
                  cookingTime: this.cookingTimeCalculation(step),
                  internalTemperature: step.internalTemperature,
                  alert: data.alert && data.alert[i] && data.alert[i].alert,
                  order: i,
                };
              }),
          };
        }),
    };
    const response = await programsApi.addPrograms(payload);
    if (response.response) {
      const { _id } = response.response;
      this.props.history.push(`/programs/${_id}`);
    }
    if (response.error) {
      // console.log(response.error);
      // return;
    }
  };

  cancelProgram = () => {
    this.props.history.push('/programs');
  };

  handleCavityChange = (e, cavityModes) => {
    const { postFormData } = this.props;
    this.setState({ cavityModes });
    const cavities = e.map((cavity) => {
      return cavity.value;
    });
    this.setState({ selectedCavity: cavities });
    postFormData({ selectedCavity: cavities });
  };

  render() {
    const { brands } = this.props;
    const {
      selectedRegion,
      selectedCountry,
      commonModes,
      selectedCavity,
      openModal,
      visible,
      isValid,
      units,
      isTypeError,
      isTypeErrorForProbeTime,
      urlError,
    } = this.state;
    const data = brands && brands.data ? brands : 0;

    return (
      <Sidebar.Pushable as={Segment} className="d-flex">
        <Navbar name="createPrograms" />
        <Sidebar.Pusher>
          <MainHeader name="Create Program" />
          <MainBreadcrumb step1="Dashboard" step2="Create Program" />
          <div className="inner-wrapper">
            <Form>
              <Grid>
                <TopSectionCreateProgram
                  isValid={isValid}
                  urlError={urlError}
                />
                <Grid.Row>
                  <Grid.Column verticalAlign="middle" width={16}>
                    <Form.Group>
                      <Form.Field
                        width="4"
                        error={!selectedRegion && !isValid ? 'error' : ''}
                      >
                        <label>Region</label>
                        <Dropdown
                          className="large"
                          options={[...this.getregions()]}
                          placeholder="Region"
                          selection
                          fluid
                          name="selectedRegion"
                          value={selectedRegion}
                          onChange={(e, regionValue) =>
                            this.handleChange(e, regionValue)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        width="4"
                        error={!selectedCountry && !isValid ? 'error' : ''}
                      >
                        <label>Country</label>
                        <Dropdown
                          className="large"
                          options={[...this.getcountries()]}
                          placeholder="Country"
                          selection
                          fluid
                          name="selectedCountry"
                          value={selectedCountry}
                          onChange={(e, countryValue) =>
                            this.handleCountryChange(e, countryValue)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        width="7"
                        error={
                          !(selectedCavity.length > 0) && !isValid
                            ? 'error'
                            : ''
                        }
                        className="select-cavity-drpdown"
                      >
                        <label>Select Cavity</label>
                        {data && data.data ? (
                          <div>{this.getbrands(data.data)}</div>
                        ) : (
                          <Dropdown.Menu>
                            <Dropdown.Item text="No brands" />
                          </Dropdown.Menu>
                        )}
                      </Form.Field>
                      <Form.Field width="1" className="text-center plr-0">
                        <label className="visibility-hidden">View</label>
                        <Modal
                          size="tiny"
                          closeIcon
                          onClose={() => this.setState({ openModal: false })}
                          onOpen={() => this.setState({ openModal: true })}
                          open={openModal}
                          trigger={
                            <Button
                              className="link-button"
                              disabled={
                                commonModes && commonModes.length ? '' : 'false'
                              }
                            >
                              View modes
                            </Button>
                          }
                        >
                          <Modal.Header>List ofAvailable Modes</Modal.Header>
                          <Modal.Content>
                            {commonModes && commonModes.length ? (
                              <List divided relaxed>
                                {this.listOfModes()}
                              </List>
                            ) : (
                              <div>No modes</div>
                            )}
                          </Modal.Content>
                        </Modal>
                      </Form.Field>
                    </Form.Group>
                    <Sidebar.Pushable
                      as={Segment}
                      className="create-program-sidebar"
                    >
                      <div
                        className={`${
                          visible === true ? 'visible' : ''
                        } collapsible-header`}
                      >
                        <Button
                          icon={`angle double ${
                            visible === true ? 'right' : 'left'
                          }`}
                          onClick={() => this.setState({ visible: !visible })}
                        />
                        <Header as="h4">User Instructions</Header>
                      </div>
                      <Sidebar
                        // animation="push"
                        // onHide={() => this.setState({ visible: false })}
                        visible={visible}
                        direction="right"
                      >
                        <UserInstructionForm />
                      </Sidebar>

                      <Sidebar.Pusher>
                        <OvenOperationsForm
                          units={units}
                          commonModes={
                            commonModes && commonModes.length
                              ? commonModes
                              : null
                          }
                          isValid={isValid}
                          isTypeError={isTypeError}
                          isTypeErrorForProbeTime={isTypeErrorForProbeTime}
                        />
                        <Divider section />

                        {/* <TimeAlertsForm /> */}
                      </Sidebar.Pusher>
                    </Sidebar.Pushable>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="16">
                    {isValid === false ? (
                      <p className="error-note text-center position-relative">
                        Please fill correct data
                      </p>
                    ) : (
                      ''
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider hidden section />
              <div className="button-wrapper text-center">
                <Button secondary compact onClick={() => this.createProgram()}>
                  Create
                </Button>
                <Button compact primary onClick={() => this.cancelProgram()}>
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regions: state.regions.regions,
    brands: state.brands.brands,
    program: state.program.program,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRegion: () => dispatch(fetchregionRequested()),
    fetchBrands: () => dispatch(fetchBrandsRequested()),
    postFormData: (data) => dispatch(programFormRequested(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProgram);
