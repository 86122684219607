import { combineReducers } from 'redux';
import {
  DASHBOARD_CHARTDATA_REQUESTED,
  DASHBOARD_CHARTDATA_SUCCESSFUL,
  DASHBOARD_CHARTDATA_FAILURE,
} from '../../constants/actions';

const dashboardChartReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_CHARTDATA_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case DASHBOARD_CHARTDATA_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case DASHBOARD_CHARTDATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  dashboardChart: dashboardChartReducer,
});
