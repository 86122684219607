import { put, call, takeLatest } from 'redux-saga/effects';
import modesApi from '../../api/modesApi';
import { MODES_LISTING_REQUESTED } from '../../constants/actions';
import {
  fetchModesFailure,
  fetchModesSuccessful,
} from '../actions/modesActions';

function* fetchModes(action) {
  const { response, error } = yield call(modesApi.fetchModes, action.payload);
  if (response) {
    yield put(fetchModesSuccessful(response));
  } else {
    yield put(fetchModesFailure(error));
  }
}

export default function* modesSagas() {
  yield takeLatest(MODES_LISTING_REQUESTED, fetchModes);
}
