import React from 'react';
import { Header, Segment, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const MainHeader = (props) => {
  const {
    rightContent,
    name,
    buttonName,
    iconName,
    path,
    linkButton,
    secondary,
  } = props;
  return (
    <Segment className="m-0 main-header">
      <Header as="h5">{name}</Header>
      {buttonName ? (
        <Button
          icon
          labelPosition="left"
          secondary={secondary}
          as={Link}
          to={path}
          className={linkButton ? 'link-button' : ''}
        >
          <Icon name={iconName} />
          {buttonName}
        </Button>
      ) : null}
      {rightContent && <props.rightContent />}
    </Segment>
  );
};
export default MainHeader;
