import axios from 'axios';
import { setLocalStorageValue } from '../utils/sharedFunctions';

const fetchUserDetails = (id) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/auth/user/${id}`;
  const token = localStorage.getItem('accessToken');
  return axios
    .get(tokenUrl, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      const { data } = response.data;
      setLocalStorageValue('status', data.status);
      // eslint-disable-next-line no-underscore-dangle
      setLocalStorageValue('_id', data._id);
      setLocalStorageValue('role', data.role);
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      if (e && e.response) {
        const { data } = e.response;
        return {
          error: {
            ...data,
          },
        };
      }
      return {
        error: {},
      };
    });
};

export default fetchUserDetails;
