import {
  TAGS_LISTING_REQUESTED,
  TAGS_LISTING_SUCCESSFUL,
  TAGS_LISTING_FAILURE,
} from '../../constants/actions';

export const fetchTagsRequested = (data) => {
  return {
    type: TAGS_LISTING_REQUESTED,
    payload: data,
  };
};

export const fetchTagsSuccessful = (data) => {
  return {
    type: TAGS_LISTING_SUCCESSFUL,
    payload: data,
  };
};

export const fetchTagsFailure = (data) => {
  return {
    type: TAGS_LISTING_FAILURE,
    payload: data,
  };
};
