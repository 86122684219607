import { put, call, takeLatest } from 'redux-saga/effects';
import dashboardApi from '../../api/dashboardApi';
import {
  DASHBOARD_SUMMARY_REQUESTED,
  // DASHBOARD_CHARTDATA_REQUESTED
} from '../../constants/actions';
import {
  fetchDashboardSummaryFailure,
  fetchDashboardSummarySuccessful,
  //   fetchDashboardChartDataFailure,
  //   fetchDashboardChartDataSuccessful,
} from '../actions/dashboardActions';

function* fetchDashboardSummary(action) {
  const { response, error } = yield call(
    dashboardApi.fetchDashboardSummary,
    action.payload
  );
  if (response) {
    yield put(fetchDashboardSummarySuccessful(response));
  } else {
    yield put(fetchDashboardSummaryFailure(error));
  }
}

// function* fetchDashboardChartData(action) {
//   const { response, error } = yield call(
//     dashboardApi.fetchDashboardChartData,
//     action.payload
//   );
//   if (response) {
//     yield put(fetchDashboardChartDataSuccessful(response));
//   } else {
//     yield put(fetchDashboardChartDataFailure(error));
//   }
// }

export default function* dashboardSaga() {
  yield takeLatest(DASHBOARD_SUMMARY_REQUESTED, fetchDashboardSummary);
  //   yield takeLatest(DASHBOARD_CHARTDATA_REQUESTED, fetchDashboardChartData);
}
