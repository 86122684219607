import { combineReducers } from 'redux';
import {
  FILTER_LISTING_REQUESTED,
  FILTER_LISTING_FAILURE,
  FILTER_LISTING_SUCCESSFUL,
} from '../../constants/actions';

const filterReducer = (state = {}, action) => {
  switch (action.type) {
    case FILTER_LISTING_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case FILTER_LISTING_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case FILTER_LISTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  filters: filterReducer,
});
