const initialState = {
  app: {},
  auth: {
    authenticated: false,
    isLoading: false,
  },
  dashboard: {
    dashboardPrograms: {
      isLoading: true,
    },
  },
  userManagement: {
    users: {
      isLoading: true,
    },
  },
  categories: {
    categories: {
      isLoading: true,
    },
  },
  brands: {
    brands: {
      isLoading: true,
    },
  },
  regions: {
    regions: {
      isLoading: true,
    },
  },
  modes: {
    modes: {
      isLoading: true,
    },
  },
  filters: {
    filters: {
      isLoading: true,
    },
  },
  files: {
    files: {
      isLoading: true,
    },
  },
  tags: {
    tags: {
      isLoading: true,
    },
  },
  programs: {
    programs: {
      isLoading: true,
    },
  },
  programUsers: {
    programUsers: {
      isLoading: true,
    },
  },
  dashboardSummary: {
    dashboardSummary: {
      isLoading: true,
    },
  },
  dashboardChartData: {
    dashboardChartData: {
      isLoading: true,
    },
  },
};

export default initialState;
