import { React, Component } from 'react';
import { Route, withRouter } from 'react-router-dom';

import View from './views/View';
import './assets/semantic/semantic.min.css';

class App extends Component {
  componentWillUnmount() {}

  render() {
    return (
      <>
        <Route path="/" component={View} />
      </>
    );
  }
}

export default withRouter(App);
