import { put, call, takeLatest } from 'redux-saga/effects';
import programsApi from '../../api/programsApi';
import { PROGRAMS_REQUESTED } from '../../constants/actions';
import {
  fetchProgramsFailure,
  fetchProgramsSuccessful,
} from '../actions/programsActions';

function* fetchPrograms(action) {
  const { response, error } = yield call(
    programsApi.fetchPrograms,
    action.payload
  );
  if (response) {
    yield put(fetchProgramsSuccessful(response));
  } else {
    yield put(fetchProgramsFailure(error));
  }
}

export default function* programsSagas() {
  yield takeLatest(PROGRAMS_REQUESTED, fetchPrograms);
}
