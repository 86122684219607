/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Input } from 'semantic-ui-react';

export default function InputWithIcons(props) {
  const { value, onClickCheck, onClickClose, onInputchange } = props;

  return (
    <div className="input-with-icons">
      <Input
        value={value}
        onChange={(e) => {
          if (onInputchange) onInputchange(e);
        }}
        className="input-wrap"
      />
      <div className="icon-wrap">
        <span
          className="save-icon"
          onClick={() => {
            if (onClickCheck) onClickCheck();
          }}
        />
        <span
          className="cancel-icon"
          onClick={() => {
            if (onClickClose) onClickClose();
          }}
        />
      </div>
    </div>
  );
}
