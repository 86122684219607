import { combineReducers } from 'redux';
import {
  PROGRAM_FAILURE,
  PROGRAM_REQUESTED,
  PROGRAM_SUCCESSFUL,
  PROGRAMFORM_REQUESTED,
  PROGRAMFORM_UPDATE,
  PROGRAM_USERFEEDBACKUPDATE,
} from '../../constants/actions';

const programReducer = (state = {}, action) => {
  switch (action.type) {
    case PROGRAM_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case PROGRAM_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case PROGRAM_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case PROGRAMFORM_REQUESTED:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case PROGRAMFORM_UPDATE:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isLoading: false,
      };
    case PROGRAM_USERFEEDBACKUPDATE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  program: programReducer,
});
