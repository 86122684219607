import axios from 'axios';
import { setLocalStorageValue } from '../utils/sharedFunctions';

const fetchToken = (user) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/auth/login`;

  return axios
    .post(tokenUrl, user, {
      headers: {
        source: 'web',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      const { data } = response.data;
      setLocalStorageValue('accessToken', data.token);
      // eslint-disable-next-line no-underscore-dangle
      setLocalStorageValue('_id', data._id);
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

export default fetchToken;
