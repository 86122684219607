import { normalize } from 'normalizr';
import { categoryList } from '../state/schemas';
import ApiUtils from './apiUtils';

const fetchCategory = async () => {
  try {
    const response = await ApiUtils.get(`api/categories`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, categoryList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const addCategory = async (param) => {
  try {
    const response = await ApiUtils.post(`api/categories`, param);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const deleteCategory = async (categoryId) => {
  try {
    const response = await ApiUtils.del(`api/categories/${categoryId}`);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchCategory,
  addCategory,
  deleteCategory,
};
