import { Component, React } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import { Loader } from 'semantic-ui-react';
// import Navbar from './ui/Navbar';
import { validateTokenRequested } from '../state/actions/authActions';
import Login from './pages/Login/Login';
import DashboardContainer from './pages/Dashboard/DashboardContainer';
import ProgramDetails from './pages/Program/ProgramDetails';
import UserListing from './pages/UserManagement/UserListing';
import MetaMain from './pages/Meta/MetaMain';
import CreateProgram from './pages/CreateProgram/CreateProgram';
import ProgramList from './pages/Program/ProgramList';
import { getLocalStorageValue } from '../utils/sharedFunctions';
import EditProgram from './pages/EditProgram/EditProgram';

const code = getLocalStorageValue('accessToken');

class View extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.checkLoggedIn();
    props.validateToken();
  }

  componentDidMount() {
    // const { validateToken } = this.props;
    // Handel authorization callback and extract the auth code
    // if (!code) {
    // window.history.replaceState({}, document.title, '/login');
    // Invoke login request, this will exchange the access code for an access token
    // loginRequest({ code });
    // }
    // if (!authenticated && !code) {
    // Check if the existing tokens in the local storage are valid
    // validateToken();
    this.checkLoggedIn();
  }

  checkLoggedIn() {
    const { history } = this.props;
    if (!code) {
      history.push('/login');
    }
  }

  render() {
    // const { isLoading, authenticated } = this.props;
    return (
      <>
        {/* <Navbar user={user} /> */}
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/dashboard" component={DashboardContainer} />
          <Route exact path="/programs" component={ProgramList} />
          <Route exact path="/program-details" component={ProgramDetails} />
          <Route exact path="/user-list" component={UserListing} />
          <Route exact path="/meta" component={MetaMain} />
          <Route exact path="/programs/create" component={CreateProgram} />
          <Route exact path="/programs/:id/edit" component={EditProgram} />
          <Route exact path="/programs/:id/" component={ProgramDetails} />
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.app.user,
    isLoading: state.auth.isLoading,
    authenticated: state.auth.authenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // loginRequest:(accesscode) => dispatch(loginRequested(accesscode)),
    validateToken: () => dispatch(validateTokenRequested()),
    // loginSuccessfull: userData => dispatch(loginSuccessfull(userData)),
    // loginFailed: error => dispatch(loginFailure(error))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(View);
