import {
  FILES_LISTING_REQUESTED,
  FILES_LISTING_SUCCESSFUL,
  FILES_LISTING_FAILURE,
} from '../../constants/actions';

export const fetchFilesRequested = (data) => {
  return {
    type: FILES_LISTING_REQUESTED,
    payload: data,
  };
};

export const fetchFilesSuccessful = (data) => {
  return {
    type: FILES_LISTING_SUCCESSFUL,
    payload: data,
  };
};

export const fetchFilesFailure = (data) => {
  return {
    type: FILES_LISTING_FAILURE,
    payload: data,
  };
};
