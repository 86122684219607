import axios from 'axios';
import { getLocalStorageValue } from '../utils/sharedFunctions';
import ApiUtils from './apiUtils';

const fetchBrands = async () => {
  try {
    const response = await ApiUtils.get(`api/brands`);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const postBrands = (param) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/brands`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .post(tokenUrl, param, {
      headers: {
        source: 'web',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      // eslint-disable-next-line no-underscore-dangle
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

const deleteBrands = async (brandId) => {
  try {
    const response = await ApiUtils.del(`api/brands/${brandId}`);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const updateBrands = async (brandId, param) => {
  try {
    const response = await ApiUtils.put(`api/brands/${brandId}`, param);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const deleteCavity = async (brandId, cavityId) => {
  try {
    const response = await ApiUtils.del(
      `api/brands/${brandId}/cavities/${cavityId}`
    );
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchBrands,
  postBrands,
  updateBrands,
  deleteBrands,
  deleteCavity,
};
