/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  Form,
  Grid,
  Header,
  Image,
  Label,
  List,
  // Rating,
  // Checkbox,
  Popup,
  Segment,
  Sidebar,
  // Statistic,
  Table,
  Radio,
} from 'semantic-ui-react';
import { get } from 'lodash';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  fetchDashboardProgramsRequested,
  fetchDashboardSummaryRequested,
  fetchDashboardChartDataRequested,
} from '../../../state/actions/dashboardActions';
import { validateTokenRequested } from '../../../state/actions/authActions';
import MainHeader from '../../ui/MainHeader';
import Navbar from '../../ui/Navbar';
import failedIcon from '../../../assets/images/failed.svg';
import runningStatusIcon from '../../../assets/images/current-running.svg';
import successIcon from '../../../assets/images/successful.svg';
import programBlackIcon from '../../../assets/images/total-programms.svg';
// import graphImage1 from '../../../assets/images/Group1.svg';
// import graphImage2 from '../../../assets/images/Group2.svg';
import satisfied from '../../../assets/images/satisfied.svg';
import notSatisfied from '../../../assets/images/not-satisfied.svg';
import greenFlag from '../../../assets/images/flags/green-flag.svg';
import yellowFlag from '../../../assets/images/flags/yellow-flag.svg';
import blueFlag from '../../../assets/images/flags/blue-flag.svg';
import pinkFlag from '../../../assets/images/flags/pink-flag.svg';
import redFlag from '../../../assets/images/flags/red-flag.svg';
import whiteFlag from '../../../assets/images/flags/flag.svg';
import { getLocalStorageValue } from '../../../utils/sharedFunctions';

const filters = {
  page: 1,
  pageSize: 5,
  type: 'recent',
};

const chartfilter = {
  startDate: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
};
class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedWeek: '1W',
    };
  }

  componentDidMount() {
    const code = getLocalStorageValue('accessToken');
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.props.user || !code) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.validateToken();
    }
    this.fetchData(filters);
  }

  fetchData = () => {
    this.props.fetchDashboardPrograms(filters);
    this.props.fetchDashboardSummary();
    this.props.fetchDashboardChartData(chartfilter);
  };

  flagImage = (program) => {
    const flags = get(program, 'flag');
    let flagValue = whiteFlag;
    if (flags === 'green') flagValue = greenFlag;
    if (flags === 'yellow') flagValue = yellowFlag;
    if (flags === 'blue') flagValue = blueFlag;
    if (flags === 'pink') flagValue = pinkFlag;
    if (flags === 'red') flagValue = redFlag;
    return <Image src={flagValue} />;
  };

  showGraph = (chartData) => {
    const datas = {
      labels: chartData.map((e) => moment(e.date).format('DD/MMM')),
      datasets: [
        {
          label: 'failed',
          data: chartData.map((e) => e.failed.count),
          fill: false,
          satisfied: chartData.map((e) => e.failed.satisfied),
          dissatisfied: chartData.map((e) => e.failed.dissatisfied),
          borderWidth: 1,
          // backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: '#FF6262',
        },
        {
          label: 'completed',
          data: chartData.map((e) => e.completed.count),
          fill: false,
          satisfied: chartData.map((e) => e.completed.satisfied),
          dissatisfied: chartData.map((e) => e.completed.dissatisfied),
          borderWidth: 1,
          borderColor: '#7AB51B',
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0,
        },
      },
      title: {
        display: false,
      },
      tooltips: {
        enabled: false,
        mode: 'single',
        // titleAlign: 'center',
        // cornerRadius: 1,
        displayColors: false,
        position: 'nearest',
        callbacks: {
          label: (tooltipItems, data) => {
            return { tooltipItems, data };
          },
        },
        custom(tooltipModel) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table class="graph-tooltip-table"></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = '<thead>';

            titleLines.forEach((title) => {
              innerHtml += `<tr><th>${title}</th><th>Completed</th><th>Failed</th></tr>`;
            });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach((body, i) => {
              const temp = body[i];
              const setCompletedValue =
                temp.tooltipItems.datasetIndex === 1
                  ? temp.data.datasets[1].satisfied[temp.tooltipItems.index]
                  : '-';
              const setFailedValue =
                temp.tooltipItems.datasetIndex === 0
                  ? temp.data.datasets[0].satisfied[temp.tooltipItems.index]
                  : '-';
              const disetCompletedValue =
                temp.tooltipItems.datasetIndex === 1
                  ? temp.data.datasets[1].dissatisfied[temp.tooltipItems.index]
                  : '-';
              const disetFailedValue =
                temp.tooltipItems.datasetIndex === 0
                  ? temp.data.datasets[0].dissatisfied[temp.tooltipItems.index]
                  : '-';
              const colors = tooltipModel.labelColors[i];
              let style = `background:${colors.backgroundColor}`;
              style += `; border-color:${colors.borderColor}`;
              style += '; border-width: 2px';
              const span = `<span style="${style}"></span>`;
              innerHtml += `<tr><td>Satisfied</td><td>${span}${setCompletedValue}</td><td>${span}${setFailedValue}</td></tr><tr><td>Dissatisfied</td><td>${span}${disetCompletedValue}</td><td>${span}${disetFailedValue}</td></tr>`;
            });
            innerHtml += '</tbody>';

            const tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          const position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = `${
            position.left + window.pageXOffset + tooltipModel.caretX
          }px`;
          tooltipEl.style.top = `${
            position.top + window.pageYOffset + tooltipModel.caretY
          }px`;
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
          tooltipEl.style.pointerEvents = 'none';
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              display: false,
            },
            offset: true,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              // max: 5,
              // min: 0,
              display: false,
              stepSize: 6,
            },
            offset: true,
          },
        ],
      },
    };
    return <Line data={datas} options={options} width={400} height={50} />;
  };

  programsByWeek = (value) => {
    const start = moment()
      .subtract(value.charAt(0), 'weeks')
      .format('YYYY-MM-DD');
    const end = moment().format('YYYY-MM-DD');
    this.setState({ selectedWeek: value });
    const filter = {
      startDate: start,
      endDate: end,
    };
    this.props.fetchDashboardChartData(filter);
  };

  render() {
    const { dashboardPrograms, dashboardSummary, dashboardChart } = this.props;
    const { selectedWeek } = this.state;
    const data = dashboardPrograms && get(dashboardPrograms, 'data');
    return (
      <Sidebar.Pushable as={Segment} className="d-flex">
        <Navbar name="dashboard" />
        <Sidebar.Pusher>
          <MainHeader name="Dashboard" />
          <div className="inner-wrapper dashboard-wrapper pl-0">
            <div className="dashboard-left">
              <Segment padded className="bordered">
                <List relaxed="very" className="data-icon-list large">
                  <List.Item className="success-item">
                    <span>Satisfied</span>
                    {get(dashboardSummary, 'data.satisfied') || 0}
                    <Image src={satisfied} />
                  </List.Item>
                  <List.Item className="failed-item">
                    <span>Dissatisfied</span>
                    {get(dashboardSummary, 'data.dissatisfied') || 0}
                    <Image src={notSatisfied} />
                  </List.Item>
                </List>
              </Segment>
              <Segment padded className="bordered">
                <List relaxed="very" className="data-icon-list large">
                  <List.Item>
                    <span>
                      Total
                      <br />
                      Programs
                    </span>
                    {get(dashboardSummary, 'data.total') || 0}
                    <Image src={programBlackIcon} />
                  </List.Item>
                  <List.Item>
                    <span>Current Running</span>
                    {get(dashboardSummary, 'data.running') || 0}
                    <Image src={runningStatusIcon} />
                  </List.Item>
                  <List.Item>
                    <span>Completed</span>
                    {get(dashboardSummary, 'data.completed') || 0}
                    <Image src={successIcon} />
                  </List.Item>
                  <List.Item>
                    <span>Failed</span>
                    {get(dashboardSummary, 'data.failed') || 0}
                    <Image src={failedIcon} />
                  </List.Item>
                </List>
              </Segment>
            </div>
            <div className="dashboard-right">
              <Grid className="mr-0">
                <Grid.Row>
                  <Grid.Column width="16">
                    <Card fluid className="add-shadow">
                      <Card.Content>
                        <Header
                          as="h5"
                          className="d-flex align-center justify-content-between"
                        >
                          Most 5 frequently run programs
                          <Form>
                            <Form.Group className="m-0">
                              <Form.Field inline className="list-filter small">
                                <Radio
                                  label="1W"
                                  name="radioGroup"
                                  value="1W"
                                  checked={selectedWeek === '1W'}
                                  onChange={(e, { value }) => {
                                    this.programsByWeek(value);
                                  }}
                                />
                                <Radio
                                  label="2W"
                                  name="radioGroup"
                                  value="2W"
                                  checked={selectedWeek === '2W'}
                                  onChange={(e, { value }) => {
                                    this.programsByWeek(value);
                                  }}
                                />
                                <Radio
                                  label="3W"
                                  name="radioGroup"
                                  value="3W"
                                  checked={selectedWeek === '3W'}
                                  onChange={(e, { value }) => {
                                    this.programsByWeek(value);
                                  }}
                                />
                                <Radio
                                  label="4W"
                                  name="radioGroup"
                                  value="4W"
                                  checked={selectedWeek === '4W'}
                                  onChange={(e, { value }) => {
                                    this.programsByWeek(value);
                                  }}
                                />
                              </Form.Field>
                            </Form.Group>
                          </Form>
                        </Header>
                      </Card.Content>
                      <Table basic="very" className="mt-0 graph-table">
                        <Table.Header>
                          <Table.HeaderCell width="3">Program</Table.HeaderCell>
                          <Table.HeaderCell width="1" textAlign="center">
                            <Image
                              size="mini"
                              src={successIcon}
                              className="m-auto"
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell width="1" textAlign="center">
                            <Image
                              size="mini"
                              src={failedIcon}
                              className="m-auto"
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell width="1" textAlign="center">
                            <Image src={satisfied} className="m-auto" />
                          </Table.HeaderCell>
                          <Table.HeaderCell width="1" textAlign="center">
                            <Image src={notSatisfied} className="m-auto" />
                          </Table.HeaderCell>
                          <Table.HeaderCell width="7" />
                        </Table.Header>
                        <Table.Body>
                          {dashboardChart &&
                          dashboardChart.data &&
                          dashboardChart.data.length > 0 ? (
                            dashboardChart.data.map((chart) => (
                              <Table.Row>
                                <Table.Cell className="pl-0">
                                  <Link to={`/programs/${chart.programId}/`}>
                                    {chart.title}
                                  </Link>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {chart.completed}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {chart.failed}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {chart.satisfied}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {chart.dissatisfied}
                                </Table.Cell>
                                <Table.Cell>
                                  {chart &&
                                    chart.data &&
                                    this.showGraph(chart.data)}
                                </Table.Cell>
                              </Table.Row>
                            ))
                          ) : (
                            <Table.Row>
                              <Table.Cell colSpan="6" textAlign="center">
                                <div className="no-data mt-50 mb-50">
                                  No Data available
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </Card>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row className="mt-25">
                  <Grid.Column>
                    <Grid verticalAlign="middle">
                      <Grid.Column width="4">
                        <Header as="h5" className="font-regular">
                          Recent run programs
                        </Header>
                      </Grid.Column>
                    </Grid>
                    <div className="list-table">
                      <Table
                        basic="very"
                        sortable
                        structured
                        singleLine
                        striped
                        className="program-list add-shadow dashboard-list"
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell
                              rowspan="2"
                              className="sticky-column first-column plr-half"
                              width="1"
                            />
                            <Table.HeaderCell
                              width="4"
                              rowspan="2"
                              className="second-column sticky-column"
                            >
                              Program Title
                            </Table.HeaderCell>
                            <Table.HeaderCell width="4" rowspan="2">
                              Internal Title
                            </Table.HeaderCell>
                            <Table.HeaderCell width="2" rowspan="2">
                              Category
                            </Table.HeaderCell>
                            <Table.HeaderCell width="2" rowspan="2">
                              Cavities
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              width="2"
                              rowspan="2"
                              textAlign="center"
                            >
                              Current
                              <br />
                              Version
                            </Table.HeaderCell>
                            <Table.HeaderCell width="2" rowspan="2">
                              Program Id
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              textAlign="center"
                              colSpan="2"
                              className="review-status"
                            >
                              Ratings
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              width="2"
                              rowSpan="2"
                              textAlign="center"
                            >
                              # Reviews
                            </Table.HeaderCell>
                            {/* <Table.HeaderCell
                              width="2"
                              rowSpan="2"
                              textAlign="center"
                            >
                              Published
                            </Table.HeaderCell> */}
                            {/* <Table.HeaderCell width="6" rowSpan="2">
                          Last used
                        </Table.HeaderCell> */}
                          </Table.Row>
                          <Table.Row>
                            <Table.HeaderCell width="2" textAlign="center">
                              Consumer
                            </Table.HeaderCell>
                            <Table.HeaderCell width="2" textAlign="center">
                              Internal
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          {data && data.length > 0 ? (
                            data.map((program) => (
                              <Table.Row>
                                <Table.Cell className="sticky-column first-column plr-half">
                                  {dashboardPrograms &&
                                    dashboardPrograms.data &&
                                    this.flagImage(program)}
                                </Table.Cell>
                                <Table.Cell className="second-column sticky-column no-white-space">
                                  <Link to={`/programs/${program._id}/`}>
                                    {program.title}
                                  </Link>
                                </Table.Cell>
                                <Table.Cell className="no-white-space">
                                  {program.internalTitle}
                                </Table.Cell>
                                <Table.Cell>{program.category.name}</Table.Cell>
                                <Table.Cell>
                                  {program.cavities[0].name}
                                  {program.cavities.length > 1 ? (
                                    <Popup
                                      inverted
                                      content={
                                        <List bulleted>
                                          {program.cavities.map(
                                            (cavity, index) =>
                                              index > 0 ? (
                                                <List.Item>
                                                  {cavity.name}
                                                </List.Item>
                                              ) : null
                                          )}
                                        </List>
                                      }
                                      trigger={
                                        <Button
                                          className="ml-10"
                                          size="mini"
                                          content={`+${
                                            program.cavities.length - 1
                                          }`}
                                        />
                                      }
                                    />
                                  ) : (
                                    ''
                                  )}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  V{program.version}
                                </Table.Cell>
                                <Table.Cell>
                                  {get(program, 'sequence') || '-'}
                                </Table.Cell>
                                <Table.Cell>
                                  <div className="d-flex align-center justify-center">
                                    {program &&
                                    get(program, 'userFeedback') !==
                                      undefined &&
                                    (get(program, 'userFeedback.satisfied') !==
                                      0 ||
                                      get(
                                        program,
                                        'userFeedback.dissatisfied'
                                      ) !== 0) ? (
                                      <Image
                                        src={
                                          get(
                                            program,
                                            'userFeedback.satisfied'
                                          ) >=
                                          get(
                                            program,
                                            'userFeedback.dissatisfied'
                                          )
                                            ? satisfied
                                            : notSatisfied
                                        }
                                        className="mr-10"
                                      />
                                    ) : (
                                      '-'
                                    )}
                                  </div>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {get(program, 'internalFeedback.rating') ? (
                                    <>
                                      <Label
                                        circular
                                        className={
                                          get(
                                            program,
                                            'internalFeedback.rating'
                                          ) >= 3
                                            ? 'rating-label light-success'
                                            : 'rating-label light-failed'
                                        }
                                      >
                                        {get(
                                          program,
                                          'internalFeedback.rating'
                                        )}
                                      </Label>{' '}
                                      / 5
                                    </>
                                  ) : (
                                    '-'
                                  )}
                                  {/* To update label colors conditionally use below classes
                            success => light-success
                            failed => light-failed
                            warninng => light-warning
                          */}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {Number(
                                    get(program, 'userFeedback.satisfied') || 0
                                  ) +
                                    Number(
                                      get(
                                        program,
                                        'userFeedback.dissatisfied'
                                      ) || 0
                                    )}
                                </Table.Cell>
                                {/* <Table.Cell textAlign="center">
                                  <Checkbox
                                    checked={get(program, 'published') === true}
                                    slider
                                    fitted
                                    disabled
                                  />
                                </Table.Cell> */}
                                {/* <Table.Cell>28th Oct 2020 | 13:12</Table.Cell> */}
                              </Table.Row>
                            ))
                          ) : (
                            <Table.Row>
                              <Table.Cell className="sticky-column first-column visibility-hidden">
                                Hidden
                              </Table.Cell>
                              <Table.Cell colSpan="11" textAlign="center">
                                <div className="no-data">
                                  No Programs available
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
// At the end of the file
const mapStateToProps = (state) => {
  return {
    dashboardSummary: state.dashboardSummary.dashboardSummary,
    dashboardPrograms: state.dashboard.dashboardPrograms,
    dashboardChart: state.dashboardChart.dashboardChart,
    user: state.app.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validateToken: () => dispatch(validateTokenRequested()),
    fetchDashboardPrograms: (filter) =>
      dispatch(fetchDashboardProgramsRequested(filter)),
    fetchDashboardSummary: () => dispatch(fetchDashboardSummaryRequested()),
    fetchDashboardChartData: (filter) =>
      dispatch(fetchDashboardChartDataRequested(filter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
