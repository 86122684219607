import { combineReducers } from 'redux';
import {
  BRANDS_REQUESTED,
  BRANDS_SUCCESSFUL,
  BRANDS_FAILURE,
} from '../../constants/actions';

const brandsReducer = (state = {}, action) => {
  switch (action.type) {
    case BRANDS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case BRANDS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case BRANDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  brands: brandsReducer,
});
