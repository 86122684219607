/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, Component } from 'react';
import { connect } from 'react-redux';

import Dropzone from 'react-dropzone';
import {
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Segment,
  TextArea,
  Confirm,
} from 'semantic-ui-react';
import { get } from 'lodash';
// import MainBreadcrumb from '../../ui/MainBreadcrumb';
import upload from '../../../assets/images/upload-file.svg';
import { fetchCategoryRequested } from '../../../state/actions/categoryActions';
import { fetchTagsRequested } from '../../../state/actions/tagsActions';
import { fetchFilterRequested } from '../../../state/actions/filterAction';
import { fetchFilesRequested } from '../../../state/actions/fileActions';
import { programFormUpdate } from '../../../state/actions/programAction';
import tagsApi from '../../../api/tagsApi';
import filesApi from '../../../api/filesApi';
// import CustomFunctions from '../../../utils/customFunctions';
import trash from '../../../assets/images/trash.svg';

const defaultFilters = {
  sortBy: 'title',
  sortOrder: 'desc',
  page: 1,
  // search:'a'
};

class TopSectionCreateProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTagsData: [],
      filterValue: [],
      tagsSearchData: [],
      additionalItems: [],
      categoryValue: '',
      programTitle: '',
      internalTitle: '',
      // selectedFile: '',
      description: '',
      description2: '',
      refUrlOne: '',
      refUrlTwo: '',
      // isValid: true,
      fileBlobUrl: [],
      // addedMultipleFiles: [],
      setTags: false,
      activeSlideIndex: 0,
      modalOpen: false,
    };
    this.fetchData(defaultFilters);
  }

  componentDidUpdate() {
    const { program } = this.props;
    const programData = get(program, 'data.tags');
    const { setTags } = this.state;

    if (get(programData, '[0]') && !setTags) {
      this.renderSelectedTag(programData);
    }
  }

  renderSelectedTag = (programData) => {
    this.setState({
      selectedTagsData: programData,
      setTags: true,
    });
  };

  fetchData = (filters) => {
    this.props.fetchCategory(filters);
    this.props.fetchTags(filters);
    this.props.fetchFilter(filters);
    this.props.fetchFiles(filters);
  };

  handleTagsValue = (e, { value }) => {
    this.setState((prevState) => ({
      additionalItems: [{ text: value, value }, ...prevState.additionalItems],
    }));
  };

  onTagsSearchChange = async (e, data) => {
    const tagsResponse = await tagsApi.fetchTags({ search: data.searchQuery });
    if (tagsResponse && tagsResponse.response && tagsResponse.response.data) {
      this.setState({ tagsSearchData: tagsResponse.response.data });
    }
  };

  onAddTagsData = async (e, value) => {
    const { additionalItems, selectedTagsData } = this.state;
    const { postFormData } = this.props;
    const payloadData = {};

    if (value.value) payloadData.name = value.value;
    const postTagsResponse = await tagsApi.postTags(payloadData);

    if (postTagsResponse.error) {
      // handle error
      return;
    }

    const addedTag = postTagsResponse.response.data;
    const fountItemIndex = additionalItems.findIndex(
      (item) => item._id === addedTag._id
    );
    if (fountItemIndex > -1) return;
    const obj = {
      key: addedTag._id,
      text: addedTag.name,
      value: addedTag._id,
    };
    additionalItems.push(obj);
    this.setState({
      additionalItems,
      selectedTagsData: [...selectedTagsData, obj.value],
    });
    postFormData({ tags: [...selectedTagsData, obj.value] });
  };

  handleChange = (e, data) => {
    const { value } = data;
    const { postFormData } = this.props;
    const { tagsSearchData, additionalItems } = this.state;
    const selectedTags = tagsSearchData.filter((tag) => {
      return value.includes(tag._id);
    });
    selectedTags.map((tag) => {
      const fountItemIndex = additionalItems.findIndex(
        (item) => item._id === tag._id
      );
      if (fountItemIndex > -1) return tag;
      additionalItems.push({
        key: tag._id,
        value: tag._id,
        text: tag.name,
      });
      return tag;
    });
    this.setState({
      selectedTagsData: value,
      additionalItems,
    });
    postFormData({ tags: value });
  };

  getFilters = () => {
    const { filters } = this.props;
    if (!filters || !filters.data) return [];
    const filtersOptions = [];
    filters.data.map((filter) => {
      return filtersOptions.push({
        key: filter._id,
        text: filter.name,
        value: filter._id,
      });
    });
    return filtersOptions;
  };

  getCategoryData = () => {
    const { categories } = this.props;
    if (!categories || !categories.data) return [];
    const categoryOptions = [];
    if (categories && categories.data && categories.data.length > 0) {
      categories.data.map((category) => {
        return categoryOptions.push({
          key: category._id,
          text: category.name,
          value: category._id,
          ...category,
        });
      });
    }
    return categoryOptions;
  };

  getTagsData = () => {
    const { tagsSearchData } = this.state;
    if (!tagsSearchData || !tagsSearchData.length) return [];
    const tagsOptions = [];
    tagsSearchData.map((tag) => {
      return tagsOptions.push({
        key: tag._id,
        text: tag.name,
        value: tag._id,
        ...tag,
      });
    });
    return tagsOptions;
  };

  getNewTagsData = (programDataTags) => {
    if (!programDataTags || !programDataTags.length) return [];
    const tagsOptions = [];
    programDataTags.map((tag) => {
      return tagsOptions.push({
        key: tag.id,
        text: tag.name,
        value: tag.id,
      });
    });
    return tagsOptions;
  };

  getUploadFiles = async (acceptedFiles) => {
    acceptedFiles.map(async (file) => {
      this.uploadFile(file);
    });
  };

  resetData = () => {
    this.setState({
      programTitle: '',
      internalTitle: '',
      categoryValue: '',
      // selectedfile: '',
      refUrlOne: '',
      refUrlTwo: '',
      // isValid: false,
    });
  };

  uploadFile = async (file) => {
    const { postFormData, program } = this.props;
    const imageData = program && program.data && program.data.fileBlobUrl;
    const queryParam = {
      type: 'general',
    };
    const formData = new FormData();
    formData.append('file', file);
    const filesResponse = await filesApi.postFiles(queryParam, formData);
    if (filesResponse.error) {
      return;
    }
    const filesData = {};
    if (
      filesResponse &&
      filesResponse.response &&
      filesResponse.response.data
    ) {
      filesData.id = filesResponse.response.data._id;
      filesData.value = filesResponse.response.data.url;
    }
    imageData.push(filesData);
    this.setState({ fileBlobUrl: imageData || [] });
    postFormData({ fileBlobUrl: imageData || [] });
  };

  onModalOpen = () => {
    this.setState({ modalOpen: true });
  };

  onDeleteHandler = (activeSlideIndex) => {
    const { postFormData } = this.props;
    const { fileBlobUrl } = this.state;
    let index = activeSlideIndex;
    if (activeSlideIndex === fileBlobUrl.length - 1) index = 0;
    fileBlobUrl.splice(activeSlideIndex, 1);
    this.setState({ modalOpen: false, fileBlobUrl, activeSlideIndex: index });
    postFormData({ fileBlobUrl: fileBlobUrl || [] });
  };

  render() {
    const {
      selectedTagsData,
      filterValue,
      additionalItems,
      fileBlobUrl,
      activeSlideIndex,
      modalOpen,
      categoryValue,
      programTitle,
      internalTitle,
      // selectedFile,
      description,
      description2,
      refUrlOne,
      refUrlTwo,
      // isValid,
    } = this.state;
    const { postFormData, program, isValid, urlError } = this.props;
    const programData = program && program.data ? program.data : 0;
    return (
      <>
        <Grid.Row>
          <Grid.Column width={11}>
            <Form.Group widths="equal">
              <Form.Field
                error={
                  programData &&
                  !programData.programTitle &&
                  !programTitle &&
                  !isValid
                    ? 'error'
                    : ''
                }
              >
                <label>Program Title</label>
                <Input
                  placeholder="Enter Program Title"
                  size="large"
                  value={
                    programData && programData.programTitle
                      ? programData.programTitle
                      : programTitle
                  }
                  onChange={(e) => {
                    this.setState({ programTitle: e.target.value });
                    postFormData({ programTitle: e.target.value });
                  }}
                />
              </Form.Field>
              <Form.Field
                error={
                  programData &&
                  !programData.internalTitle &&
                  !internalTitle &&
                  !isValid
                    ? 'error'
                    : ''
                }
              >
                <label>Internal Title</label>
                <Input
                  placeholder="Enter Internal Title"
                  className={!internalTitle && !isValid ? 'error' : ''}
                  size="large"
                  value={
                    programData && programData.internalTitle
                      ? programData.internalTitle
                      : internalTitle
                  }
                  onChange={(e) => {
                    this.setState({ internalTitle: e.target.value });
                    postFormData({ internalTitle: e.target.value });
                  }}
                />
              </Form.Field>
              <Form.Field
                error={
                  programData &&
                  !programData.categoryValue &&
                  !categoryValue &&
                  !isValid
                    ? 'error'
                    : ''
                }
              >
                <label>Category</label>
                <Dropdown
                  options={this.getCategoryData()}
                  placeholder="Category"
                  selection
                  fluid
                  className="large category"
                  value={
                    programData && programData.categoryValue
                      ? programData.categoryValue.id
                      : categoryValue
                  }
                  onChange={(e, { value }) => {
                    this.setState({ categoryValue: value });
                    postFormData({ categoryValue: value });
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Tags</label>
                <Dropdown
                  className="large tag-selection d-flex align-center"
                  options={[
                    ...additionalItems,
                    ...this.getTagsData(),
                    ...this.getNewTagsData(programData && programData.tagsData),
                  ]}
                  placeholder="Choose Tags"
                  search
                  selection
                  fluid
                  multiple
                  allowAdditions
                  clearable
                  value={selectedTagsData}
                  onAddItem={(e, value) => this.onAddTagsData(e, value)}
                  onChange={(e, data) => this.handleChange(e, data)}
                  onSearchChange={(e, data) => this.onTagsSearchChange(e, data)}
                />
              </Form.Field>
              <Form.Field>
                <label>Filters</label>
                <Dropdown
                  className="large tag-selection d-flex align-center"
                  options={this.getFilters()}
                  placeholder="Choose Filters"
                  search
                  selection
                  fluid
                  multiple
                  value={
                    programData && programData.filterValue
                      ? programData.filterValue
                      : filterValue
                  }
                  onChange={(e, value) => {
                    this.setState({ filterValue: value.value });
                    postFormData({ filterValue: value.value });
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                error={
                  programData &&
                  !programData.description &&
                  !description &&
                  !isValid
                    ? 'error'
                    : ''
                }
              >
                <label>Description</label>
                <TextArea
                  rows="6"
                  value={
                    programData && programData.description
                      ? programData.description
                      : description
                  }
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                    postFormData({ description: e.target.value });
                  }}
                />
              </Form.Field>
              <Form.Field
                error={
                  programData &&
                  programData.fileBlobUrl &&
                  !programData.fileBlobUrl.length > 0 &&
                  !fileBlobUrl.length > 0 &&
                  !isValid
                    ? 'error'
                    : ''
                }
              >
                <label htmlFor="uploadimages">Upload Images</label>
                <Dropzone
                  multiple
                  accept="image/*"
                  onDrop={(acceptedFiles) => {
                    this.getUploadFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="d-flex create-prog-upload">
                      <div className="upload-images" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {/* Below code is commented for developers */}

                        <span>
                          <Image src={upload} />
                          Click here
                          <br />
                          or
                          <br />
                          Drag and drop file here
                        </span>
                      </div>
                      {programData &&
                        programData.fileBlobUrl &&
                        programData.fileBlobUrl.length > 0 && (
                          <div className="d-flex flex-column image-slider">
                            {programData.fileBlobUrl.map((imgUrl, imgIndex) => (
                              <>
                                {activeSlideIndex === imgIndex && (
                                  <div className="image-wrapper">
                                    <Image src={imgUrl.value} />
                                  </div>
                                )}
                              </>
                            ))}
                            <div className="d-flex justify-content-between">
                              <Icon
                                name="chevron left"
                                onClick={() => {
                                  const ind =
                                    activeSlideIndex ===
                                    programData.fileBlobUrl.length - 1
                                      ? 0
                                      : activeSlideIndex + 1;
                                  this.setState({ activeSlideIndex: ind });
                                }}
                              />
                              <Image
                                src={trash}
                                onClick={() =>
                                  this.onModalOpen(activeSlideIndex)
                                }
                              />
                              <Icon
                                name="chevron right"
                                onClick={() => {
                                  const ind =
                                    activeSlideIndex === 0
                                      ? programData.fileBlobUrl.length - 1
                                      : activeSlideIndex - 1;
                                  this.setState({ activeSlideIndex: ind });
                                }}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </Dropzone>
              </Form.Field>
            </Form.Group>
          </Grid.Column>
          <Grid.Column width={5}>
            <Segment className="grey-block">
              <Form.Group>
                <Form.Field width="16">
                  <label>
                    Description / Reference
                    <span className="sub-label">
                      (Only for program developers)
                    </span>
                  </label>
                  <TextArea
                    rows="9"
                    value={
                      programData && programData.description2
                        ? programData.description2
                        : description2
                    }
                    onChange={(e) => {
                      this.setState({ description2: e.target.value });
                      postFormData({
                        description2: e.target.value,
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field width="16" error={urlError.url1 ? 'error' : ''}>
                <label>Reference URL</label>
                <Input
                  size="large"
                  placeholder="url"
                  value={
                    programData && programData.refUrlOne
                      ? programData.refUrlOne
                      : refUrlOne
                  }
                  onChange={(e) => {
                    this.setState({ refUrlOne: e.target.value });
                    postFormData({ refUrlOne: e.target.value });
                  }}
                />
              </Form.Field>
              <Form.Field width="16" error={urlError.url2 ? 'error' : ''}>
                <Input
                  size="large"
                  placeholder="url"
                  value={
                    programData && programData.refUrlTwo
                      ? programData.refUrlTwo
                      : refUrlTwo
                  }
                  onChange={(e) => {
                    this.setState({ refUrlTwo: e.target.value });
                    postFormData({ refUrlTwo: e.target.value });
                  }}
                />
              </Form.Field>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Divider className="m-0" />
        <Confirm
          open={modalOpen}
          closeOnDimmerClick={false}
          content="Are you sure you want to delete image ?"
          onCancel={() => this.setState({ modalOpen: false })}
          onConfirm={() => this.onDeleteHandler(activeSlideIndex)}
          className="confirm-modal"
          size="tiny"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories.categories,
    tags: state.tags.tags,
    filters: state.filters.filters,
    files: state.files.files,
    program: state.program.program,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategory: (filters) => dispatch(fetchCategoryRequested(filters)),
    fetchTags: (filters) => dispatch(fetchTagsRequested(filters)),
    fetchFilter: (filters) => dispatch(fetchFilterRequested(filters)),
    fetchFiles: (filters) => dispatch(fetchFilesRequested(filters)),
    postFormData: (data) => dispatch(programFormUpdate(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopSectionCreateProgram);
