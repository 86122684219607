import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Grid,
  Image,
  Item,
  Label,
  // Menu,
  Modal,
} from 'semantic-ui-react';
import { get } from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import {programRequested} from '../../../state/actions/programAction';

class ViewProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      // activeItem: 'closest',
    };
    // this.fetchData();
  }

  fetchData = () => {
    // this.props.fetchProgram(this.props.id, true);
  };

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  modeSteps = (programData, stepCount) => {
    const modesArray = [];
    new Array(stepCount).fill(0).forEach((a, i) => {
      const modes =
        programData &&
        get(programData, 'operations').reduce(
          (prev, next) => [...prev, next.steps[i].mode.name],
          []
        );
      const cookingTime =
        programData &&
        get(programData, 'operations').reduce(
          (prev, next) => [...prev, next.steps[i].cookingTime],
          []
        );
      const internalTemp =
        programData &&
        get(programData, 'operations').reduce(
          (prev, next) => [...prev, next.steps[i].internalTemperature],
          []
        );
      const temp =
        programData &&
        get(programData, 'operations').reduce(
          (prev, next) => [...prev, next.steps[i].temperature],
          []
        );
      const alerts =
        programData &&
        get(programData, 'operations').reduce(
          (prev, next) => [...prev, next.steps[i].alert],
          []
        );

      modesArray.push({ modes, cookingTime, internalTemp, temp, alerts });
    });
    const mode =
      modesArray &&
      modesArray.map((e, i) => {
        return (
          <>
            <div className="row no-border step-label-wrap">
              <div className="label-column ptb-0 align-end plr-0">
                <Label className="step-label">Step {i + 1}</Label>
              </div>
              {e.modes.map(() => {
                return <div className="input-column empty" />;
              })}
            </div>
            {/* <div>Step-{i + 1}</div> */}
            <div className="row bg-grey">
              <div className="label-column">
                <label className="heading-lable text-capitalize">
                  Mode / Program
                </label>
              </div>
              {e.modes.map((f) => {
                return (
                  <div className="input-column position-relative">{f}</div>
                );
              })}
            </div>
            <div className="row">
              <div className="label-column">
                <label>Temperature</label>
              </div>
              {e.temp.map((f) => {
                return (
                  <div className="input-column position-relative">
                    {f}{' '}
                    {get(programData, 'units.temperature') === 'fahrenheit'
                      ? '°F'
                      : '°C'}
                  </div>
                );
              })}
            </div>
            {programData && programData.type === 'probe' ? (
              <div className="row">
                <div className="label-column">
                  <label>Internal Temperature</label>
                </div>
                {e.internalTemp.map((f) => {
                  return (
                    <div className="input-column position-relative">
                      {f}{' '}
                      {get(programData, 'units.temperature') === 'fahrenheit'
                        ? '°F'
                        : '°C'}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                <div className="label-column">
                  <label>Cooking Time</label>
                </div>
                {e.cookingTime.map((f) => {
                  return (
                    <div className="input-column position-relative">
                      {`${Math.floor(f / 60)} h : ${f % 60} m`}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="row">
              <div className="label-column">
                <label>Next Step Alert</label>
              </div>
              {/* {e.alerts.map((f) => {
                return ( */}
              <div className="input-column position-relative alert-column bg-white d-flex justify-content-between">
                {e.alerts[0].text}
                {e.alerts[0] && e.alerts[0].nextStepManual === true ? (
                  <span className="left-divider mr-10">
                    Require manual step
                  </span>
                ) : (
                  ''
                )}
              </div>
              {/* );
              })} */}
            </div>
          </>
        );
      });
    return mode;
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      className: 'image-slider',
    };
    const { openModal } = this.state;
    const { program } = this.props;
    const programData = get(program, 'data.data');
    const units = get(program, 'data.data.units') || 0;
    const stepCount =
      programData && get(programData, 'operations[0].steps').length;
    return (
      <Modal
        size="large"
        closeIcon
        onClose={() => this.setState({ openModal: false })}
        onOpen={() => this.setState({ openModal: true })}
        open={openModal}
        trigger={<Button className="d-flex link-button">View Program</Button>}
        className="view-program-modal"
      >
        <Modal.Header>{get(programData, 'title')}</Modal.Header>
        <Modal.Content>
          <Modal.Description className="pt-0">
            <Divider className="mt-0" />
            <p className="value text-uppercase">Program Information</p>
            <Grid divided="vertically">
              <Grid.Row columns="3">
                <Grid.Column>
                  <p className="label">Program Title</p>
                  <p className="value">{get(programData, 'title')}</p>
                </Grid.Column>
                <Grid.Column>
                  <p className="label">Internal Title</p>
                  <p className="value">{get(programData, 'internalTitle')}</p>
                </Grid.Column>
                <Grid.Column>
                  <p className="label">Category</p>
                  <p className="value">{get(programData, 'category.name')}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="3">
                <Grid.Column>
                  <p className="label">Tags</p>
                  <p className="value">
                    {programData &&
                      programData.tags &&
                      get(programData, 'tags').map((e) => {
                        return <Label>{e.name}</Label>;
                      })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <p className="label">Filters</p>
                  <p className="value">
                    {programData &&
                      programData.filters &&
                      get(programData, 'filters').map((e) => {
                        return <Label>{e.name}</Label>;
                      })}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <p className="label">Images</p>
                  <Slider {...settings}>
                    {programData &&
                      programData.images &&
                      get(programData, 'images').map((e) => {
                        return <Image src={e.url} />;
                      })}
                  </Slider>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column>
                  <p className="label">Description</p>
                  <p className="description">
                    {get(programData, 'description')}
                  </p>
                </Grid.Column>
                <Grid.Column className="grey-block">
                  <div className="d-flex justify-content-between align-center">
                    <p className="label">Description / Reference</p>
                    <p className="label small">(Only for program developers)</p>
                  </div>
                  <p className="description">
                    {get(programData, 'reference.description')}
                  </p>
                  <p className="description">
                    {programData &&
                      programData.reference &&
                      programData.reference.urls &&
                      get(programData, 'reference.urls').map((e) => {
                        return (
                          <>
                            <br />
                            <a href={e} target="_blank" rel="noreferrer">
                              {e}
                            </a>
                          </>
                        );
                      })}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <Grid.Column>
                  <p className="label">Region</p>
                  <p className="value">{get(programData, 'region.name')}</p>
                </Grid.Column>
                <Grid.Column>
                  <p className="label">Select Cavity</p>
                  <p className="value">
                    {programData &&
                      programData.cavities &&
                      get(programData, 'cavities').map((e) => {
                        return <Label>{e.name}</Label>;
                      })}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2" className="pt-0">
                <Grid.Column className="mt-25">
                  <p className="label">Country</p>
                  <p className="value">{get(programData, 'country.name')}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="16">
                  <p className="value text-uppercase">Base Parameters</p>
                </Grid.Column>
                <Grid.Column width="16">
                  <div className="d-flex bg-grey base-para">
                    <div className="first-column">
                      <p>
                        {get(programData, 'baseParameters.key')}
                        <span>
                          {get(programData, 'baseParameters.value')}
                        </span>{' '}
                        {get(units, get(programData, 'baseParameters.key'))}
                      </p>
                    </div>
                    <div className="second-column">
                      <p>
                        Quantity
                        <span>
                          {get(programData, 'baseParameters.quantity')}
                        </span>
                      </p>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="16">
                  <div className="d-flex bg-grey base-para mt-0">
                    <p>
                      <label>Preheat Alert</label>
                      <span>{get(programData, 'preheat.text')}</span>
                    </p>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="16">
                  <p className="value text-uppercase">Oven operations</p>
                </Grid.Column>
                <Grid.Column width="16">
                  <div className="oven-operation-form view-only p-0 mt-15">
                    <div className="oven-operation-wrapper">
                      <div className="row head no-border d-flex align-center">
                        <div className="label-column">
                          <label className="heading-lable text-capitalize">
                            Outcome
                          </label>
                        </div>
                        {programData &&
                          programData.operations &&
                          get(programData, 'operations').map((e) => {
                            return (
                              <div className="input-column position-relative">
                                {e.outcome.display}
                              </div>
                            );
                          })}
                      </div>
                      <div className="row">
                        <div className="label-column">
                          {programData && programData.type === 'probe' ? (
                            <label>Probe Based</label>
                          ) : (
                            <label>Time Based</label>
                          )}
                        </div>
                        {programData &&
                          programData.operations &&
                          get(programData, 'operations').map(() => (
                            <div className="input-column empty" />
                          ))}
                      </div>
                      {programData && programData.type === 'probe' ? (
                        <div className="row bg-grey">
                          <div className="label-column">
                            <label>Forecast Time</label>
                          </div>
                          {programData &&
                            programData.operations &&
                            get(programData, 'operations').map((e) => {
                              return (
                                <div className="input-column position-relative d-flex align-end">
                                  {`${Math.floor(e.time / 60)} h : ${
                                    e.time % 60
                                  } m`}
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="row no-border">
                        <div className="label-column">
                          <label className="heading-lable">
                            Cooking step(s)
                          </label>
                        </div>
                        {programData &&
                          programData.operations &&
                          get(programData, 'operations').map(() => (
                            <div className="input-column empty" />
                          ))}
                      </div>
                      {programData && this.modeSteps(programData, stepCount)}

                      {/* <Divider className="mt-0 mlr-0" /> */}
                      <div className="row head no-border mt-15">
                        <div className="label-column">
                          <label className="heading-lable">
                            Final estimated time
                          </label>
                        </div>
                        {programData && programData.type === 'probe'
                          ? programData &&
                            programData.operations &&
                            get(programData, 'operations').map((e) => {
                              return (
                                <div className="input-column position-relative d-flex align-end">
                                  {`${Math.floor(e.time / 60)} h : ${
                                    e.time % 60
                                  } m`}
                                </div>
                              );
                            })
                          : programData &&
                            programData.operations &&
                            get(programData, 'operations').map((e) => {
                              let finalCookingTime = 0;
                              get(e, 'steps').map((s) => {
                                finalCookingTime += s.cookingTime;
                                return finalCookingTime;
                              });
                              return (
                                <div className="input-column position-relative d-flex align-end">
                                  {`${Math.floor(finalCookingTime / 60)} h : ${
                                    finalCookingTime % 60
                                  } m`}
                                </div>
                              );
                            })}
                      </div>
                      <Divider className="mb-0 mlr-0" />
                      <div className="row no-border">
                        <div className="label-column">
                          <label className="heading-lable">
                            Cooking time varies by <br />
                            <span>(Per extra)</span>
                          </label>
                        </div>
                        {programData &&
                          programData.operations &&
                          get(programData, 'operations').map(() => (
                            <div className="input-column empty large" />
                          ))}
                      </div>
                      <div className="row bg-grey">
                        <div className="label-column">
                          <span className="variation-label">
                            {get(programData, 'operations[0].variations.key')}{' '}
                          </span>
                          {get(programData, 'operations[0].variations.value')}{' '}
                          {get(
                            units,
                            get(programData, 'operations[0].variations.key')
                          )}
                        </div>

                        {programData &&
                          programData.operations &&
                          get(programData, 'operations').map((e) => {
                            return (
                              <div className="input-column position-relative ">
                                {e.variations.variation} mins
                              </div>
                            );
                          })}
                      </div>
                      <div className="row">
                        <div className="label-column">
                          <span className="variation-label">Quantity </span>
                          {get(
                            programData,
                            'operations[0].variations.quantity.value'
                          )}
                        </div>
                        {programData &&
                          programData.operations &&
                          get(programData, 'operations').map((e) => {
                            return (
                              <div className="input-column position-relative d-flex align-end">
                                {e.variations.quantity.variation} mins
                              </div>
                            );
                          })}
                      </div>
                      <div className="row no-border">
                        <div className="label-column">
                          <label className="heading-lable">Doneness</label>
                        </div>
                        {programData &&
                          programData.operations &&
                          get(programData, 'operations').map((e) => {
                            return (
                              <div className="input-column">
                                <Image src={e.image.url} />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="16">
                  <p className="value text-uppercase">User Instructions</p>
                </Grid.Column>
                <Grid.Column width="16">
                  <Item.Group items className="user-instruction view-only">
                    {programData &&
                      programData.userInstructions &&
                      get(programData, 'userInstructions').map((e) => {
                        return (
                          <Item>
                            <Item.Content verticalAlign="middle">
                              <Item.Image
                                size="tiny"
                                src={get(e, 'icon.url')}
                              />
                              {get(e, 'instruction')}
                            </Item.Content>
                          </Item>
                        );
                      })}
                  </Item.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.program.program.isLoading,
    program: state.program.program,
  };
};

const mapDispatchToProps = () => {
  return {
    // fetchProgram: (id) => dispatch(programRequested({ id, isTransform: true })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewProgram);
