import { put, call, takeLatest } from 'redux-saga/effects';
import categoryApi from '../../api/categoryApi';
import { CATEGORY_LISTING_REQUESTED } from '../../constants/actions';
import {
  fetchCategoryFailure,
  fetchCategorySuccessful,
} from '../actions/categoryActions';

function* fetchCategory(action) {
  const { response, error } = yield call(
    categoryApi.fetchCategory,
    action.payload
  );
  if (response) {
    yield put(fetchCategorySuccessful(response));
  } else {
    yield put(fetchCategoryFailure(error));
  }
}

export default function* categorySagas() {
  yield takeLatest(CATEGORY_LISTING_REQUESTED, fetchCategory);
}
