import {
  LOGIN_REQUESTED,
  LOGIN_SUCCESSFULL,
  LOGIN_FAILURE,
  VALIDATE_TOKEN_REQUESTED,
  VALIDATE_TOKEN_SUCCESSFULL,
  VALIDATE_TOKEN_FAILURE,
} from '../../constants/actions';

const appReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return { ...state, isLoading: true };

    case LOGIN_SUCCESSFULL:
      return {
        ...state,
        isLoading: false,
        authenticated: true,
        user: { ...action.payload },
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        authenticated: false,
        user: {},
        error: { ...action.payload },
      };

    case VALIDATE_TOKEN_REQUESTED:
      return { ...state, isLoading: true };

    case VALIDATE_TOKEN_SUCCESSFULL:
      return {
        ...state,
        isLoading: false,
        authenticated: true,
        // user: { ...action.payload }
      };

    case VALIDATE_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        authenticated: false,
        user: {},
        // error: { ...action.payload }
      };

    default:
      return state;
  }
};

export default appReducer;
