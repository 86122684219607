import React from 'react';
import { Breadcrumb, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const MainBreadcrumb = (props) => {
  const { step1, step2, step3, step2Link } = props;
  return (
    <>
      <Segment vertical className="m-0">
        <Breadcrumb>
          <Breadcrumb.Section as={Link} to="/dashboard">
            {step1}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="caret right" />
          {step2Link ? (
            <>
              <Breadcrumb.Section as={Link} to={step2Link}>
                {step2}
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="caret right" />
              <Breadcrumb.Section>{step3}</Breadcrumb.Section>
            </>
          ) : (
            <Breadcrumb.Section>{step2}</Breadcrumb.Section>
          )}
        </Breadcrumb>
      </Segment>
    </>
  );
};
export default MainBreadcrumb;
