/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, Component } from 'react';
import { Segment, Sidebar, Tab } from 'semantic-ui-react';
import MainHeader from '../../ui/MainHeader';
import MainBreadcrumb from '../../ui/MainBreadcrumb';
import Navbar from '../../ui/Navbar';
import RegionCountry from './RegionCountry';
import BrandCavity from './BrandCavity';
import Category from './Category';
import Filters from './Filters';
import Modes from './Modes';

const panes = [
  {
    menuItem: 'Region and Country',
    render: () => (
      <Tab.Pane attached={false}>
        <RegionCountry />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Brand and Cavity',
    render: () => (
      <Tab.Pane attached={false}>
        <BrandCavity />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Category',
    render: () => (
      <Tab.Pane attached={false}>
        <Category />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Filters',
    render: () => (
      <Tab.Pane attached={false}>
        <Filters />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Modes',
    render: () => (
      <Tab.Pane attached={false}>
        <Modes />
      </Tab.Pane>
    ),
  },
];

class Meta extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Sidebar.Pushable as={Segment} className="d-flex">
        <Navbar name="meta" />
        <Sidebar.Pusher>
          <MainHeader name="Data Management" />
          <MainBreadcrumb step1="Dashboard" step2="Data Management" />
          <div className="inner-wrapper">
            <Tab
              className="custom-tab"
              menu={{ pointing: true }}
              panes={panes}
            />
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

export default Meta;
