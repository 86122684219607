import { put, call, takeLatest } from 'redux-saga/effects';
import tagsApi from '../../api/tagsApi';
import { TAGS_LISTING_REQUESTED } from '../../constants/actions';
import { fetchTagsFailure, fetchTagsSuccessful } from '../actions/tagsActions';

function* fetchTags(action) {
  const { response, error } = yield call(tagsApi.fetchTags, action.payload);
  if (response) {
    yield put(fetchTagsSuccessful(response));
  } else {
    yield put(fetchTagsFailure(error));
  }
}

export default function* tagsSagas() {
  yield takeLatest(TAGS_LISTING_REQUESTED, fetchTags);
}
