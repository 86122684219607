import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Header,
  // Image,
  Segment,
  Sidebar,
  Table,
  Checkbox,
  Pagination,
  Icon,
  Tab,
  // Label,
  Dropdown,
  Input,
} from 'semantic-ui-react';
import { get } from 'lodash';
import {
  fetchuserRequested,
  userStatusUpdate,
} from '../../../state/actions/userManagementAction';
import MainHeader from '../../ui/MainHeader';
import MainBreadcrumb from '../../ui/MainBreadcrumb';
import Navbar from '../../ui/Navbar';
import AddUser from './AddUser';
import userManagementApi from '../../../api/userManagementApi';
import { getLocalStorageValue } from '../../../utils/sharedFunctions';

const defaultFilters = {
  // sortBy: 'title',
  // sortOrder: 'desc',
  page: 1,
  pageSize: 10,
  type: 'developer',
};
const option = [
  {
    key: '10',
    text: '10',
    value: '10',
  },
  {
    key: '30',
    text: '30',
    value: '30',
  },
  {
    key: '50',
    text: '50',
    value: '50',
  },
];

class UserListing extends Component {
  searchInterval = null;

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      userType: 'developer',
      keyword: '',
      recordCount: '10',
      recordStartNumber: '1',
      recordEndNumber: '10',
    };
    this.fetchData(defaultFilters);
  }

  searchCallback = (searchData) => {
    this.setState({ keyword: searchData.value });
    if (this.searchInterval) clearTimeout(this.searchInterval);
    const { userType } = this.state;
    this.searchInterval = setTimeout(() => {
      this.fetchUsers(userType === 'tester');
    }, 800);
  };

  modalCallback = (status) => {
    this.setState({ openModal: status });
    this.props.fetchUser(defaultFilters);
  };

  fetchData = (filters) => {
    this.props.fetchUser(filters);
  };

  fetchUsers = (isTester = false) => {
    const { keyword } = this.state;
    const filters = defaultFilters;
    if (isTester) filters.type = 'tester';
    else filters.type = 'developer';
    if (keyword.trim() !== '') filters.search = keyword;
    else delete filters.search;
    this.fetchData(filters);
  };

  paginationDropdown = (e, { value }) => {
    const { keyword, userType } = this.state;

    this.setState({
      recordCount: value,
      recordStartNumber: 1,
      recordEndNumber: value,
    });
    const filters = {
      ...defaultFilters,
      pageSize: value,
      type: userType,
      search: keyword,
    };
    this.props.fetchUser(filters);
  };

  getNextUsers = (pageData) => {
    const { recordCount } = this.state;
    const count = parseInt(recordCount, 10);
    const start = parseInt(pageData.activePage - 1, 10) * count + 1;
    const end = start + count - 1;
    this.setState({
      recordStartNumber: start,
      recordEndNumber: end,
    });
    const filters = {
      ...defaultFilters,
      page: pageData.activePage,
      pageSize: recordCount,
    };
    this.props.fetchUser(filters);
  };

  renderPagination = (users) => {
    const { recordCount } = this.state;
    return (
      <>
        {users && users.meta && users.meta.totalRecords > 1 && (
          <Pagination
            boundaryRange={0}
            // defaultActivePage={1}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name="caret left" />, icon: true }}
            nextItem={{ content: <Icon name="caret right" />, icon: true }}
            siblingRange={1}
            totalPages={Math.ceil(users.meta.totalRecords / recordCount)}
            activePage={users.meta.currentPage}
            onPageChange={(pageEvent, pageData) => {
              this.getNextUsers(pageData);
            }}
          />
        )}
      </>
    );
  };

  handlePublishChange = async (index) => {
    const { postFormData, users } = this.props;
    const usersData = get(users, 'data');
    const userId = users && users.data && users.data[index]._id;
    let userStatus = '';
    if (
      (users && users.data && users.data[index].status === 'active') ||
      users.data[index].status === true
    ) {
      userStatus = false;
    }
    if (
      (users && users.data && users.data[index].status === 'inactive') ||
      users.data[index].status === false
    ) {
      userStatus = true;
    }
    usersData[index].status = userStatus;
    postFormData({ data: usersData });

    const payload = {
      active: userStatus,
    };
    const response = await userManagementApi.userActive(payload, userId);
    if (response.error) {
      // console.log(response.error);
      // return;
    }
  };

  renderSearch = () => {
    const { keyword } = this.state;
    return (
      <Input
        value={keyword}
        icon="search"
        placeholder="Search..."
        onChange={(e, searchData) => {
          this.searchCallback(searchData);
        }}
        className="search"
      />
    );
  };

  setPanes = (users) => {
    const { recordCount, recordStartNumber, recordEndNumber } = this.state;
    const role = getLocalStorageValue('role');
    const panes = [
      {
        menuItem: 'DPD User(s)',
        render: () => (
          <Tab.Pane attached={false}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Grid centered verticalAlign="middle">
                    <Grid.Column width="4">
                      <Header as="h5">User List</Header>
                    </Grid.Column>
                    <Grid.Column width="12">
                      <div className="pull-right d-flex options-set">
                        {this.renderSearch()}
                        <Button
                          icon
                          labelPosition="left"
                          secondary
                          disabled={role !== 'admin'}
                          onClick={() => {
                            this.modalCallback(true);
                          }}
                        >
                          <Icon name="add" />
                          Add User
                        </Button>
                      </div>
                    </Grid.Column>
                  </Grid>
                  <div className="list-table">
                    <Table
                      basic="very"
                      sortable
                      structured
                      striped
                      className="program-list add-shadow"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            rowspan="2"
                            className="sticky-column first-column no-shadow"
                          >
                            User(s)
                          </Table.HeaderCell>

                          {/* <Table.HeaderCell rowSpan="2" className="last-used">
                            Last Logged in
                          </Table.HeaderCell> */}
                          <Table.HeaderCell rowSpan="2" textAlign="center">
                            User Status
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {users && users.data ? (
                          users.data.map((el, index) => (
                            <Table.Row>
                              <Table.Cell className="sticky-column first-column">
                                <a href>{el.name}</a>
                              </Table.Cell>

                              <Table.Cell textAlign="center">
                                <Checkbox
                                  checked={get(el, 'status')}
                                  slider
                                  fitted
                                  disabled={role !== 'admin'}
                                  onChange={() =>
                                    this.handlePublishChange(index)
                                  }
                                />
                              </Table.Cell>
                              {/* <Table.Cell
                                textAlign="center"
                                className="sticky-column last-column"
                              >
                                <Image.Group>
                                  <Image className="pointer" src={logsIcon} />
                                  <Image
                                    className="pointer"
                                    src={programBlackIcon}
                                  />
                                </Image.Group>
                              </Table.Cell> */}
                            </Table.Row>
                          ))
                        ) : (
                          <Table.Row>
                            <Table.Cell textAlign="center">
                              <div className="no-data">No records found</div>
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column className="table-pagination">
                  <div className="d-flex align-center">
                    <div className="d-flex align-center">
                      <span className="mr-20">Items per page</span>
                      <Dropdown
                        options={option}
                        selection
                        compact
                        className="mr-20 mini"
                        value={recordCount}
                        onChange={(e, value) => {
                          this.paginationDropdown(e, value);
                        }}
                      />
                      {users && users.meta ? (
                        <span className="mr-20">
                          {recordStartNumber}-
                          {recordEndNumber > users.meta.totalRecords
                            ? users.meta.totalRecords
                            : recordEndNumber}{' '}
                          of {users.meta.totalRecords}
                        </span>
                      ) : null}
                    </div>
                    {this.renderPagination(users)}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'TA User(s)',
        render: () => (
          <Tab.Pane attached={false}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Grid centered verticalAlign="middle">
                    <Grid.Column width="4">
                      <Header as="h5">User List</Header>
                    </Grid.Column>
                    <Grid.Column width="12">
                      <div className="pull-right d-flex options-set">
                        {/* <div className="graph-label d-flex mr-20">
                          <div className="mr-20 d-flex align align-center">
                            <Label
                              size="mini"
                              circular
                              className="completed"
                              empty
                            />{' '}
                            Completed
                          </div>
                          <div className="d-flex align align-center">
                            <Label
                              size="mini"
                              circular
                              className="failed"
                              empty
                            />{' '}
                            Failed
                          </div>
                        </div> */}
                        {this.renderSearch()}
                        <Button
                          icon
                          labelPosition="left"
                          secondary
                          disabled={role === 'tester'}
                          onClick={() => {
                            this.modalCallback(true);
                          }}
                        >
                          <Icon name="add" />
                          Add User
                        </Button>
                      </div>
                    </Grid.Column>
                  </Grid>
                  <div className="list-table">
                    <Table
                      basic="very"
                      sortable
                      structured
                      striped
                      className="program-list add-shadow"
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            rowspan="2"
                            className="sticky-column first-column no-shadow"
                          >
                            User(s)
                          </Table.HeaderCell>
                          {/* <Table.HeaderCell
                            textAlign="center"
                            colSpan="4"
                            className="review-status"
                          >
                            Programs Created
                          </Table.HeaderCell>
                          <Table.HeaderCell rowSpan="2" className="last-used">
                            Last Logged in
                          </Table.HeaderCell> */}
                          <Table.HeaderCell rowSpan="2" textAlign="center">
                            User Status
                          </Table.HeaderCell>
                          {/* <Table.HeaderCell
                            rowSpan="2"
                            textAlign="center"
                            className="sticky-column last-column no-shadow"
                          >
                            Actions
                          </Table.HeaderCell> */}
                        </Table.Row>
                        {/* <Table.Row>
                          <Table.HeaderCell textAlign="center">
                            Meat
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">
                            Fish
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">
                            Meat
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">
                            Veg.
                          </Table.HeaderCell>
                        </Table.Row> */}
                      </Table.Header>

                      <Table.Body>
                        {users && users.data ? (
                          users.data.map((el, index) => (
                            <Table.Row>
                              <Table.Cell className="sticky-column first-column">
                                <a href>{el.name}</a>
                              </Table.Cell>
                              {/* <Table.Cell textAlign="center">
                                <Label circular>2</Label>
                                <Label circular>2</Label>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Label circular>8</Label>
                                <Label circular>6</Label>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Label circular>5</Label>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Label circular>9</Label>
                              </Table.Cell>
                              <Table.Cell>{el.last_login}</Table.Cell> */}
                              <Table.Cell textAlign="center">
                                <Checkbox
                                  checked={get(el, 'status')}
                                  slider
                                  fitted
                                  disabled={role === 'tester'}
                                  onChange={() =>
                                    this.handlePublishChange(index)
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))
                        ) : (
                          <Table.Row>
                            <Table.Cell colSpan="2" textAlign="center">
                              <div className="no-data">No records found</div>
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column className="table-pagination">
                  <div className="d-flex align-center">
                    <div className="d-flex align-center">
                      <span className="mr-20">Items per page</span>
                      <Dropdown
                        options={option}
                        selection
                        compact
                        className="mr-20 mini"
                        value={recordCount}
                        onChange={(e, value) => {
                          this.paginationDropdown(e, value);
                        }}
                      />
                      {users && users.meta ? (
                        <span className="mr-20">
                          {recordStartNumber}-
                          {recordEndNumber > users.meta.totalRecords
                            ? users.meta.totalRecords
                            : recordEndNumber}{' '}
                          of {users.meta.totalRecords}
                        </span>
                      ) : null}
                    </div>
                    {this.renderPagination(users)}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Tab.Pane>
        ),
      },
    ];
    return panes;
  };

  render() {
    const { openModal, userType } = this.state;
    const { users } = this.props;

    return (
      <Sidebar.Pushable as={Segment} className="d-flex">
        <Navbar name="userList" />
        <Sidebar.Pusher>
          <MainHeader name="User Management" />
          <MainBreadcrumb step1="Dashboard" step2="User Management" />
          <div className="inner-wrapper">
            <Tab
              className="custom-tab"
              menu={{ pointing: true }}
              panes={this.setPanes(users)}
              onTabChange={(e, tabData) => {
                this.setState(
                  {
                    userType: tabData.activeIndex ? 'tester' : 'developer',
                    keyword: '',
                    recordStartNumber: '1',
                    recordEndNumber: '10',
                  },
                  () => {
                    this.fetchUsers(tabData.activeIndex);
                  }
                );
              }}
            />
          </div>
          <AddUser
            isOpen={openModal}
            closeModal={() => {
              this.modalCallback(false);
            }}
            userType={userType}
          />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.userManagement.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (filters) => dispatch(fetchuserRequested(filters)),
    postFormData: (data) => dispatch(userStatusUpdate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListing);
