import ApiUtils from './apiUtils';

const fetchTags = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);

  try {
    const response = await ApiUtils.get(`api/tags/search?${queryString}`);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const postTags = async (param) => {
  try {
    const response = await ApiUtils.post(`api/tags`, param);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchTags,
  postTags,
};
