import {
  USER_LISTING_FAILURE,
  USER_LISTING_REQUESTED,
  USER_LISTING_SUCCESSFUL,
  USER_STATUS,
} from '../../constants/actions';

export const fetchuserRequested = (data) => {
  return {
    type: USER_LISTING_REQUESTED,
    payload: data,
  };
};

export const fetchuserSuccessful = (data) => {
  return {
    type: USER_LISTING_SUCCESSFUL,
    payload: data,
  };
};

export const fetchuserFailure = (data) => {
  return {
    type: USER_LISTING_FAILURE,
    payload: data,
  };
};

export const userStatusUpdate = (data) => {
  return {
    type: USER_STATUS,
    payload: data,
  };
};
