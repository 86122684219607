import { combineReducers } from 'redux';
import {
  PROGRAMS_FAILURE,
  PROGRAMS_REQUESTED,
  PROGRAMS_SUCCESSFUL,
  USER_FEEDBACKUPDATE,
} from '../../constants/actions';

const programsReducer = (state = {}, action) => {
  switch (action.type) {
    case PROGRAMS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case PROGRAMS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case PROGRAMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case USER_FEEDBACKUPDATE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  programs: programsReducer,
});
