import {
  PROGRAM_FAILURE,
  PROGRAM_REQUESTED,
  PROGRAM_SUCCESSFUL,
  PROGRAMFORM_REQUESTED,
  PROGRAMFORM_UPDATE,
  USER_FEEDBACKUPDATE,
  PROGRAM_USERFEEDBACKUPDATE,
} from '../../constants/actions';

export const programRequested = (data) => {
  return {
    type: PROGRAM_REQUESTED,
    payload: data,
  };
};

export const programSuccessful = (data) => {
  return {
    type: PROGRAM_SUCCESSFUL,
    payload: data,
  };
};

export const programFailure = (data) => {
  return {
    type: PROGRAM_FAILURE,
    payload: data,
  };
};

export const programFormRequested = (data) => {
  return {
    type: PROGRAMFORM_REQUESTED,
    payload: data,
  };
};

export const programFormUpdate = (data) => {
  return {
    type: PROGRAMFORM_UPDATE,
    payload: data,
  };
};

export const userFeedbackUpdate = (data) => {
  return {
    type: USER_FEEDBACKUPDATE,
    payload: data,
  };
};

export const programUserFeedbackUpdate = (data) => {
  return {
    type: PROGRAM_USERFEEDBACKUPDATE,
    payload: data,
  };
};
