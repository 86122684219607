import { combineReducers } from 'redux';
import {
  DASHBOARD_PROGRAMS_FAILURE,
  DASHBOARD_PROGRAMS_REQUESTED,
  DASHBOARD_PROGRAMS_SUCCESSFUL,
  // DASHBOARD_CHARTDATA_REQUESTED,
  // DASHBOARD_CHARTDATA_SUCCESSFUL,
  // DASHBOARD_CHARTDATA_FAILURE,
} from '../../constants/actions';

const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_PROGRAMS_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case DASHBOARD_PROGRAMS_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case DASHBOARD_PROGRAMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    //   case DASHBOARD_CHARTDATA_REQUESTED:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };

    // case DASHBOARD_CHARTDATA_SUCCESSFUL:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     ...action.payload,
    //   };

    // case DASHBOARD_CHARTDATA_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     ...action.payload,
    //   };

    default:
      return state;
  }
};

export default combineReducers({
  dashboardPrograms: dashboardReducer,
});
