import { combineReducers } from 'redux';
import {
  USER_LISTING_FAILURE,
  USER_LISTING_REQUESTED,
  USER_LISTING_SUCCESSFUL,
  USER_STATUS,
} from '../../constants/actions';

const userManagementReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LISTING_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case USER_LISTING_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case USER_LISTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case USER_STATUS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  users: userManagementReducer,
});
