import { combineReducers } from 'redux';
import {
  MODES_LISTING_REQUESTED,
  MODES_LISTING_SUCCESSFUL,
  MODES_LISTING_FAILURE,
} from '../../constants/actions';

const modesReducer = (state = {}, action) => {
  switch (action.type) {
    case MODES_LISTING_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case MODES_LISTING_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case MODES_LISTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  modes: modesReducer,
});
