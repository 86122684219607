import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Image,
  // Input,
  Item,
  Label,
  Modal,
  Segment,
  Sidebar,
  Statistic,
  Tab,
  TextArea,
  Menu,
} from 'semantic-ui-react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import MainHeader from '../../ui/MainHeader';
import MainBreadcrumb from '../../ui/MainBreadcrumb';
import Navbar from '../../ui/Navbar';
import editIcon from '../../../assets/images/edit.svg';
import satisfied from '../../../assets/images/satisfied.svg';
import feedbackIcon from '../../../assets/images/feedback-icon.svg';
import notSatisfied from '../../../assets/images/not-satisfied.svg';
import greenFlag from '../../../assets/images/flags/green-flag.svg';
import yellowFlag from '../../../assets/images/flags/yellow-flag.svg';
import blueFlag from '../../../assets/images/flags/blue-flag.svg';
import pinkFlag from '../../../assets/images/flags/pink-flag.svg';
import redFlag from '../../../assets/images/flags/red-flag.svg';
import whiteFlag from '../../../assets/images/flags/flag.svg';
import ProgramSessions from './ProgramSessions';
import ViewProgram from './ViewProgram';
import {
  programRequested,
  programUserFeedbackUpdate,
} from '../../../state/actions/programAction';
import { programUsersRequested } from '../../../state/actions/programUsersAction';
import programUsersApi from '../../../api/programUsersApi';

const ratings = [1, 2, 3, 4, 5];
class ProgramDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      // ratingValue: 4,
    };
    this.fetchData();
  }

  fetchData = () => {
    this.props.fetchProgram(this.props.match.params.id, false, true);
  };

  fetchUser = (userId) => {
    const { program } = this.props;
    const variationId = get(program, 'data.data.versionId') || 0;
    const programId = get(program, 'data.data._id') || 0;
    this.props.fetchUserProgram(programId, variationId, userId);
  };

  setUsers = (usersList) => {
    const consumersTab =
      usersList && usersList.length > 0 ? (
        usersList.map((user) => ({
          menuItem: (
            <Menu.Item
              className="d-flex justify-content-between align-center"
              onClick={() => this.fetchUser(user.id)}
            >
              {user.name}
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane>
              <ProgramSessions id={user.id} />
            </Tab.Pane>
          ),
        }))
      ) : (
        <div className="no-data">No Users</div>
      );
    return consumersTab;
  };

  handlePublishChange = async () => {
    const { postFormData, program } = this.props;
    const programData = get(program, 'data.data');
    const programId = get(programData, '_id');
    programData.published = !programData.published;
    postFormData({
      data: {
        data: programData,
      },
    });
    const payload = {
      published: programData.published,
    };
    const response = await programUsersApi.userUpdateFeedback(
      payload,
      programId
    );
    if (response.error) {
      // console.log(response.error);
      // return;
    }
  };

  handleSubmit = async () => {
    const { program } = this.props;
    const programId = get(program, 'data.data._id');
    this.setState({ openModal: false });
    const feedbackData = get(program, 'data.data');
    const internal = get(feedbackData, 'internalFeedback');
    const payload = {
      internalFeedback: {
        rating: internal.rating,
        feedback: internal.feedback,
      },
    };
    const response = await programUsersApi.userUpdateFeedback(
      payload,
      programId
    );
    if (response.error) {
      // console.log(response.error);
      // return;
    }
  };

  render() {
    const { openModal } = this.state;
    const { program, postFormData } = this.props;
    const programData = get(program, 'data.data');
    const consumerData = get(program, 'userResponsedata');
    const flags = get(programData, 'flag');
    const id = programData && get(programData, '_id');
    let flagValue = whiteFlag;
    if (flags === 'green') flagValue = greenFlag;
    if (flags === 'yellow') flagValue = yellowFlag;
    if (flags === 'blue') flagValue = blueFlag;
    if (flags === 'pink') flagValue = pinkFlag;
    if (flags === 'red') flagValue = redFlag;

    return (
      <Sidebar.Pushable as={Segment} className="d-flex">
        <Navbar />
        <Sidebar.Pusher>
          <MainHeader
            name="Program Details"
            buttonName="Back to list"
            iconName="long arrow alternate left"
            path="/programs"
            linkButton
          />
          {/* write step2 as program title in breadcrumb step2="Programs" */}
          <MainBreadcrumb
            step1="Dashboard"
            step2="Programs"
            step2Link="/programs"
            step3={get(programData, 'title')}
          />
          <Segment className="plr-0 m-0 program-details-header-wrap">
            <div className="inner-wrapper ptb-0">
              <Grid divided className="program-details-header mtb-0">
                <Grid.Column width="4">
                  <Item.Group>
                    <Item>
                      <Item.Image
                        size="tiny"
                        src={get(programData, 'images[0].url')}
                      />
                      <Item.Content>
                        <Image src={flagValue} className="flag-icon" />
                        <Item.Header>
                          {get(programData, 'title')}(v
                          {get(programData, 'version')})
                        </Item.Header>
                        <Item.Meta>
                          {get(programData, 'internalTitle')}
                        </Item.Meta>
                        <Item.Extra>
                          <ViewProgram />
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </Grid.Column>
                <Grid.Column width="3">
                  <div className="d-flex align-center">
                    <label className="mr-20">Published</label>
                    <Checkbox
                      slider
                      fitted
                      checked={
                        programData &&
                        programData.published !== undefined &&
                        get(programData, 'published')
                      }
                      onChange={() => this.handlePublishChange()}
                    />
                  </div>
                  <Image
                    src={editIcon}
                    className="mt-10"
                    as={Link}
                    to={`/programs/${id}/edit`}
                    // href={`/programs/${id}/edit`}
                  />
                </Grid.Column>
                <Grid.Column width="9">
                  <Statistic.Group size="mini">
                    <Statistic>
                      <Statistic.Label>Program ID</Statistic.Label>
                      <Statistic.Value>
                        {get(programData, 'sequence') || '-'}
                      </Statistic.Value>
                    </Statistic>
                    <Statistic>
                      <Statistic.Label>Category</Statistic.Label>
                      <Statistic.Value>
                        {get(programData, 'category.name')}
                      </Statistic.Value>
                    </Statistic>
                    <Statistic>
                      <Statistic.Label>Total Reviews</Statistic.Label>
                      <Statistic.Value>
                        <div className="graph-label d-flex mr-20">
                          <div className="mr-20 d-flex align align-center">
                            {get(programData, 'executionSummary.completed') !==
                              undefined &&
                            get(programData, 'executionSummary.completed') !==
                              0 ? (
                              <>
                                <Label
                                  size="mini"
                                  circular
                                  className="completed"
                                  content={get(
                                    programData,
                                    'executionSummary.completed'
                                  )}
                                />{' '}
                                Completed
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="d-flex align align-center">
                            {get(programData, 'executionSummary.completed') !==
                              undefined &&
                            get(programData, 'executionSummary.completed') !==
                              0 ? (
                              <>
                                <Label
                                  size="mini"
                                  circular
                                  className="failed"
                                  content={get(
                                    programData,
                                    'executionSummary.failed'
                                  )}
                                />{' '}
                                Failed
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </Statistic.Value>
                    </Statistic>
                    <Statistic>
                      <Statistic.Label>Consumer Feedback</Statistic.Label>
                      <Statistic.Value>
                        <div className="d-flex align-center justify-center">
                          {get(programData, 'userFeedback') !== undefined &&
                          (get(programData, 'userFeedback.satisfied') !== 0 ||
                            get(programData, 'userFeedback.dissatisfied') !==
                              0) ? (
                            <Image
                              src={
                                get(programData, 'userFeedback.satisfied') >=
                                get(programData, 'userFeedback.dissatisfied')
                                  ? satisfied
                                  : notSatisfied
                              }
                              className="mr-10"
                            />
                          ) : (
                            ''
                          )}
                          {get(programData, 'userFeedback') !== undefined &&
                          get(programData, 'userFeedback.satisfied') !== 0 &&
                          get(programData, 'userFeedback.dissatisfied') !==
                            0 ? (
                            <p>
                              {get(programData, 'userFeedback.satisfied') >=
                              get(programData, 'userFeedback.dissatisfied')
                                ? 'Satisfied'
                                : 'Dissatisfied'}
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                      </Statistic.Value>
                    </Statistic>
                    <Statistic>
                      <Statistic.Label>Internal Feedback</Statistic.Label>
                      <Statistic.Value className="d-flex align-center">
                        {get(programData, 'internalFeedback.rating') ? (
                          <>
                            <Label
                              circular
                              className={
                                get(programData, 'internalFeedback.rating') >= 3
                                  ? 'rating-label light-success'
                                  : 'rating-label light-failed'
                              }
                            >
                              {get(programData, 'internalFeedback.rating')}
                            </Label>{' '}
                            / 5
                          </>
                        ) : (
                          ''
                        )}
                        <Modal
                          size="small"
                          trigger={
                            <Image
                              src={feedbackIcon}
                              className="pointer ml-10 mr-10"
                            />
                          }
                          className="feedback-modal"
                          onClose={() => this.setState({ openModal: false })}
                          onOpen={() => this.setState({ openModal: true })}
                          open={openModal}
                        >
                          <Modal.Content>
                            <div className="title-wrap d-flex justify-content-between align-center">
                              <div className="d-flex align-center">
                                <Image src={feedbackIcon} className="mr-10" />
                                Internal feedback of this session
                              </div>
                              {/* <div>Last updated: 28th Oct 2020 | 13:12</div> */}
                            </div>
                            <Form className="mt-15">
                              <TextArea
                                rows="5"
                                value={
                                  get(
                                    programData,
                                    'internalFeedback.feedback'
                                  ) || ''
                                }
                                onChange={(e) => {
                                  if (!programData.internalFeedback)
                                    programData.internalFeedback = {};
                                  programData.internalFeedback.feedback =
                                    e.target.value;
                                  postFormData({
                                    data: {
                                      data: programData,
                                    },
                                  });
                                }}
                              />
                              <div className="d-flex justify-content-between align-center mt-15">
                                <div className="rating-col">
                                  <span className="mr-20">Ratings</span>
                                  {ratings.map((r) => (
                                    <Label
                                      circular
                                      key={r}
                                      active={
                                        Number(
                                          get(
                                            programData,
                                            'internalFeedback.rating'
                                          )
                                        ) || 4
                                      }
                                      className={
                                        r ===
                                        (get(
                                          programData,
                                          'internalFeedback.rating'
                                        ) || 4)
                                          ? 'light-success pointer rating-label'
                                          : 'pointer rating-label'
                                      }
                                      onClick={(e, data) => {
                                        if (!programData.internalFeedback)
                                          programData.internalFeedback = {};
                                        programData.internalFeedback.rating =
                                          data.children;
                                        postFormData({
                                          data: {
                                            data: programData,
                                          },
                                        });
                                      }}
                                    >
                                      {r}
                                    </Label>
                                  ))}
                                </div>
                                <div className="button-wrapper">
                                  <Button
                                    compact
                                    className="link-button"
                                    onClick={() =>
                                      this.setState({ openModal: false })
                                    }
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    secondary
                                    compact
                                    onClick={() => this.handleSubmit()}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                            </Form>
                          </Modal.Content>
                        </Modal>
                      </Statistic.Value>
                    </Statistic>
                  </Statistic.Group>
                </Grid.Column>
              </Grid>
            </div>
          </Segment>
          <div className="select-region-tabs consumers-tab">
            {consumerData &&
            consumerData.data &&
            consumerData.data.length > 0 ? (
              <>
                <Grid className="m-0 region-grid">
                  <Grid.Column textAlign="center" width={4} className="bg-grey">
                    <Header as="h5" className="font-regular mt-15">
                      Consumers
                    </Header>
                    <Form>
                      <Form.Field>
                        {/* <Input icon="search" placeholder="Search..." /> */}
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                  <Grid.Column width={12} />
                </Grid>

                <Tab
                  menu={{ vertical: true, tabular: true, fluid: true }}
                  panes={this.setUsers(consumerData.data)}
                  className="program-details-tabs"
                />
              </>
            ) : (
              <div className="no-data mt-125">No sessions recorded yet</div>
            )}
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.program.program.isLoading,
    program: state.program.program,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProgram: (id) =>
      dispatch(
        programRequested({ id, isTransform: false, requireProgramUser: true })
      ),
    fetchUserProgram: (programId, variationId, userId) =>
      dispatch(programUsersRequested({ programId, variationId, userId })),
    postFormData: (data) => dispatch(programUserFeedbackUpdate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDetails);
