import { combineReducers } from 'redux';
import {
  FILES_LISTING_REQUESTED,
  FILES_LISTING_SUCCESSFUL,
  FILES_LISTING_FAILURE,
} from '../../constants/actions';

const filesReducer = (state = {}, action) => {
  switch (action.type) {
    case FILES_LISTING_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case FILES_LISTING_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case FILES_LISTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  files: filesReducer,
});
