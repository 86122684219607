import { normalize } from 'normalizr';
import { filesList } from '../state/schemas';
import ApiUtils from './apiUtils';

const fetchFiles = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);
  try {
    const response = await ApiUtils.get(`api/files?${queryString}`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, filesList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const postFiles = async (param, payload) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);
  try {
    const response = await ApiUtils.post(`api/files?${queryString}`, payload);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchFiles,
  postFiles,
};
