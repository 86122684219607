import { combineReducers } from 'redux';
import appReducer from './appReducer';
import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';
import userManagementReducer from './userManagementReducer';
import categoryReducer from './categoryReducer';
import brandsReducer from './brandsReducer';
import regionReducer from './regionReducer';
import modesReducer from './modesReducer';
import filterReducer from './filterReducer';
import filesReducer from './filesReducer';
import tagsReducer from './tagsReducer';
import programReducer from './programReducer';
import programsReducer from './programsReducer';
import programUsersReducer from './programUsersReducer';
import dashboardSummaryReducer from './dashboardSummary';
import dashboardChartReducer from './dashboardChartReducer';

const rootReducer = combineReducers({
  // Add reducers here
  auth: authReducer,
  app: appReducer,
  dashboard: dashboardReducer,
  userManagement: userManagementReducer,
  categories: categoryReducer,
  brands: brandsReducer,
  regions: regionReducer,
  modes: modesReducer,
  filters: filterReducer,
  files: filesReducer,
  tags: tagsReducer,
  program: programReducer,
  programs: programsReducer,
  programUsers: programUsersReducer,
  dashboardSummary: dashboardSummaryReducer,
  dashboardChart: dashboardChartReducer,
});

export default rootReducer;
