import { React, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Label,
  List,
  Pagination,
  Popup,
  Radio,
  Segment,
  Sidebar,
  Table,
} from 'semantic-ui-react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MainHeader from '../../ui/MainHeader';
import MainBreadcrumb from '../../ui/MainBreadcrumb';
import Navbar from '../../ui/Navbar';
import logsIcon from '../../../assets/images/edit.svg';
import programBlackIcon from '../../../assets/images/file.svg';
import satisfied from '../../../assets/images/satisfied.svg';
import notSatisfied from '../../../assets/images/not-satisfied.svg';
import greenFlag from '../../../assets/images/flags/green-flag.svg';
import yellowFlag from '../../../assets/images/flags/yellow-flag.svg';
import blueFlag from '../../../assets/images/flags/blue-flag.svg';
import pinkFlag from '../../../assets/images/flags/pink-flag.svg';
import redFlag from '../../../assets/images/flags/red-flag.svg';
import whiteFlag from '../../../assets/images/flags/flag.svg';
import { fetchProgramsRequested } from '../../../state/actions/programsActions';
import { userFeedbackUpdate } from '../../../state/actions/programAction';
import programUsersApi from '../../../api/programUsersApi';
import { fetchCategoryRequested } from '../../../state/actions/categoryActions';

const defaultFilters = {
  page: 1,
  pageSize: 10,
};

const option = [
  {
    key: '10',
    text: '10',
    value: '10',
  },
  {
    key: '30',
    text: '30',
    value: '30',
  },
  {
    key: '50',
    text: '50',
    value: '50',
  },
];

const flagOptions = [
  {
    key: 'whiteFlag',
    value: 'white',
    image: { src: `${whiteFlag}` },
  },
  {
    key: 'greenFlag',
    value: 'green',
    image: { src: `${greenFlag}` },
  },
  {
    key: 'yellowFlag',
    value: 'yellow',
    image: { src: `${yellowFlag}` },
  },
  {
    key: 'blueFlag',
    value: 'blue',
    image: { src: `${blueFlag}` },
  },
  {
    key: 'pinkFlag',
    value: 'pink',
    image: { src: `${pinkFlag}` },
  },
  {
    key: 'redFlag',
    value: 'red',
    image: { src: `${redFlag}` },
  },
];
const flagOption = [
  {
    key: '1',
    value: '1',
    text: 'All',
  },
  {
    key: 'whiteFlag',
    value: 'white',
    image: { src: `${whiteFlag}` },
  },
  {
    key: 'greenFlag',
    value: 'green',
    image: { src: `${greenFlag}` },
  },
  {
    key: 'yellowFlag',
    value: 'yellow',
    image: { src: `${yellowFlag}` },
  },
  {
    key: 'blueFlag',
    value: 'blue',
    image: { src: `${blueFlag}` },
  },
  {
    key: 'pinkFlag',
    value: 'pink',
    image: { src: `${pinkFlag}` },
  },
  {
    key: 'redFlag',
    value: 'red',
    image: { src: `${redFlag}` },
  },
];
class ProgramList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordCount: '10',
      recordStartNumber: '1',
      recordEndNumber: '10',
      searchText: '',
      flagValue: '',
      selectCategory: '',
      startDate: '',
      endDate: '',
      selectedWeek: '',
      pageNumber: '1',
    };
    this.fetchData(defaultFilters);
  }

  fetchData = (filters) => {
    this.props.fetchPrograms(filters);
    this.props.fetchCategory();
  };

  paginationDropdown = (e, { value }) => {
    const {
      searchText,
      flagValue,
      startDate,
      endDate,
      selectCategory,
      pageNumber,
    } = this.state;
    this.setState({
      recordCount: value,
      recordStartNumber: 1,
      recordEndNumber: value,
    });
    const filters = {
      ...defaultFilters,
      page: pageNumber,
      pageSize: value,
      category: selectCategory,
      flag: flagValue,
      search: searchText,
      startDate,
      endDate,
    };
    this.props.fetchPrograms(filters);
  };

  programsByCatgory = (e, { value }) => {
    const {
      searchText,
      flagValue,
      startDate,
      endDate,
      pageNumber,
    } = this.state;
    if (value !== '1') {
      this.setState({ selectCategory: value });
      const filters = {
        ...defaultFilters,
        page: pageNumber,
        category: value,
        flag: flagValue,
        search: searchText,
        startDate,
        endDate,
      };
      this.props.fetchPrograms(filters);
    } else {
      this.setState({ selectCategory: '' });
      const filters = {
        ...defaultFilters,
        page: pageNumber,
        flag: flagValue,
        search: searchText,
        startDate,
        endDate,
      };
      this.props.fetchPrograms(filters);
    }
  };

  programsBySearch = (e, { value }) => {
    const {
      selectCategory,
      flagValue,
      startDate,
      endDate,
      pageNumber,
    } = this.state;
    this.setState({ searchText: value });
    const filters = {
      ...defaultFilters,
      page: pageNumber,
      category: selectCategory,
      flag: flagValue,
      search: value,
      startDate,
      endDate,
    };
    this.props.fetchPrograms(filters);
  };

  programsByFlag = (e, { value }) => {
    const {
      selectCategory,
      searchText,
      startDate,
      endDate,
      pageNumber,
    } = this.state;
    if (value !== '1') {
      this.setState({ flagValue: value });
      const filters = {
        ...defaultFilters,
        page: pageNumber,
        flag: value,
        category: selectCategory,
        search: searchText,
        startDate,
        endDate,
      };
      this.props.fetchPrograms(filters);
    } else {
      this.setState({ flagValue: '' });
      const filters = {
        ...defaultFilters,
        page: pageNumber,
        category: selectCategory,
        search: searchText,
        startDate,
        endDate,
      };
      this.props.fetchPrograms(filters);
    }
  };

  programsByWeek = (value) => {
    const { selectCategory, searchText, flagValue, pageNumber } = this.state;
    const start = moment()
      .subtract(value.charAt(0), 'weeks')
      .format('YYYY-MM-DD');
    const end = moment().format('YYYY-MM-DD');
    this.setState({ startDate: start, endDate: end, selectedWeek: value });
    const filters = {
      ...defaultFilters,
      flag: flagValue,
      page: pageNumber,
      category: selectCategory,
      search: searchText,
      startDate: start,
      endDate: end,
    };
    this.props.fetchPrograms(filters);
  };

  handleReset = () => {
    const { selectCategory, searchText, flagValue } = this.state;
    this.setState({ startDate: '', endDate: '', selectedWeek: '' });
    const filters = {
      ...defaultFilters,
      flag: flagValue,
      category: selectCategory,
      search: searchText,
    };
    this.props.fetchPrograms(filters);
  };

  getNextPrograms = (pageData) => {
    const {
      recordCount,
      flagValue,
      selectCategory,
      searchText,
      startDate,
      endDate,
    } = this.state;

    const count = parseInt(recordCount, 10);
    const start = parseInt(pageData.activePage - 1, 10) * count + 1;
    const end = start + count - 1;

    this.setState({
      recordStartNumber: start,
      recordEndNumber: end,
      pageNumber: pageData.activePage,
    });
    const filters = {
      page: pageData.activePage,
      pageSize: recordCount,
      flag: flagValue,
      category: selectCategory,
      search: searchText,
      startDate,
      endDate,
    };
    this.props.fetchPrograms(filters);
  };

  renderPagination = (programs) => {
    const { recordCount } = this.state;
    return (
      <>
        {programs && programs.meta && programs.meta.totalRecords > 1 && (
          <Pagination
            boundaryRange={0}
            defaultActivePage={1}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name="caret left" />, icon: true }}
            nextItem={{ content: <Icon name="caret right" />, icon: true }}
            siblingRange={1}
            totalPages={Math.ceil(programs.meta.totalRecords / recordCount)}
            onPageChange={(pageEvent, pageData) => {
              this.getNextPrograms(pageData);
            }}
          />
        )}
      </>
    );
  };

  handlePublishChange = async (program, index) => {
    const { postFormData, programs } = this.props;
    const programData = get(programs, 'data');
    const programId = get(program, '_id');
    programData[index].published = !programData[index].published;
    const payload = {
      published: programData[index].published,
    };
    postFormData({ data: programData });
    const response = await programUsersApi.userUpdateFeedback(
      payload,
      programId
    );
    if (response.error) {
      // console.log(response.error);
      // return;
    }
  };

  handleFlagChange = async (program, index, value) => {
    const { postFormData, programs } = this.props;
    const programData = get(programs, 'data');
    const programId = get(program, '_id');
    programData[index].flag = value;
    const payload = {
      flag: programData[index].flag,
    };
    postFormData({ data: programData });
    const response = await programUsersApi.userUpdateFeedback(
      payload,
      programId
    );
    if (response.error) {
      // console.log(response.error);
      // return;
    }
  };

  getCategories = () => {
    const { categories } = this.props;
    if (!categories || !categories.data) return [];
    const categoriesOptions = [{ key: '1', text: 'All', value: '1' }];
    categories.data.map((category) => {
      return categoriesOptions.push({
        key: category._id,
        text: category.name,
        value: category._id,
      });
    });
    return categoriesOptions;
  };

  render() {
    const { programs } = this.props;
    const {
      recordCount,
      recordStartNumber,
      recordEndNumber,
      searchText,
      selectedWeek,
    } = this.state;
    return (
      <Sidebar.Pushable as={Segment} className="d-flex">
        <Navbar name="programs" />
        <Sidebar.Pusher>
          <MainHeader
            name="Programs"
            buttonName="Create New Program"
            iconName="add"
            path="/programs/create"
            secondary
          />
          <MainBreadcrumb step1="Dashboard" step2="Programs" />
          <div className="inner-wrapper">
            <Grid>
              <Grid.Row>
                <Grid.Column width="8">
                  <Form>
                    <Form.Group className="m-0">
                      <Form.Field inline className="list-filter">
                        {/* <Button.Group
                          value="1W"
                          size="mini"
                          buttons={['1W', '2W', '3W', '4W']}
                          onClick={(e) => {
                            this.programsByWeek(e.target.innerText);
                          }}
                        /> */}
                        <Radio
                          label="1W"
                          name="radioGroup"
                          value="1W"
                          checked={selectedWeek === '1W'}
                          onChange={(e, { value }) => {
                            this.programsByWeek(value);
                          }}
                        />
                        <Radio
                          label="2W"
                          name="radioGroup"
                          value="2W"
                          checked={selectedWeek === '2W'}
                          onChange={(e, { value }) => {
                            this.programsByWeek(value);
                          }}
                        />
                        <Radio
                          label="3W"
                          name="radioGroup"
                          value="3W"
                          checked={selectedWeek === '3W'}
                          onChange={(e, { value }) => {
                            this.programsByWeek(value);
                          }}
                        />
                        <Radio
                          label="4W"
                          name="radioGroup"
                          value="4W"
                          checked={selectedWeek === '4W'}
                          onChange={(e, { value }) => {
                            this.programsByWeek(value);
                          }}
                        />
                      </Form.Field>
                      <Form.Field
                        className="d-flex align-center
                      "
                      >
                        <Button
                          onClick={() => {
                            this.handleReset();
                          }}
                          className="link-button"
                        >
                          Reset
                        </Button>
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Grid.Column>
                <Grid.Column width="8">
                  <Form className="pull-right">
                    <Form.Group className="m-0">
                      <Form.Field>
                        <Dropdown
                          placeholder="Flags"
                          fluid
                          selection
                          className="tiny flag-dropdown"
                          options={flagOption}
                          onChange={(e, value) => this.programsByFlag(e, value)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Dropdown
                          placeholder="Category"
                          // fluid
                          selection
                          className="tiny"
                          options={[...this.getCategories()]}
                          onChange={(e, value) =>
                            this.programsByCatgory(e, value)
                          }
                        />
                      </Form.Field>
                      <Form.Field className="options-set">
                        <Input
                          icon="search"
                          placeholder="Search..."
                          className="search left"
                          value={searchText}
                          onChange={(e, value) =>
                            this.programsBySearch(e, value)
                          }
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <div className="list-table mt-0">
                  <Table
                    basic="very"
                    sortable
                    structured
                    singleLine
                    striped
                    className="program-list add-shadow"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          rowspan="2"
                          className="sticky-column first-column plr-half"
                        />
                        <Table.HeaderCell
                          rowspan="2"
                          className="second-column sticky-column"
                          width="4"
                        >
                          Program Title
                        </Table.HeaderCell>
                        <Table.HeaderCell rowspan="2" width="4">
                          Internal Title
                        </Table.HeaderCell>
                        <Table.HeaderCell width="2" rowspan="2">
                          Category
                        </Table.HeaderCell>
                        <Table.HeaderCell width="2" rowspan="2">
                          Cavities
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          width="2"
                          rowspan="2"
                          textAlign="center"
                        >
                          Current
                          <br />
                          Version
                        </Table.HeaderCell>
                        <Table.HeaderCell width="2" rowspan="2">
                          Program Id
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign="center"
                          colSpan="2"
                          className="review-status"
                          width="2"
                        >
                          Ratings
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          width="2"
                          rowSpan="2"
                          textAlign="center"
                        >
                          # Reviews
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          width="2"
                          rowSpan="2"
                          textAlign="center"
                        >
                          Published
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell width="6" rowSpan="2">
                          Last used
                        </Table.HeaderCell> */}
                        <Table.HeaderCell
                          width="3"
                          rowSpan="2"
                          className="sticky-column last-column"
                          textAlign="center"
                        >
                          Actions
                        </Table.HeaderCell>
                      </Table.Row>
                      <Table.Row>
                        <Table.HeaderCell width="2" textAlign="center">
                          Consumer
                        </Table.HeaderCell>
                        <Table.HeaderCell width="2" textAlign="center">
                          Internal
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {programs && programs.data && programs.data.length > 0 ? (
                        programs.data.map((program, i) => (
                          <Table.Row>
                            <Table.Cell className="sticky-column first-column plr-half">
                              <Dropdown
                                simple
                                selection
                                options={flagOptions}
                                className="flag-select"
                                value={get(program, 'flag')}
                                placeholder={
                                  <div>
                                    <Image src={whiteFlag} />
                                  </div>
                                }
                                onChange={(e, { value }) =>
                                  this.handleFlagChange(program, i, value)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell className="second-column sticky-column no-white-space">
                              <Link to={`/programs/${program._id}/`}>
                                {program.title}
                              </Link>
                            </Table.Cell>
                            <Table.Cell className="no-white-space">
                              {program.internalTitle}
                            </Table.Cell>
                            <Table.Cell>{program.category.name}</Table.Cell>
                            <Table.Cell>
                              {program.cavities[0].name}
                              {program.cavities.length > 1 ? (
                                <Popup
                                  inverted
                                  content={
                                    <List bulleted>
                                      {program.cavities.map((cavity, index) =>
                                        index > 0 ? (
                                          <List.Item>{cavity.name}</List.Item>
                                        ) : null
                                      )}
                                    </List>
                                  }
                                  trigger={
                                    <Button
                                      className="ml-10"
                                      size="mini"
                                      content={`+${
                                        program.cavities.length - 1
                                      }`}
                                    />
                                  }
                                />
                              ) : (
                                ''
                              )}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              V{program.version}
                            </Table.Cell>
                            <Table.Cell>
                              {get(program, 'sequence') || '-'}
                            </Table.Cell>
                            <Table.Cell>
                              <div className="d-flex align-center justify-center">
                                {get(program, 'userFeedback') !== undefined &&
                                (get(program, 'userFeedback.satisfied') !== 0 ||
                                  get(program, 'userFeedback.dissatisfied') !==
                                    0) ? (
                                  <Image
                                    src={
                                      get(program, 'userFeedback.satisfied') >=
                                      get(program, 'userFeedback.dissatisfied')
                                        ? satisfied
                                        : notSatisfied
                                    }
                                    className="mr-10"
                                  />
                                ) : (
                                  '-'
                                )}
                              </div>
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {get(program, 'internalFeedback.rating') ? (
                                <>
                                  <Label
                                    circular
                                    className={
                                      get(program, 'internalFeedback.rating') >=
                                      3
                                        ? 'rating-label light-success'
                                        : 'rating-label light-failed'
                                    }
                                  >
                                    {get(program, 'internalFeedback.rating')}
                                  </Label>{' '}
                                  / 5
                                </>
                              ) : (
                                '-'
                              )}
                              {/* To update label colors conditionally use below classes
                            success => light-success
                            failed => light-failed
                            warninng => light-warning
                          */}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {Number(
                                get(program, 'userFeedback.satisfied') || 0
                              ) +
                                Number(
                                  get(program, 'userFeedback.dissatisfied') || 0
                                )}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              <Checkbox
                                checked={
                                  program.published !== undefined &&
                                  get(program, 'published')
                                }
                                slider
                                fitted
                                onChange={() =>
                                  this.handlePublishChange(program, i)
                                }
                              />
                            </Table.Cell>
                            {/* <Table.Cell>28th Oct 2020 | 13:12</Table.Cell> */}
                            <Table.Cell
                              textAlign="center"
                              className="sticky-column last-column"
                            >
                              <Image.Group>
                                <Popup
                                  inverted
                                  position="top center"
                                  content="Edit Program"
                                  trigger={
                                    <Image
                                      className="pointer"
                                      src={logsIcon}
                                      as={Link}
                                      to={`/programs/${program._id}/edit`}
                                    />
                                  }
                                />
                                <Popup
                                  inverted
                                  position="top center"
                                  content="View Program"
                                  trigger={
                                    <Image
                                      className="pointer"
                                      src={programBlackIcon}
                                      // href= {`/programs/${program._id}/`}
                                      as={Link}
                                      to={`/programs/${program._id}/`}
                                    />
                                  }
                                />
                              </Image.Group>
                            </Table.Cell>
                          </Table.Row>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Cell className="sticky-column first-column visibility-hidden">
                            Hidden
                          </Table.Cell>
                          <Table.Cell colSpan="12">
                            <div className="no-data">No Programs available</div>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </div>
              </Grid.Row>
              <Grid.Row className="table-pagination">
                <div className="d-flex align-center">
                  <div className="d-flex align-center">
                    <span className="mr-20">Items per page</span>
                    <Dropdown
                      options={option}
                      selection
                      compact
                      className="mr-20 mini"
                      value={recordCount}
                      onChange={(e, value) => {
                        this.paginationDropdown(e, value);
                      }}
                    />
                    {programs && programs.meta ? (
                      <span className="mr-20">
                        {recordStartNumber}-
                        {recordEndNumber > programs.meta.totalRecords
                          ? programs.meta.totalRecords
                          : recordEndNumber}{' '}
                        of {programs.meta.totalRecords}
                      </span>
                    ) : null}
                  </div>
                  {this.renderPagination(programs)}
                </div>
              </Grid.Row>
            </Grid>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    programs: state.programs.programs,
    categories: state.categories.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPrograms: (filters) => dispatch(fetchProgramsRequested(filters)),
    postFormData: (data) => dispatch(userFeedbackUpdate(data)),
    fetchCategory: () => dispatch(fetchCategoryRequested()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramList);
