import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  Image,
  Input,
  Radio,
  Item,
  Label,
  Checkbox,
  Grid,
} from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dropzone from 'react-dropzone';
import trash from '../../../assets/images/trash.svg';
import upload from '../../../assets/images/upload-file.svg';
import move from '../../../assets/images/move.svg';
import filesApi from '../../../api/filesApi';
import { PROGRAMFORM_REQUESTED } from '../../../constants/actions';
import { options } from '../../../utils/sharedFunctions';

const screenWidth = document.documentElement.clientWidth;
const isLargeWideMonitor = screenWidth >= 1200 && screenWidth <= 1440;

const quantityOptions = [
  {
    key: 0,
    text: '0',
    value: 0,
  },
  {
    key: 1,
    text: '1',
    value: 1,
  },
  {
    key: 2,
    text: '2',
    value: 2,
  },
  {
    key: 3,
    text: '3',
    value: 3,
  },
  {
    key: 4,
    text: '4',
    value: 4,
  },
  {
    key: 5,
    text: '5',
    value: 5,
  },
  {
    key: 6,
    text: '6',
    value: 6,
  },
];
const timeOptions = [
  {
    key: 'timeBased',
    text: 'Time Based',
    value: 'timeBased',
  },
  {
    key: 'probeBased',
    text: 'Probe Based',
    value: 'probeBased',
  },
];

function OvenOperationsForm(props) {
  const {
    commonModes,
    units,
    isValid,
    isTypeError,
    isTypeErrorForProbeTime,
  } = props;

  if (units && units.temperature === 'fahrenheit') {
    units.temperature = '\u00b0F';
  } else if (units && units.temperature === 'celsius') {
    units.temperature = '\u00b0C';
  }

  const [selectedRadio, setselectedRadio] = useState('probeBased');
  const [selectedbaseRadio, setselectedbaseRadio] = useState('weight');
  const [nextStepAlert, setnextStepAlert] = useState([{}]);
  const [preheatClose, setPreheatClose] = useState(true);
  const [preheatAlert, setPreheatAlert] = useState({
    alert: true,
    value: 'Preheat completed and place the meat in the middle position rack',
  });
  const [selectedbaseRadioValue, setselectedbaseRadioValue] = useState();

  const [selectedQuantity, setselectedQuantity] = useState();
  const [extraThickness, setextraThickness] = useState();
  const [extraQuantity, setextraQuantity] = useState();
  const [cookingMode, setCookingMode] = useState([{}]);
  const [selectedOutcome, setselectedOutcome] = useState([
    { selectedOutcome: '', id: '' },
  ]);
  const [displayOutcome] = useState(options);
  const [steps, setSteps] = useState([{ steps: '', id: '' }]);
  const [outcomeKeys, setOutcomeKeys] = useState([]);
  const [inputValue, setInputValue] = useState([{}]);
  const [scrollClass, setScrollClass] = useState([{}]);
  const [count, setCount] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: PROGRAMFORM_REQUESTED,
      payload: { preheat: preheatAlert },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutcomeChange = (e, index) => {
    const { key } = e.options.find((o) => o.value === e.value);
    const optionkey = [...outcomeKeys];
    optionkey[index] = key;
    setOutcomeKeys(optionkey);
    const { name, value } = e;
    const list = [...selectedOutcome];
    list[index][name] = value;
    list[index].id = key;
    setselectedOutcome(list);
    if (!inputValue[index]) inputValue.push({ outcome: '' });
    inputValue[index].outcome = e.value;
    setInputValue(inputValue);
    dispatch({ type: PROGRAMFORM_REQUESTED, payload: { inputValue } });
  };

  const handleRemoveClick = (index) => {
    const list = [...selectedOutcome];
    const keyList = [...outcomeKeys];
    list.splice(index, 1);
    keyList.splice(index, 1);
    setselectedOutcome(list);
    setOutcomeKeys(keyList);
    const inputList = [...inputValue];
    inputList.splice(index, 1);
    setInputValue(inputList);
    dispatch({ type: PROGRAMFORM_REQUESTED, payload: { inputValue } });
    setCount(Number(count) - 1);
    isTypeErrorForProbeTime.splice(index, 1);
    dispatch({
      type: PROGRAMFORM_REQUESTED,
      payload: { columnCount: count - 1 },
    });
  };

  const checkColumnCount = (index) => {
    if (index >= 3) {
      setScrollClass('add-scroll');
    }
  };

  const handleAddClick = () => {
    const list = [...inputValue];
    list.push({});
    setInputValue(list);
    setCount(Number(count) + 1);
    checkColumnCount(count);
    setselectedOutcome([...selectedOutcome, { selectedOutcome: '', id: '' }]);
    dispatch({
      type: PROGRAMFORM_REQUESTED,
      payload: { columnCount: count + 1 },
    });
  };

  const handleRemoveStep = (j) => {
    const temp = [...steps];
    const list = [...inputValue];
    const modelist = [...cookingMode];
    temp.splice(j, 1);
    modelist.splice(j, 1);
    list.map((e) => {
      if (e.precedingCookingStep) return e.precedingCookingStep.splice(j, 1);
      return e;
    });
    setSteps(temp);
    setInputValue(list);
    setCookingMode(modelist);
    dispatch({
      type: PROGRAMFORM_REQUESTED,
      payload: {
        cookingMode: modelist,
        inputValue: list,
        stepsCount: steps.length - 1,
      },
    });
  };

  const handleAddStep = () => {
    setSteps([...steps, { steps: '', id: '' }]);
    dispatch({
      type: PROGRAMFORM_REQUESTED,
      payload: { stepsCount: steps.length + 1 },
    });
  };

  const reorderSteps = (list, cookingmodeList, startIndex, endIndex) => {
    const modelist = [...cookingmodeList];
    const shift = modelist.splice(startIndex, 1);
    modelist.splice(endIndex, 0, shift[0]);
    const result = Array.from(list);
    result.map((item) => {
      if (item.precedingCookingStep) {
        const [removed] = item.precedingCookingStep.splice(startIndex, 1);
        item.precedingCookingStep.splice(endIndex, 0, removed);
      }
      return item;
    });
    return { result, modelist };
  };

  const onStepsDragged = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorderSteps(
      inputValue,
      cookingMode,
      result.source.index,
      result.destination.index
    );
    setInputValue(items.result);
    setCookingMode(items.modelist);
    dispatch({
      type: PROGRAMFORM_REQUESTED,
      payload: { cookingMode: items.modelist, inputValue: items.result },
    });
  };

  const getViewStyle = (dragProps) => {
    const style = {
      position: 'static',
      padding: 0,
      margin: 0,
      top: 'auto',
      left: 'auto',
    };
    return { ...dragProps.style, ...style };
  };

  const calculateFinalTime = (i) => {
    let time;
    if (selectedRadio === 'probeBased') {
      if (
        inputValue &&
        inputValue[i] &&
        inputValue[i].probeBased &&
        inputValue[i].probeBased.time
      )
        time = `${inputValue[i].probeBased.time}h`;
      if (
        inputValue &&
        inputValue[i] &&
        inputValue[i].probeBased &&
        inputValue[i].probeBased.timeinMin
      )
        time = `${inputValue[i].probeBased.timeinMin}m`;
      if (
        inputValue &&
        inputValue[i] &&
        inputValue[i].probeBased &&
        inputValue[i].probeBased.time &&
        inputValue[i].probeBased.timeinMin
      )
        time = `${inputValue[i].probeBased.time}h ${inputValue[i].probeBased.timeinMin}m`;
    } else if (inputValue[i] && inputValue[i].precedingCookingStep) {
      const totalTime =
        inputValue &&
        inputValue[i] &&
        inputValue[i].precedingCookingStep &&
        inputValue[i].precedingCookingStep.reduce((a, b) => {
          let sum = a;
          if (b.cookingTime) sum += Number(b.cookingTime * 60);
          if (b.cookingTimeinMin) sum += Number(b.cookingTimeinMin);
          return sum;
        }, 0);
      const num = totalTime;
      const hours = num / 60;
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);
      return `${rhours}h ${rminutes}m`;
    }
    return time;
  };

  const removeImage = (j) => {
    const list = [...inputValue];
    delete list[j].image;
    setInputValue(list);
    dispatch({ type: PROGRAMFORM_REQUESTED, payload: { inputValue: list } });
  };

  const uploadFile = async (file, index) => {
    const queryParam = {
      type: 'general',
    };
    const formData = new FormData();
    formData.append('file', file);
    const filesResponse = await filesApi.postFiles(queryParam, formData);
    if (filesResponse.error) {
      return;
    }
    const filesData = {};
    if (
      filesResponse &&
      filesResponse.response &&
      filesResponse.response.data
    ) {
      filesData.id = filesResponse.response.data._id;
      filesData.value = filesResponse.response.data.url;
    }
    const list = [...inputValue];
    if (!list[index]) inputValue.push({ image: '' });
    list[index].image = filesData;
    setInputValue(inputValue);
    dispatch({ type: PROGRAMFORM_REQUESTED, payload: { inputValue } });
  };
  const getUploadFiles = async (acceptedFiles, index) => {
    acceptedFiles.map(async (file) => {
      uploadFile(file, index);
    });
  };

  return (
    <div>
      <Header as="h5" className="d-flex align-center">
        Base Parameters
        <Header.Subheader className="font-regular ml-10">
          (Below oven operations values are based on these parameters)
        </Header.Subheader>
      </Header>
      <div className="pink-bg base-para-form">
        <Form.Group inline className="m-0">
          <Form.Field
            className="d-flex align-center"
            error={
              (!selectedbaseRadioValue && !isValid) ||
              (selectedbaseRadioValue &&
                isTypeError.selectedbaseRadioValue &&
                selectedbaseRadio === 'weight')
                ? 'error'
                : ''
            }
            width={3}
          >
            <Radio
              label="Weight"
              name="baseParameters"
              value="weight"
              checked={selectedbaseRadio === 'weight'}
              onChange={(e, { value }) => {
                setselectedbaseRadio(value);
                dispatch({
                  type: PROGRAMFORM_REQUESTED,
                  payload: { selectedbaseRadio: value },
                });
              }}
            />
            <Input
              selection
              compact
              name="selectedWeight"
              value={
                selectedbaseRadio === 'weight' ? selectedbaseRadioValue : ''
              }
              onChange={(e) => {
                setselectedbaseRadio('weight');
                setselectedbaseRadioValue(e.target.value);
                dispatch({
                  type: PROGRAMFORM_REQUESTED,
                  payload: {
                    selectedbaseRadioValue: e.target.value,
                    selectedbaseRadio: 'weight',
                  },
                });
              }}
            />
            <span>{units ? units.weight : 'lbs'}</span>
          </Form.Field>
          <Form.Field
            className="d-flex align-center"
            error={
              (!selectedbaseRadioValue && !isValid) ||
              (selectedbaseRadioValue &&
                isTypeError.selectedbaseRadioValue &&
                selectedbaseRadio === 'thickness')
                ? 'error'
                : ''
            }
            width={3}
          >
            <Radio
              label="Thickness"
              name="baseParameters"
              value="thickness"
              checked={selectedbaseRadio === 'thickness'}
              onChange={(e, { value }) => {
                setselectedbaseRadio(value);
                dispatch({
                  type: PROGRAMFORM_REQUESTED,
                  payload: { selectedbaseRadio: value },
                });
              }}
            />
            <Input
              compact
              // type="number"
              value={
                selectedbaseRadio === 'thickness' ? selectedbaseRadioValue : ''
              }
              onChange={(e) => {
                setselectedbaseRadio('thickness');
                setselectedbaseRadioValue(e.target.value);
                dispatch({
                  type: PROGRAMFORM_REQUESTED,
                  payload: {
                    selectedbaseRadioValue: e.target.value,
                    selectedbaseRadio: 'thickness',
                  },
                });
              }}
            />
            <span>{units ? units.thickness : 'in'}</span>
          </Form.Field>
          <Form.Field
            className="d-flex align-center quantity-field"
            error={selectedQuantity === undefined && !isValid ? 'error' : ''}
          >
            <label>Quantity</label>
            <Dropdown
              options={quantityOptions}
              selection
              compact
              name="selectedQuantity"
              value={selectedQuantity}
              onChange={(e, quantityValue) => {
                setselectedQuantity(quantityValue.value);
                dispatch({
                  type: PROGRAMFORM_REQUESTED,
                  payload: { selectedQuantity: quantityValue.value },
                });
              }}
            />
          </Form.Field>
        </Form.Group>
      </div>
      <Divider hidden />
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width="3">
            <Form.Field>
              <Checkbox
                label="Preheat alert"
                checked={preheatAlert.alert}
                onChange={() => {
                  const s = { ...preheatAlert };
                  s.alert = !preheatAlert.alert;
                  setPreheatAlert(s);
                  dispatch({
                    type: PROGRAMFORM_REQUESTED,
                    payload: { preheat: s },
                  });
                }}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="13">
            <Form.Field error={!preheatAlert.value && !isValid ? 'error' : ''}>
              <Input
                name=""
                label={{
                  basic: true,
                  content: (
                    <Button
                      className="pointer link-button m-0"
                      disabled={preheatClose}
                      onClick={() => {
                        const s = { ...preheatAlert };
                        s.value = '';
                        setPreheatAlert(s);
                        dispatch({
                          type: PROGRAMFORM_REQUESTED,
                          payload: { preheat: s },
                        });
                      }}
                    >
                      &#10005;
                    </Button>
                  ),
                }}
                labelPosition="right"
                className="large clearable"
                disabled={!preheatAlert.alert}
                value={preheatAlert.value}
                onChange={(e) => {
                  setPreheatClose(false);
                  const s = { ...preheatAlert };
                  s.value = e.target.value;
                  setPreheatAlert(s);
                  dispatch({
                    type: PROGRAMFORM_REQUESTED,
                    payload: { preheat: s },
                  });
                }}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Header as="h5" className="d-flex align-center">
        Oven Operations
      </Header>
      <div className="pink-bg oven-operation-form p-0">
        <div className={` oven-operation-wrapper ${scrollClass}`}>
          <div className="row bg-white align-end full-width">
            <div className="label-column text-right p-0" />
            {selectedOutcome.map((x, i) => {
              return (
                <>
                  <div className="input-column bg-white p-0 d-flex justify-end">
                    {selectedOutcome.length !== 1 ? (
                      <div className="delete-icon pointer">
                        <Image
                          src={trash}
                          onClick={() => {
                            handleRemoveClick(i, x);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="delete-icon empty" />
                    )}
                  </div>
                </>
              );
            })}
          </div>
          <div className="row">
            <div className="label-column text-right">
              <label className="heading-lable">Outcome</label>
            </div>
            {selectedOutcome.map((x, i) => {
              return (
                <div className="input-column position-relative">
                  <Form.Field
                    className="mb-0"
                    width="16"
                    error={!x.selectedOutcome && !isValid ? 'error' : ''}
                  >
                    <Dropdown
                      placeholder="Select"
                      fluid
                      selection
                      name="selectedOutcome"
                      value={x.selectedOutcome}
                      options={displayOutcome
                        .map((e) => {
                          if (
                            outcomeKeys
                              .filter((ip, ind) => ind !== i)
                              .indexOf(e.key) === -1
                          ) {
                            return {
                              key: e.key,
                              text: e.text,
                              value: e.value,
                              order: e.order,
                            };
                          }
                          return 'false';
                        })
                        .filter((oc) => oc !== 'false')}
                      onChange={(e, outcomeValue) =>
                        handleOutcomeChange(outcomeValue, i)
                      }
                    />
                  </Form.Field>
                </div>
              );
            })}
            <div className="button-wrap">
              <Button
                icon="add"
                disabled={count >= displayOutcome.length}
                onClick={handleAddClick}
              />
            </div>
          </div>
          <div className="row">
            <Divider fitted className="ml-15" />
          </div>
          <div className="row">
            <div className="label-column">
              <Dropdown
                selection
                value={selectedRadio}
                options={timeOptions}
                onChange={(e, { value }) => {
                  setselectedRadio(value);
                  dispatch({
                    type: PROGRAMFORM_REQUESTED,
                    payload: { type: value },
                  });
                }}
                className="time-options"
              />
            </div>
            <div className="input-column empty" />
          </div>
          <div className="row">
            <Divider
              fitted
              className="ml-15"
              style={{ paddingBottom: '15px' }}
            />
          </div>
          <div className="row white-bg">
            <div className="label-column">
              {/* <Form.Field> */}
              <label>Forecast Time</label>
              {/* </Form.Field> */}
            </div>
            {selectedOutcome.map((x, i) => {
              return (
                <div className="input-column d-flex align-end">
                  <Form.Group className="mb-0">
                    <Form.Field
                      width="7"
                      className="pl-0"
                      error={
                        (inputValue &&
                          inputValue[i] &&
                          (inputValue[i].probeBased === undefined ||
                            (inputValue[i].probeBased.time === '' &&
                              inputValue[i].probeBased.timeinMin === '') ||
                            (inputValue[i].probeBased.time === undefined &&
                              inputValue[i].probeBased.timeinMin === '') ||
                            (inputValue[i].probeBased.time === '' &&
                              inputValue[i].probeBased.timeinMin ===
                                undefined)) &&
                          !isValid) ||
                        (inputValue &&
                          inputValue[i] &&
                          inputValue[i].probeBased &&
                          isTypeErrorForProbeTime.length ===
                            inputValue.length &&
                          (inputValue[i].probeBased.time ||
                            inputValue[i].probeBased.timeinMin) &&
                          isTypeErrorForProbeTime[i] === true)
                          ? 'error'
                          : ''
                      }
                    >
                      <Input
                        // type="number"
                        name="forecastTimeHr"
                        value={
                          inputValue &&
                          inputValue[i] &&
                          inputValue[i].probeBased &&
                          inputValue[i].probeBased.time
                        }
                        label={{ basic: true, content: 'hr' }}
                        labelPosition="right"
                        disabled={selectedRadio === 'timeBased'}
                        onChange={(e) => {
                          const list = [...inputValue];
                          if (!list[i]) list.push({ probeBased: {} });
                          if (!list[i].probeBased) list[i].probeBased = {};
                          list[i].probeBased.time = e.target.value;
                          setInputValue(list);
                          dispatch({
                            type: PROGRAMFORM_REQUESTED,
                            payload: { inputValue: list, type: 'probeBased' },
                          });
                        }}
                      />
                    </Form.Field>
                    <Form.Field
                      width="9"
                      className="pr-0"
                      error={
                        (inputValue &&
                          inputValue[i] &&
                          (inputValue[i].probeBased === undefined ||
                            (inputValue[i].probeBased.time === '' &&
                              inputValue[i].probeBased.timeinMin === '') ||
                            (inputValue[i].probeBased.time === undefined &&
                              inputValue[i].probeBased.timeinMin === '') ||
                            (inputValue[i].probeBased.time === '' &&
                              inputValue[i].probeBased.timeinMin ===
                                undefined)) &&
                          !isValid) ||
                        (inputValue &&
                          inputValue[i] &&
                          inputValue[i].probeBased &&
                          isTypeErrorForProbeTime.length ===
                            inputValue.length &&
                          (inputValue[i].probeBased.time ||
                            inputValue[i].probeBased.timeinMin) &&
                          isTypeErrorForProbeTime[i] === true)
                          ? 'error'
                          : ''
                      }
                    >
                      <Input
                        // type="number"
                        name="forecastTimeMin"
                        // placeholder="min"
                        label={{ basic: true, content: 'min' }}
                        labelPosition="right"
                        disabled={selectedRadio === 'timeBased'}
                        value={
                          inputValue &&
                          inputValue[i] &&
                          inputValue[i].probeBased &&
                          inputValue[i].probeBased.timeinMin
                        }
                        onChange={(e) => {
                          const list = [...inputValue];
                          if (!list[i]) list.push({ probeBased: {} });
                          if (!list[i].probeBased) list[i].probeBased = {};
                          list[i].probeBased.timeinMin = e.target.value;
                          setInputValue(list);
                          dispatch({
                            type: PROGRAMFORM_REQUESTED,
                            payload: { inputValue: list, type: 'probeBased' },
                          });
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              );
            })}
          </div>
          {/* </div> */}
          <div className="row" style={{ paddingTop: '15px' }}>
            <Divider fitted className="ml-15" />
          </div>
          <div className="row">
            <div className="label-column">
              <label className="heading-lable">Cooking step(s)</label>
            </div>
            {selectedOutcome.map(() => {
              return <div className="input-column empty large" />;
            })}
          </div>
          <DragDropContext onDragEnd={onStepsDragged}>
            <Droppable droppableId="droppable123">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {steps.map((step, j) => {
                    return (
                      <Draggable
                        key={`drag-key-${j * 101}`}
                        draggableId={`drag-key-${j * 101}`}
                        index={j}
                      >
                        {(provide) => (
                          <div
                            style={getViewStyle(provide.draggableProps)}
                            ref={provide.innerRef}
                            {...provide.draggableProps}
                            {...provide.dragHandleProps}
                          >
                            <div className="row">
                              <div className="label-column ptb-0">
                                <Label className="step-label">
                                  Step {j + 1}
                                </Label>
                              </div>
                              {selectedOutcome.map(() => {
                                return (
                                  <div className="input-column empty small" />
                                );
                              })}
                            </div>
                            <div className="draggable-wrap">
                              <div className="row white-bg flex-column position-relative">
                                <div className="d-flex align-center">
                                  <div className="label-column">
                                    <label>Mode / Program</label>
                                  </div>
                                  {selectedOutcome.map((x, i) => {
                                    return (
                                      <div className="input-column">
                                        <Form.Field
                                          width="16"
                                          error={
                                            !(
                                              cookingMode[j] &&
                                              cookingMode[j].value
                                            ) && !isValid
                                              ? 'error'
                                              : ''
                                          }
                                        >
                                          <Dropdown
                                            placeholder="Select"
                                            fluid
                                            selection
                                            name="precedingMode"
                                            disabled={i !== 0}
                                            options={
                                              selectedRadio === 'timeBased'
                                                ? commonModes &&
                                                  commonModes.map((e) => {
                                                    return {
                                                      key: e.key,
                                                      text: e.name,
                                                      value: e.name,
                                                    };
                                                  })
                                                : commonModes &&
                                                  commonModes
                                                    .map((e) => {
                                                      if (e.meatProbe) {
                                                        return {
                                                          key: e.key,
                                                          text: e.name,
                                                          value: e.name,
                                                        };
                                                      }
                                                      return 'false';
                                                    })
                                                    .filter(
                                                      (mode) => mode !== 'false'
                                                    )
                                            }
                                            value={
                                              cookingMode[j] &&
                                              cookingMode[j].value
                                                ? cookingMode[j].value
                                                : ''
                                            }
                                            onChange={(e, data) => {
                                              const { value } = data;
                                              const { key } = data.options.find(
                                                (o) => o.value === value
                                              );
                                              const list = [...cookingMode];
                                              if (!list[j]) list[j] = {};
                                              list[j].value = value;
                                              list[j].key = key;
                                              setCookingMode(list);
                                              dispatch({
                                                type: PROGRAMFORM_REQUESTED,
                                                payload: { cookingMode: list },
                                              });
                                            }}
                                          />
                                        </Form.Field>
                                      </div>
                                    );
                                  })}
                                </div>
                                <Divider fitted />
                                <div className="d-flex align-center">
                                  <div className="label-column">
                                    <label>Temperature</label>
                                  </div>
                                  {selectedOutcome.map((x, i) => {
                                    return (
                                      <div className="input-column">
                                        <Form.Field
                                          width="16"
                                          error={
                                            inputValue &&
                                            inputValue[i] &&
                                            !(
                                              inputValue[i]
                                                .precedingCookingStep &&
                                              inputValue[i]
                                                .precedingCookingStep[j] &&
                                              inputValue[i]
                                                .precedingCookingStep[j]
                                                .temperature
                                            ) &&
                                            !isValid
                                              ? 'error'
                                              : ''
                                          }
                                        >
                                          <Input
                                            type="number"
                                            min="0"
                                            name="precedingTemperature"
                                            label={{
                                              basic: true,
                                              content: (
                                                <span>
                                                  {units
                                                    ? units.temperature
                                                    : '&#8457;'}
                                                </span>
                                              ),
                                            }}
                                            labelPosition="right"
                                            value={
                                              inputValue &&
                                              inputValue[i] &&
                                              inputValue[i]
                                                .precedingCookingStep &&
                                              inputValue[i]
                                                .precedingCookingStep[j] &&
                                              inputValue[i]
                                                .precedingCookingStep[j]
                                                .temperature
                                                ? inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .temperature
                                                : ''
                                            }
                                            onChange={(e) => {
                                              const list = [...inputValue];
                                              if (!list[i])
                                                list.push({
                                                  precedingCookingStep: [{}],
                                                });
                                              if (!list[i].precedingCookingStep)
                                                list[i].precedingCookingStep = [
                                                  {},
                                                ];
                                              if (
                                                !list[i].precedingCookingStep[j]
                                              )
                                                list[i].precedingCookingStep[
                                                  j
                                                ] = {};
                                              list[i].precedingCookingStep[
                                                j
                                              ].temperature = e.target.value;
                                              setInputValue(list);
                                              dispatch({
                                                type: PROGRAMFORM_REQUESTED,
                                                payload: { inputValue: list },
                                              });
                                            }}
                                          />
                                        </Form.Field>
                                      </div>
                                    );
                                  })}
                                </div>
                                <Divider fitted />
                                {selectedRadio === 'probeBased' ? (
                                  <div className="d-flex align-center">
                                    <div className="label-column">
                                      <label>Internal Temperature</label>
                                    </div>
                                    {selectedOutcome.map((x, i) => {
                                      return (
                                        <div className="input-column">
                                          <Form.Field
                                            width="16"
                                            error={
                                              inputValue &&
                                              inputValue[i] &&
                                              !(
                                                inputValue[i]
                                                  .precedingCookingStep &&
                                                inputValue[i]
                                                  .precedingCookingStep[j] &&
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .internalTemperature
                                              ) &&
                                              !isValid
                                                ? 'error'
                                                : ''
                                            }
                                          >
                                            <Input
                                              type="number"
                                              min="0"
                                              name="internalTemperature"
                                              label={{
                                                basic: true,
                                                content: (
                                                  <span>
                                                    {units
                                                      ? units.temperature
                                                      : '&#8457;'}
                                                  </span>
                                                ),
                                              }}
                                              labelPosition="right"
                                              value={
                                                inputValue &&
                                                inputValue[i] &&
                                                inputValue[i]
                                                  .precedingCookingStep &&
                                                inputValue[i]
                                                  .precedingCookingStep[j] &&
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .internalTemperature
                                                  ? inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .internalTemperature
                                                  : ''
                                              }
                                              onChange={(e) => {
                                                const list = [...inputValue];
                                                if (!list[i])
                                                  list.push({
                                                    precedingCookingStep: [{}],
                                                  });
                                                if (
                                                  !list[i].precedingCookingStep
                                                )
                                                  list[
                                                    i
                                                  ].precedingCookingStep = [{}];
                                                if (
                                                  !list[i].precedingCookingStep[
                                                    j
                                                  ]
                                                )
                                                  list[i].precedingCookingStep[
                                                    j
                                                  ] = {};
                                                list[i].precedingCookingStep[
                                                  j
                                                ].internalTemperature =
                                                  e.target.value;
                                                delete list[i]
                                                  .precedingCookingStep[j]
                                                  .cookingTime;
                                                setInputValue(list);
                                                dispatch({
                                                  type: PROGRAMFORM_REQUESTED,
                                                  payload: { inputValue: list },
                                                });
                                              }}
                                            />
                                          </Form.Field>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    <div className="label-column">
                                      <label>Cooking Time</label>
                                    </div>
                                    {selectedOutcome.map((x, i) => {
                                      return (
                                        <div className="input-column">
                                          <Form.Group className="mb-0">
                                            <Form.Field
                                              width="7"
                                              className="pl-0"
                                              error={
                                                (inputValue &&
                                                  inputValue[i] &&
                                                  (inputValue[i]
                                                    .precedingCookingStep ===
                                                    undefined ||
                                                    inputValue[i]
                                                      .precedingCookingStep[
                                                      j
                                                    ] === undefined ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTime ===
                                                      undefined &&
                                                      inputValue[i]
                                                        .precedingCookingStep[j]
                                                        .cookingTimeinMin ===
                                                        '') ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTime === '' &&
                                                      inputValue[i]
                                                        .precedingCookingStep[j]
                                                        .cookingTimeinMin ===
                                                        undefined) ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTime === '' &&
                                                      inputValue[i]
                                                        .precedingCookingStep[j]
                                                        .cookingTimeinMin ===
                                                        '') ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTime ===
                                                      undefined &&
                                                      inputValue[i]
                                                        .precedingCookingStep[j]
                                                        .cookingTimeinMin ===
                                                        undefined)) &&
                                                  !isValid) ||
                                                (inputValue &&
                                                  inputValue[i] &&
                                                  inputValue[i]
                                                    .precedingCookingStep &&
                                                  inputValue[i]
                                                    .precedingCookingStep[j] &&
                                                  ((inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .cookingTime &&
                                                    !inputValue[
                                                      i
                                                    ].precedingCookingStep[
                                                      j
                                                    ].cookingTime.match(
                                                      /^[1-9][0-9]*$/
                                                    )) ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTimeinMin &&
                                                      !inputValue[
                                                        i
                                                      ].precedingCookingStep[
                                                        j
                                                      ].cookingTimeinMin.match(
                                                        /^[1-9][0-9]*$/
                                                      ))) &&
                                                  !isValid)
                                                  ? 'error'
                                                  : ''
                                              }
                                            >
                                              <Input
                                                // type="number"
                                                name="precedingTimeHr"
                                                label={{
                                                  basic: true,
                                                  content: 'hr',
                                                }}
                                                labelPosition="right"
                                                value={
                                                  inputValue &&
                                                  inputValue[i] &&
                                                  inputValue[i]
                                                    .precedingCookingStep &&
                                                  inputValue[i]
                                                    .precedingCookingStep[j] &&
                                                  inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .cookingTime
                                                }
                                                onChange={(e) => {
                                                  const list = [...inputValue];
                                                  if (!list[i])
                                                    list.push({
                                                      precedingCookingStep: [
                                                        {},
                                                      ],
                                                    });
                                                  if (
                                                    !list[i]
                                                      .precedingCookingStep
                                                  )
                                                    list[
                                                      i
                                                    ].precedingCookingStep = [
                                                      {},
                                                    ];
                                                  if (
                                                    !list[i]
                                                      .precedingCookingStep[j]
                                                  )
                                                    list[
                                                      i
                                                    ].precedingCookingStep[
                                                      j
                                                    ] = {};
                                                  list[i].precedingCookingStep[
                                                    j
                                                  ].cookingTime =
                                                    e.target.value;
                                                  delete list[i]
                                                    .precedingCookingStep[j]
                                                    .internalTemperature;
                                                  setInputValue(list);
                                                  dispatch({
                                                    type: PROGRAMFORM_REQUESTED,
                                                    payload: {
                                                      inputValue: list,
                                                    },
                                                  });
                                                }}
                                              />
                                            </Form.Field>
                                            <Form.Field
                                              width="9"
                                              className="pr-0"
                                              error={
                                                (inputValue &&
                                                  inputValue[i] &&
                                                  (inputValue[i]
                                                    .precedingCookingStep ===
                                                    undefined ||
                                                    inputValue[i]
                                                      .precedingCookingStep[
                                                      j
                                                    ] === undefined ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTime ===
                                                      undefined &&
                                                      inputValue[i]
                                                        .precedingCookingStep[j]
                                                        .cookingTimeinMin ===
                                                        '') ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTime === '' &&
                                                      inputValue[i]
                                                        .precedingCookingStep[j]
                                                        .cookingTimeinMin ===
                                                        undefined) ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTime === '' &&
                                                      inputValue[i]
                                                        .precedingCookingStep[j]
                                                        .cookingTimeinMin ===
                                                        '') ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTime ===
                                                      undefined &&
                                                      inputValue[i]
                                                        .precedingCookingStep[j]
                                                        .cookingTimeinMin ===
                                                        undefined)) &&
                                                  !isValid) ||
                                                (inputValue &&
                                                  inputValue[i] &&
                                                  inputValue[i]
                                                    .precedingCookingStep &&
                                                  inputValue[i]
                                                    .precedingCookingStep[j] &&
                                                  ((inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .cookingTime &&
                                                    !inputValue[
                                                      i
                                                    ].precedingCookingStep[
                                                      j
                                                    ].cookingTime.match(
                                                      /^[1-9][0-9]*$/
                                                    )) ||
                                                    (inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .cookingTimeinMin &&
                                                      !inputValue[
                                                        i
                                                      ].precedingCookingStep[
                                                        j
                                                      ].cookingTimeinMin.match(
                                                        /^[1-9][0-9]*$/
                                                      ))) &&
                                                  !isValid)
                                                  ? 'error'
                                                  : ''
                                              }
                                            >
                                              <Input
                                                // type="number"
                                                name="precedingTimeMin"
                                                label={{
                                                  basic: true,
                                                  content: 'min',
                                                }}
                                                labelPosition="right"
                                                value={
                                                  inputValue &&
                                                  inputValue[i] &&
                                                  inputValue[i]
                                                    .precedingCookingStep &&
                                                  inputValue[i]
                                                    .precedingCookingStep[j] &&
                                                  inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .cookingTimeinMin
                                                }
                                                onChange={(e) => {
                                                  const list = [...inputValue];
                                                  if (!list[i])
                                                    list.push({
                                                      precedingCookingStep: [
                                                        {},
                                                      ],
                                                    });
                                                  if (
                                                    !list[i]
                                                      .precedingCookingStep
                                                  )
                                                    list[
                                                      i
                                                    ].precedingCookingStep = [
                                                      {},
                                                    ];
                                                  if (
                                                    !list[i]
                                                      .precedingCookingStep[j]
                                                  )
                                                    list[
                                                      i
                                                    ].precedingCookingStep[
                                                      j
                                                    ] = {};
                                                  list[i].precedingCookingStep[
                                                    j
                                                  ].cookingTimeinMin =
                                                    e.target.value;
                                                  delete list[i]
                                                    .precedingCookingStep[j]
                                                    .internalTemperature;
                                                  setInputValue(list);
                                                  dispatch({
                                                    type: PROGRAMFORM_REQUESTED,
                                                    payload: {
                                                      inputValue: list,
                                                    },
                                                  });
                                                }}
                                              />
                                            </Form.Field>
                                          </Form.Group>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                <Divider fitted />
                                <div className="d-flex align-center full-width">
                                  <div className="label-column">
                                    <label>Next Step Alert</label>
                                  </div>
                                  <div className="input-column alert-column bg-white">
                                    <Form.Group className="mb-0">
                                      <Form.Field width="11" className="pl-0">
                                        <Input
                                          name=""
                                          label={{
                                            basic: true,
                                            content: (
                                              <Button className="pointer link-button m-0">
                                                &#10005;
                                              </Button>
                                            ),
                                          }}
                                          onChange={(e) => {
                                            const list = [...nextStepAlert];
                                            if (!list[j]) list[j] = {};
                                            if (!list[j].alert)
                                              list[j].alert = {};
                                            list[j].alert.text = e.target.value;
                                            setnextStepAlert(list);
                                            dispatch({
                                              type: PROGRAMFORM_REQUESTED,
                                              payload: { alert: list },
                                            });
                                          }}
                                          labelPosition="right"
                                          className="clearable"
                                        />
                                      </Form.Field>
                                      <Form.Field
                                        width="5"
                                        className="left-divider d-flex align-center"
                                      >
                                        <Checkbox
                                          label="Require Acknowledgement"
                                          checked={nextStepAlert.nextStepManual}
                                          onChange={() => {
                                            const list = [...nextStepAlert];
                                            if (!list[j]) list[j] = {};
                                            if (!list[j].alert)
                                              list[j].alert = {};
                                            list[
                                              j
                                            ].alert.nextStepManual = !list[j]
                                              .alert.nextStepManual;
                                            setnextStepAlert(list);
                                            dispatch({
                                              type: PROGRAMFORM_REQUESTED,
                                              payload: { alert: list },
                                            });
                                          }}
                                        />
                                      </Form.Field>
                                    </Form.Group>
                                  </div>
                                </div>

                                {steps.length !== 1 && (
                                  <div className="button-grp">
                                    <Button>
                                      <Image src={move} />
                                    </Button>

                                    <Button onClick={() => handleRemoveStep(j)}>
                                      <Image src={trash} />
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="row mt--half">
            <div className="label-column btn-wrap">
              <Button
                icon
                labelPosition="left"
                secondary
                compact
                onClick={handleAddStep}
              >
                <Icon name="add" />
                Add a step
              </Button>
            </div>
            {selectedOutcome.map(() => {
              return <div className="input-column empty" />;
            })}
          </div>
          <div className="row">
            <Divider className="ml-15 mt-0" />
          </div>
          <div className="row dark-pink-bg">
            <div className="label-column text-right">
              <label className="heading-lable">Final estimated time</label>
            </div>
            {selectedOutcome.map((x, i) => {
              return (
                <div className="input-column">
                  <Header as="h3" className="d-flex justify-center">
                    {calculateFinalTime(i)}
                    {/* <Header.Subheader>mins</Header.Subheader> */}
                  </Header>
                </div>
              );
            })}
          </div>
          <div className="row">
            <Divider className="ml-15 mb-0" />
          </div>
          <div className="row">
            <div className="label-column">
              <label className="heading-lable">
                Cooking time varies by <br />
                <span>(Per extra)</span>
              </label>
            </div>
            {selectedOutcome.map(() => {
              return <div className="input-column empty large" />;
            })}
          </div>
          <div className="row white-bg flex-column">
            <div className="d-flex align-center">
              <div className="label-column">
                <Form.Field
                  className="d-flex"
                  width="16"
                  error={
                    (!extraThickness && !isValid) ||
                    (extraThickness && isTypeError.extraThickness)
                      ? 'error'
                      : ''
                  }
                >
                  <div className="d-flex align-center">
                    {/* <label className="mr-10 mb-0">Per extra</label> */}
                    <Input
                      // type="number"
                      name="extraThickness"
                      value={extraThickness}
                      onChange={(e) => {
                        setextraThickness(e.target.value);
                        dispatch({
                          type: PROGRAMFORM_REQUESTED,
                          payload: { extraThickness: e.target.value },
                        });
                      }}
                    />
                    <span>
                      {selectedbaseRadio === 'weight'
                        ? units && units.weight
                        : units && units.thickness}
                    </span>
                    <label className="mb-0">
                      {selectedbaseRadio === 'thickness'
                        ? 'Thickness'
                        : 'Weight'}
                    </label>
                  </div>
                </Form.Field>
              </div>
              {selectedOutcome.map((x, i) => {
                return (
                  <div
                    className={`${
                      isLargeWideMonitor && 'large'
                    } input-column d-flex align-center`}
                  >
                    <Form.Field
                      className="m-auto"
                      width="10"
                      error={
                        (inputValue &&
                          inputValue[i] &&
                          (inputValue[i].cookingTimeVariation === undefined ||
                            !inputValue[i].cookingTimeVariation
                              .thicknessTime) &&
                          !isValid) ||
                        (inputValue[i].cookingTimeVariation &&
                          inputValue[i].cookingTimeVariation.thicknessTime &&
                          !inputValue[
                            i
                          ].cookingTimeVariation.thicknessTime.match(
                            /^\d+(\.\d{1,2})?$/
                          ) &&
                          !isValid)
                          ? 'error'
                          : ''
                      }
                    >
                      <Input
                        // type="number"
                        label={{ basic: true, content: 'mins' }}
                        labelPosition="right"
                        name="thicknessTime"
                        onChange={(e) => {
                          const list = [...inputValue];
                          if (!list[i]) list.push({ cookingTimeVariation: {} });
                          if (!list[i].cookingTimeVariation)
                            list[i].cookingTimeVariation = {};
                          list[i].cookingTimeVariation.thicknessTime =
                            e.target.value;
                          setInputValue(list);
                          dispatch({
                            type: PROGRAMFORM_REQUESTED,
                            payload: { inputValue: list },
                          });
                        }}
                      />
                    </Form.Field>
                  </div>
                );
              })}
            </div>
            <Divider fitted />
            <div className="d-flex align-center">
              <div className="label-column">
                <Form.Field
                  className={`${
                    isLargeWideMonitor
                      ? 'flex-column justify-center'
                      : 'align-center'
                  } d-flex`}
                  width="16"
                  error={extraQuantity === undefined && !isValid ? 'error' : ''}
                >
                  <div className="d-flex align-center">
                    {/* <label className="mr-10 mb-0">Per extra</label> */}
                    <Dropdown
                      // placeholder="Select"
                      compact
                      selection
                      name="extraQuantity"
                      options={quantityOptions}
                      value={extraQuantity}
                      onChange={(e, quantityValue) => {
                        setextraQuantity(quantityValue.value);
                        dispatch({
                          type: PROGRAMFORM_REQUESTED,
                          payload: { extraQuantity: quantityValue.value },
                        });
                      }}
                    />
                    <span className="visibility-hidden">in</span>
                    <label className="mb-0">Quantity</label>
                  </div>
                </Form.Field>
              </div>
              {selectedOutcome.map((x, i) => {
                return (
                  <div
                    className={`${
                      isLargeWideMonitor && 'large'
                    } input-column d-flex align-center`}
                  >
                    <Form.Field
                      className="m-auto"
                      width="10"
                      error={
                        (inputValue &&
                          inputValue[i] &&
                          (inputValue[i].cookingTimeVariation === undefined ||
                            !inputValue[i].cookingTimeVariation.quantityTime) &&
                          !isValid) ||
                        (inputValue[i].cookingTimeVariation &&
                          inputValue[i].cookingTimeVariation.quantityTime &&
                          !inputValue[
                            i
                          ].cookingTimeVariation.quantityTime.match(
                            /^\d+(\.\d{1,2})?$/
                          ) &&
                          !isValid)
                          ? 'error'
                          : ''
                      }
                    >
                      <Input
                        // type="number"
                        label={{ basic: true, content: 'mins' }}
                        labelPosition="right"
                        name="quantityTime"
                        onChange={(e) => {
                          const list = [...inputValue];
                          if (!list[i]) list.push({ cookingTimeVariation: {} });
                          if (!list[i].cookingTimeVariation)
                            list[i].cookingTimeVariation = {};
                          list[i].cookingTimeVariation.quantityTime =
                            e.target.value;
                          setInputValue(list);
                          dispatch({
                            type: PROGRAMFORM_REQUESTED,
                            payload: { inputValue: list },
                          });
                        }}
                      />
                    </Form.Field>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row">
            <div className="label-column">
              <label className="heading-lable">Upload doneness image</label>
            </div>
            {selectedOutcome.map((x, i) => {
              return (
                <div className="input-column">
                  <Form.Field
                    error={
                      !(inputValue[i] && inputValue[i].image) && !isValid
                        ? 'error'
                        : ''
                    }
                  >
                    <div className="upload-images">
                      <Dropzone
                        accept="image/*"
                        onDrop={(acceptedFiles) => {
                          getUploadFiles(acceptedFiles, i);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="upload">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {inputValue[i] && inputValue[i].image ? (
                                <Item.Image
                                  size="tiny"
                                  src={inputValue[i].image.value}
                                />
                              ) : (
                                <span>
                                  <Image src={upload} />
                                  Click here or
                                  <br />
                                  Drag and drop file here
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </Form.Field>
                  <Image
                    src={trash}
                    className="m-auto"
                    onClick={() => removeImage(i)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default OvenOperationsForm;
