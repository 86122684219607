import {
  REGION_LISTING_FAILURE,
  REGION_LISTING_REQUESTED,
  REGION_LISTING_SUCCESSFUL,
} from '../../constants/actions';

export const fetchregionRequested = (data) => {
  return {
    type: REGION_LISTING_REQUESTED,
    payload: data,
  };
};

export const fetchregionSuccessful = (data) => {
  return {
    type: REGION_LISTING_SUCCESSFUL,
    payload: data,
  };
};

export const fetchregionFailure = (data) => {
  return {
    type: REGION_LISTING_FAILURE,
    payload: data,
  };
};
