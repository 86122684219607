import React from 'react';
import { Divider, Image, Menu, Popup, Sidebar } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/BSH-logo.svg';
import dashIcon from '../../assets/images/icon-dashboard.svg';
import createProgIcon from '../../assets/images/icon-create-program.svg';
import progIcon from '../../assets/images/icon-programs.svg';
import userMgmtIcon from '../../assets/images/icon-user-mgmt.svg';
// import settingIcon from '../../assets/images/settings-icon.svg';
import logoutIcon from '../../assets/images/icon-logout.svg';
import dataMgmtIcon from '../../assets/images/data-management-icon.svg';

const Navbar = (props) => {
  const { name } = props;

  return (
    <>
      <Sidebar as={Menu} vertical visible>
        <Menu.Item className="logo">
          <Image src={logo} />
        </Menu.Item>
        <Menu.Item as={Link} to="/dashboard" active={name === 'dashboard'}>
          <Popup
            inverted
            trigger={<Image src={dashIcon} />}
            content="Dashboard"
            position="right center"
          />
        </Menu.Item>
        <Menu.Item as={Link} to="/programs" active={name === 'programs'}>
          <Popup
            inverted
            trigger={<Image src={progIcon} />}
            content="Programs"
            position="right center"
          />
        </Menu.Item>
        <Divider />
        <Menu.Item
          as={Link}
          to="/programs/create"
          active={name === 'createPrograms'}
        >
          <Popup
            inverted
            trigger={<Image src={createProgIcon} />}
            content="Create Programs"
            position="right center"
          />
        </Menu.Item>
        <Menu.Item as={Link} to="/user-list" active={name === 'userList'}>
          <Popup
            inverted
            trigger={<Image src={userMgmtIcon} />}
            content="User Management"
            position="right center"
          />
        </Menu.Item>
        <Menu.Item as={Link} to="/meta" active={name === 'meta'}>
          <Popup
            inverted
            trigger={<Image src={dataMgmtIcon} />}
            content="Meta"
            position="right center"
          />
        </Menu.Item>
        <div className="bottom-menu">
          <Menu.Item
            as="a"
            onClick={async () => {
              await localStorage.removeItem('status');
              await localStorage.removeItem('accessToken');
              await localStorage.removeItem('role');
              await localStorage.removeItem('_id');
              window.location.reload();
            }}
          >
            <Popup
              inverted
              trigger={<Image src={logoutIcon} />}
              content="Logout"
              position="right center"
            />
          </Menu.Item>
        </div>
      </Sidebar>
    </>
  );
};

export default Navbar;
