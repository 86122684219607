import { combineReducers } from 'redux';
import {
  REGION_LISTING_FAILURE,
  REGION_LISTING_REQUESTED,
  REGION_LISTING_SUCCESSFUL,
} from '../../constants/actions';

const regionReducer = (state = {}, action) => {
  switch (action.type) {
    case REGION_LISTING_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case REGION_LISTING_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case REGION_LISTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  regions: regionReducer,
});
