import { combineReducers } from 'redux';
import {
  CATEGORY_LISTING_REQUESTED,
  CATEGORY_LISTING_FAILURE,
  CATEGORY_LISTING_SUCCESSFUL,
} from '../../constants/actions';

const categoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_LISTING_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case CATEGORY_LISTING_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case CATEGORY_LISTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  categories: categoryReducer,
});
