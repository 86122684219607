import {
  PROGRAM_USER_REQUESTED,
  PROGRAM_USER_SUCCESSFUL,
  PROGRAM_USER_FAILURE,
  SESSION_USERFEEDBACKUPDATE,
} from '../../constants/actions';

export const programUsersRequested = (data) => {
  return {
    type: PROGRAM_USER_REQUESTED,
    payload: data,
  };
};

export const programUsersSuccessful = (data) => {
  return {
    type: PROGRAM_USER_SUCCESSFUL,
    payload: data,
  };
};

export const programUsersFailure = (data) => {
  return {
    type: PROGRAM_USER_FAILURE,
    payload: data,
  };
};

export const sessionUserFeedbackUpdate = (data) => {
  return {
    type: SESSION_USERFEEDBACKUPDATE,
    payload: data,
  };
};
