/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { React, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Menu,
  Tab,
  Table,
  Message,
  Confirm,
} from 'semantic-ui-react';
// import edit from '../../../assets/images/edit.svg';
import trash from '../../../assets/images/trash.svg';
import { fetchBrandsRequested } from '../../../state/actions/brandsActions';
import { fetchModesRequested } from '../../../state/actions/modesActions';
import MultiSelectDropdown from '../../ui/MultiSelectDropdown';
import CustomFunctions from '../../../utils/customFunctions';
import brandsApi from '../../../api/brandsApi';
import modesApi from '../../../api/modesApi';
import LabelWithIcons from '../../ui/LabelWithIcons';
import InputWithIcons from '../../ui/InputWithIcons';
import { getLocalStorageValue } from '../../../utils/sharedFunctions';

const defaultFilters = {
  sortBy: 'title',
  sortOrder: 'desc',
  page: 1,
  pageSize: 10,
};

class BrandCavity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModes: [],
      selectedBrand: '',
      additionalItems: [],
      cavityName: '',
      brandError: '',
      cavityError: '',
      modesError: '',
      modelsError: '',
      models: [],
      modelName: '',
      addedModeName: '',
      brandName: '',
      setIndex: null,
      formError: false,
      isFailed: false,
      isSuccess: false,
      formSuccess: '',
      currentCavIndex: null,
      modalOpenBrand: false,
      modalOpenCavity: false,
      modalOpenModes: false,
      brandId: 0,
      cavityId: 0,
      modKey: '',
    };
    this.fetchData(defaultFilters);
  }

  fetchData = (filters) => {
    this.props.fetchBrands(filters);
    this.props.fetchModes(filters);
  };

  getModesData = () => {
    const { modes } = this.props;
    if (!modes || !modes.data) return [];
    const modesOptions = [];
    modes.data.map((mod) => {
      return modesOptions.push({
        key: mod.key,
        text: mod.name,
        value: mod.key,
        name: mod.name,
        ...mod,
      });
    });
    return modesOptions;
  };

  handleModes = (smodes) => {
    this.setState({ selectedModes: [...smodes], modesError: '' });
  };

  handleChange = (e, { value }) =>
    this.setState({ selectedBrand: value, brandError: '' });

  handleBrandsValue = (e, { value }) => {
    this.setState((prevState) => ({
      additionalItems: [{ text: value, value }, ...prevState.additionalItems],
    }));
  };

  getbrands = () => {
    const { brands } = this.props;
    if (!brands || !brands.data) return [];
    const brandsOptions = [];
    brands.data.map((brand) => {
      return brandsOptions.push({
        key: brand._id,
        text: brand.name,
        value: brand._id,
      });
    });
    return brandsOptions;
  };

  validateForm = () => {
    const { selectedBrand, cavityName, selectedModes, models } = this.state;
    let isValid = true;
    if (CustomFunctions.checkIfEmpty(selectedBrand)) {
      this.setState({ brandError: 'Please enter Brand name' });
      isValid = false;
    }
    if (CustomFunctions.checkIfEmpty(cavityName)) {
      this.setState({ cavityError: 'Please enter Cavity name' });
      isValid = false;
    }
    if (selectedModes.length === 0) {
      this.setState({ modesError: 'Please select modes' });
      isValid = false;
    }
    if (models.length === 0) {
      this.setState({ modelsError: 'Please enter model name' });
      isValid = false;
    }
    return isValid;
  };

  getModelsData = (e, setData = false) => {
    const { models, modelName } = this.state;
    if (
      (setData && modelName.trim() !== '') ||
      (e.key === 'Enter' && modelName.trim() !== '')
    ) {
      models.push(modelName);
      this.setState({ models, modelName: '' });
    }
  };

  getModesAddData = async (e, bid, cid) => {
    const { addedModeName } = this.state;
    if (e.key === 'Enter' && addedModeName.trim() !== '') {
      const objPayload = {
        key: `Cooking.Oven.Program.HeatingMode.${addedModeName.trim()}`,
        name: addedModeName.trim(),
        fastPreHeat: false,
        meatProbe: false,
      };
      const modesResponse = await modesApi.postModes(bid, cid, objPayload);
      if (modesResponse.error) {
        this.setState({
          formError: modesResponse.error.message,
          isFailed: true,
          addedModeName: '',
        });
        setTimeout(() => {
          this.setState({ isFailed: false });
        }, 3000);
        return;
      }
      this.setState({
        isFailed: false,
        isSuccess: true,
        setIndex: null,
        formSuccess: 'Mode added successfully',
        addedModeName: '',
      });
      setTimeout(() => {
        this.setState({ isSuccess: false });
      }, 3000);
      this.fetchData(defaultFilters);
    }
  };

  onRemoveHandler = (index) => {
    const { models } = this.state;
    models.splice(index, 1);
    this.setState({ models });
  };

  onCheckHandler = async (bid) => {
    const { brandName } = this.state;
    const payload = {
      name: brandName,
    };
    const brandsResponse = await brandsApi.updateBrands(bid, payload);
    if (brandsResponse.error) {
      this.setState({
        formError: brandsResponse.error.message,
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
      return;
    }
    this.setState({
      isFailed: false,
      isSuccess: true,
      setIndex: null,
      formSuccess: 'Brand updated successfully',
    });
    setTimeout(() => {
      this.setState({ isSuccess: false });
    }, 3000);
    this.fetchData(defaultFilters);
  };

  onCloseHandler = () => {
    this.setState({ setIndex: null });
  };

  onEditHandler = (index, brandName) => {
    this.setState({ setIndex: index, brandName });
  };

  onModalOpen = (type = 1, bid = null, cid = null, key = null) => {
    switch (type) {
      case 1:
        this.setState({ modalOpenBrand: true, brandId: bid });
        break;
      case 2:
        this.setState({ modalOpenCavity: true, brandId: bid, cavityId: cid });
        break;
      case 3:
        this.setState({
          modalOpenModes: true,
          brandId: bid,
          cavityId: cid,
          modKey: key,
        });
        break;
      default:
        this.setState({ modalOpenBrand: true, brandId: bid });
    }
  };

  onDeleteBrandHandler = async () => {
    const { brandId } = this.state;
    const brandsResponse = await brandsApi.deleteBrands(brandId);
    if (brandsResponse.error) {
      this.setState({
        formError: brandsResponse.error.message,
        isFailed: true,
        modalOpenBrand: false,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
      return;
    }
    this.setState({
      isFailed: false,
      isSuccess: true,
      formSuccess: 'Brand deleted successfully',
      modalOpenBrand: false,
    });
    this.fetchData(defaultFilters);
    setTimeout(() => {
      this.setState({ isSuccess: false });
    }, 3000);
  };

  onCavityDeleteHandler = async () => {
    const { brandId, cavityId } = this.state;
    const brandsResponse = await brandsApi.deleteCavity(brandId, cavityId);
    if (brandsResponse.error) {
      this.setState({
        formError: brandsResponse.error.message,
        isFailed: true,
        modalOpenCavity: false,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
      return;
    }
    this.setState({
      isFailed: false,
      setIndex: null,
      isSuccess: true,
      formSuccess: 'Cavity deleted successfully',
      modalOpenCavity: false,
    });
    this.fetchData(defaultFilters);
    setTimeout(() => {
      this.setState({ isSuccess: false });
    }, 3000);
  };

  onRemoveModesHandler = async () => {
    const { brandId, cavityId, modKey } = this.state;
    const modesResponse = await modesApi.deleteModes(brandId, cavityId, modKey);
    if (modesResponse.error) {
      this.setState({
        formError: modesResponse.error.message,
        isFailed: true,
        modalOpenModes: false,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
      return;
    }
    this.setState({
      isFailed: false,
      setIndex: null,
      isSuccess: true,
      formSuccess: 'Mode deleted successfully',
      modalOpenModes: false,
    });
    this.fetchData(defaultFilters);
    setTimeout(() => {
      this.setState({ isSuccess: false });
    }, 3000);
  };

  handleSubmit = async () => {
    const { brands } = this.props;
    const { selectedBrand, cavityName, selectedModes, models } = this.state;
    if (!this.validateForm()) return;
    const payloadData = {};
    if (cavityName) payloadData.cavity = cavityName;
    const selectedModeOptions = [];
    selectedModes.map((mode) => {
      return selectedModeOptions.push({
        name: mode.name,
        key: mode.key,
        fastPreHeat: mode.fastPreHeat,
        meatProbe: mode.meatProbe,
      });
    });
    if (selectedModes.length) payloadData.modes = selectedModeOptions;
    if (models.length) payloadData.vibs = models;

    const brand = {};
    if (brands && brands.data) {
      const selectedBrandData = brands.data.filter((b) => {
        return b._id === selectedBrand;
      });
      if (selectedBrandData.length) brand.id = selectedBrand;
      else brand.name = selectedBrand;
    }

    payloadData.brand = brand;
    const brandsResponse = await brandsApi.postBrands(payloadData);
    if (brandsResponse.error) {
      this.setState({
        formError: brandsResponse.error.errors[0].detail,
        isFailed: true,
      });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
      return;
    }

    this.setState({
      isFailed: false,
      isSuccess: true,
      formSuccess: 'Data added successfully',
      selectedModes: [],
      cavityName: '',
      models: [],
      selectedBrand: '',
      modelName: '',
    });
    this.fetchData(defaultFilters);
    setTimeout(() => {
      this.setState({ isSuccess: false });
    }, 3000);
  };

  setPanes = (role) => {
    const { setIndex, brandName, addedModeName, currentCavIndex } = this.state;
    const { brands } = this.props;
    const panes =
      brands && brands.data && brands.data.length > 0
        ? brands.data.map((brand, index) => {
            return {
              menuItem: (
                <Menu.Item
                  className={`${
                    setIndex === index && 'edit-input'
                  } d-flex justify-content-between align-center`}
                >
                  {setIndex === index ? (
                    <>
                      <InputWithIcons
                        value={brandName}
                        onInputchange={(e) =>
                          this.setState({ brandName: e.target.value })
                        }
                        onClickCheck={() => this.onCheckHandler(brand._id)}
                        onClickClose={() => this.onCloseHandler()}
                      />
                    </>
                  ) : (
                    <>
                      <div className="input-with-icons">
                        <div className="input-wrap">{brand.name}</div>
                        <div className="icon-wrap">
                          <span
                            className="edit-icon"
                            onClick={() =>
                              this.onEditHandler(index, brand.name)
                            }
                          />
                          <span
                            className="trash-icon"
                            onClick={() => this.onModalOpen(1, brand._id)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Menu.Item>
              ),

              render: () => (
                <Tab.Pane className="no-border no-shadow">
                  {brand.cavities.map((cavity, cavIndex) => (
                    <Table
                      basic="very"
                      className={`${
                        cavIndex === 0 ? 'mt--30' : ''
                      } brands-table`}
                    >
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Cavity</Table.HeaderCell>
                          <Table.HeaderCell>
                            {cavity.name}&quot;
                            <Image
                              src={trash}
                              onClick={() =>
                                this.onModalOpen(2, brand._id, cavity.id)
                              }
                              className="pointer pull-right"
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>Modes</Table.Cell>
                          <Table.Cell>
                            <div>
                              {cavity &&
                                cavity.modes.map((mod) => (
                                  <>
                                    <Label className="delete-label">
                                      {mod.name}
                                      <Icon
                                        name="delete"
                                        onClick={() =>
                                          this.onModalOpen(
                                            3,
                                            brand._id,
                                            cavity.id,
                                            mod.key
                                          )
                                        }
                                      />
                                    </Label>
                                  </>
                                ))}
                              <Input
                                type="text"
                                name={addedModeName}
                                value={
                                  currentCavIndex === cavIndex
                                    ? addedModeName
                                    : ''
                                }
                                placeholder="Add Mode"
                                onChange={(e) => {
                                  this.setState({
                                    addedModeName: e.target.value,
                                    currentCavIndex: cavIndex,
                                  });
                                }}
                                onKeyDown={(e) =>
                                  this.getModesAddData(e, brand._id, cavity.id)
                                }
                                disabled={role !== 'admin'}
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Models</Table.Cell>
                          <Table.Cell>
                            <div>
                              {cavity.vibs.map((vib) => (
                                <Label className="delete-label">
                                  {vib} <Icon name="delete" />
                                </Label>
                              ))}

                              <Input placeholder="Add model" />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  ))}
                </Tab.Pane>
              ),
            };
          })
        : [];
    return panes;
  };

  render() {
    const { brands } = this.props;
    const {
      additionalItems,
      selectedBrand,
      brandError,
      cavityError,
      modesError,
      modelsError,
      selectedModes,
      cavityName,
      models,
      modelName,
      formError,
      isFailed,
      formSuccess,
      isSuccess,
      modalOpenModes,
      modalOpenBrand,
      modalOpenCavity,
    } = this.state;

    const role = getLocalStorageValue('role');

    return (
      <>
        <Grid className="mlr-0">
          <Grid.Column width={4} className="category-left-side p-0">
            <div className="inner-wrapper">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Form.Field>
                  <label htmlFor="brandname">Brand</label>
                  <Dropdown
                    options={[...additionalItems, ...this.getbrands()]}
                    placeholder="Brand name"
                    search
                    selection
                    fluid
                    allowAdditions
                    value={selectedBrand}
                    onAddItem={(e, value) => this.handleBrandsValue(e, value)}
                    onChange={(e, value) => this.handleChange(e, value)}
                    className="large"
                    disabled={role !== 'admin'}
                  />
                  <p className="error-note text-center">{brandError}</p>
                </Form.Field>
                <Form.Field>
                  <label htmlFor="cavity">Cavity</label>
                  <input
                    type="text"
                    name={cavityName}
                    value={cavityName}
                    placeholder="Cavity"
                    onChange={(e) => {
                      this.setState({
                        cavityName: e.target.value,
                        cavityError: '',
                      });
                    }}
                    disabled={role !== 'admin'}
                  />
                  <p className="error-note text-center">{cavityError}</p>
                </Form.Field>
                <Form.Field>
                  <MultiSelectDropdown
                    labelValue="Modes"
                    placeholder="Select Modes"
                    options={this.getModesData()}
                    defaultSelectedOptions={selectedModes}
                    onChange={(select) => this.handleModes(select)}
                    disabled={role !== 'admin'}
                  />
                  <p className="error-note text-center">{modesError}</p>
                </Form.Field>
                <Form.Field>
                  <label htmlFor="models">Models</label>
                  <input
                    type="text"
                    name={models}
                    value={modelName}
                    placeholder="Add Models"
                    onChange={(e) =>
                      this.setState({
                        modelName: e.target.value,
                        modelsError: '',
                      })
                    }
                    onBlur={(e) => this.getModelsData(e, true)}
                    onKeyDown={(e) => this.getModelsData(e)}
                    disabled={role !== 'admin'}
                  />
                  <p className="error-note text-center">{modelsError}</p>
                  {models.map((model, index) => (
                    <LabelWithIcons
                      name="delete"
                      onClick={() => {
                        this.onRemoveHandler(index);
                      }}
                      text={model}
                      classname="mt-15"
                    />
                  ))}
                </Form.Field>
                <div className="button-wrapper d-flex justify-center">
                  <Button
                    icon
                    labelPosition="left"
                    secondary
                    type="button"
                    onClick={() => this.handleSubmit()}
                    disabled={role !== 'admin'}
                  >
                    <Icon name="add" />
                    Add
                  </Button>
                </div>
              </Form>
            </div>
          </Grid.Column>
          <Grid.Column width={12}>
            <div className="select-region-tabs">
              <Grid className="m-0 region-grid">
                <Grid.Column width={4} className="bg-grey">
                  <Header as="h5" className="font-regular">
                    Brand
                  </Header>
                </Grid.Column>
                <Grid.Column width={12} />
              </Grid>
              {brands && brands.data ? (
                <Tab
                  menu={{
                    pointing: true,
                    fluid: true,
                    vertical: true,
                    tabular: true,
                  }}
                  panes={this.setPanes(brands, role)}
                />
              ) : (
                <div className="no-data">No records found</div>
              )}
              {isFailed && (
                <Message error className="text-center">
                  <p>{formError}</p>
                </Message>
              )}
              {isSuccess && (
                <Message success className="text-center">
                  <p className="success-note">{formSuccess}</p>
                </Message>
              )}
            </div>
          </Grid.Column>
        </Grid>
        <Confirm
          open={modalOpenBrand}
          closeOnDimmerClick={false}
          content="Are you sure you want to delete Brand?"
          onCancel={() => this.setState({ modalOpenBrand: false })}
          onConfirm={() => {
            this.onDeleteBrandHandler();
          }}
          className="confirm-modal"
          size="tiny"
        />
        <Confirm
          open={modalOpenCavity}
          closeOnDimmerClick={false}
          content="Are you sure you want to delete Cavity?"
          onCancel={() => this.setState({ modalOpenCavity: false })}
          onConfirm={() => {
            this.onCavityDeleteHandler();
          }}
          className="confirm-modal"
          size="tiny"
        />
        <Confirm
          open={modalOpenModes}
          closeOnDimmerClick={false}
          content="Are you sure you want to delete mode?"
          onCancel={() => this.setState({ modalOpenModes: false })}
          onConfirm={() => {
            this.onRemoveModesHandler();
          }}
          className="confirm-modal"
          size="tiny"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    brands: state.brands.brands,
    modes: state.modes.modes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBrands: (filters) => dispatch(fetchBrandsRequested(filters)),
    fetchModes: (filters) => dispatch(fetchModesRequested(filters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandCavity);
