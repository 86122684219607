import { put, call, takeLatest } from 'redux-saga/effects';

import {
  LOGIN_REQUESTED,
  VALIDATE_TOKEN_REQUESTED,
} from '../../constants/actions';
import {
  loginFailure,
  loginSuccessfull,
  validateTokenFailure,
  validateTokenSuccessfull,
} from '../actions/authActions';
import auth from '../../api/authApi';
import apiUtils from '../../api/apiUtils';
import fetchUserDetails from '../../api/userApi';

export function* requestAccessToken(action) {
  const { code } = action.payload;
  const { response, error } = yield call(auth.fetchAccessToken, code);
  if (response) {
    // eslint-disable-next-line camelcase
    const { access_token, id_token, refresh_token, expires_in } = response;
    localStorage.setItem('accessToken', access_token);
    localStorage.setItem('idToken', id_token);
    localStorage.setItem('refreshToken', refresh_token);
    localStorage.setItem('expiresIn', expires_in);
    // Set user information
    apiUtils.setUserInformation(access_token);
    yield put(loginSuccessfull(response));
  } else {
    yield put(loginFailure(error));
  }
}

export function* validateToken() {
  // TODO: Add the endpoint to validate JWT from the server
  // const { response, error } = yield call(auth.fetchAccessToken, code);
  if (
    localStorage.getItem('accessToken')
    // && localStorage.getItem('idToken') &&
    // localStorage.getItem('refreshToken')
  ) {
    // Set user information
    apiUtils.setUserInformation(localStorage.getItem('accessToken'));
    fetchUserDetails(localStorage.getItem('_id'));
    yield put(validateTokenSuccessfull());
  } else {
    yield put(validateTokenFailure());
  }
}

// export function* logout(action) {
//   yield call([localStorage, localStorage.clear]);
//   if (response) {
//     yield put(loginSuccessfull(response));
//   } else {
//     yield put(loginFailure(error));
//   }
// }

export default function* appsSaga() {
  yield takeLatest(LOGIN_REQUESTED, requestAccessToken);
  yield takeLatest(VALIDATE_TOKEN_REQUESTED, validateToken);
}
