import {
  CATEGORY_LISTING_REQUESTED,
  CATEGORY_LISTING_FAILURE,
  CATEGORY_LISTING_SUCCESSFUL,
} from '../../constants/actions';

export const fetchCategoryRequested = (data) => {
  return {
    type: CATEGORY_LISTING_REQUESTED,
    payload: data,
  };
};

export const fetchCategorySuccessful = (data) => {
  return {
    type: CATEGORY_LISTING_SUCCESSFUL,
    payload: data,
  };
};

export const fetchCategoryFailure = (data) => {
  return {
    type: CATEGORY_LISTING_FAILURE,
    payload: data,
  };
};
