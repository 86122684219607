import { put, call, takeLatest } from 'redux-saga/effects';
import regionApi from '../../api/regionApi';
import { REGION_LISTING_REQUESTED } from '../../constants/actions';
import {
  fetchregionFailure,
  fetchregionSuccessful,
} from '../actions/regionAction';

function* fetchRegion(action) {
  const { response, error } = yield call(regionApi.fetchRegion, action.payload);
  if (response) {
    yield put(fetchregionSuccessful(response));
  } else {
    yield put(fetchregionFailure(error));
  }
}

export default function* regionSaga() {
  yield takeLatest(REGION_LISTING_REQUESTED, fetchRegion);
}
