import { normalize } from 'normalizr';
import { dashboardProgramList } from '../state/schemas';
import ApiUtils from './apiUtils';

const fetchDashboardPrograms = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);

  try {
    const response = await ApiUtils.get(`api/programs?${queryString}`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, dashboardProgramList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const fetchDashboardSummary = async () => {
  // const queryString = ApiUtils.mapObjectToQueryString(param);

  try {
    const response = await ApiUtils.get(`api/dashboard/summary`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, dashboardProgramList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const fetchDashboardChartData = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);

  try {
    const response = await ApiUtils.get(`api/dashboard/charts?${queryString}`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, dashboardProgramList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchDashboardPrograms,
  fetchDashboardSummary,
  fetchDashboardChartData,
};
