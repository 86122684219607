import axios from 'axios';
import { tokenUrl } from '../constants/auth';

const fetchAccessToken = async (code) => {
  try {
    const params = new URLSearchParams();
    params.append('code', code);

    const response = await axios.post(tokenUrl, params, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    });

    const { data } = response.data;

    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchAccessToken,
};
