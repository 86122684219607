export const getLocalStorageValue = (key) => {
  const accessToken = localStorage.getItem(key);
  return accessToken;
};

export const setLocalStorageValue = (key, value) => {
  localStorage.setItem(key, value);
};

export const valueValidation = (inputvalue) => {
  // Email errors
  const errorvalue = {};
  if (!inputvalue.email) {
    errorvalue.email = 'Please enter the username';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputvalue.email)
  ) {
    errorvalue.email = 'Invalid email address';
  }
  // Password Errors
  if (!inputvalue.password) {
    errorvalue.password = 'Please enter your password';
  }
  return errorvalue;
};

export const transformData = (response) => {
  const temp = {
    data: {
      id: response.data.data._id,
      units: response.data.data.units,
      programTitle: response.data.data.title,
      internalTitle: response.data.data.internalTitle,
      categoryValue: response.data.data.category,
      tags: response.data.data.tags && response.data.data.tags.map((e) => e.id),
      tagsData: response.data.data.tags,
      filterValue:
        response.data.data.filters &&
        response.data.data.filters.map((e) => e.id),
      description2: response.data.data.reference.description,
      refUrlOne:
        response.data.data.reference &&
        response.data.data.reference.urls &&
        response.data.data.reference.urls[0] &&
        response.data.data.reference.urls[0],
      refUrlTwo:
        response.data.data.reference &&
        response.data.data.reference.urls &&
        response.data.data.reference.urls[1] &&
        response.data.data.reference.urls[1],
      description: response.data.data.description,
      fileBlobUrl: response.data.data.images.map((item) => item),
      selectedRegion: response.data.data.region.id,
      selectedCountry: response.data.data.country.id,
      selectedCavity: response.data.data.cavities.map((item) => item.id),
      instructionArr: response.data.data.userInstructions.map((item, index) => {
        return {
          instruction: item.instruction,
          refUrl: item.url,
          selectedIconId: item.icon.id,
          fileBlobUrl: item.image,
          order: index,
        };
      }),
      selectedbaseRadio: response.data.data.baseParameters.key,
      selectedQuantity: response.data.data.baseParameters.quantity,
      selectedbaseRadioValue: response.data.data.baseParameters.value.toString(),
      selectedRadio:
        response.data.data.type === 'probe' ? 'probeBased' : 'timeBased',
      preheatAlert:
        response.data.data.preheat && response.data.data.preheat.text
          ? { value: response.data.data.preheat.text, alert: true }
          : null,
      cookingMode:
        response.data.data.operations &&
        response.data.data.operations.map((e) => e.steps.map((f) => f.mode))[0],
      extraThickness:
        response.data.data.operations &&
        response.data.data.operations.map((e) => e.variations.value),
      extraQuantity:
        response.data.data.operations &&
        response.data.data.operations.map((e) => e.variations.quantity.value),
      alert:
        response.data.data.operations &&
        response.data.data.operations.map((e) =>
          e.steps.map((f) => f.alert)
        )[0],
      inputValue:
        response.data.data.operations &&
        response.data.data.operations.map((e) => {
          return {
            outcome: e.outcome.key,
            image: e.image,
            totalTime: {
              probeBased: {
                key: response.data.data.type === 'probe',
                time: Math.floor(e.time / 60),
                timeinMin: e.time % 60,
              },
              timeBased: {
                key: response.data.data.type !== 'probe',
                cookingTime: Math.floor(e.time / 60),
                cookingTimeinMin: e.time % 60,
              },
            },
            cookingTimeVariation: {
              thicknessTime: e.variations && e.variations.variation.toString(),
              quantityTime:
                e.variations && e.variations.quantity.variation.toString(),
            },
            precedingCookingStep:
              e.steps &&
              e.steps.map((step) => {
                return {
                  temperature: step.temperature,
                  timeBased: {
                    cookingTime: Math.floor(step.cookingTime / 60),
                    cookingTimeinMin: step.cookingTime % 60,
                  },
                  internalTemperature: step.internalTemperature,
                };
              }),
          };
        }),
    },
  };
  return temp;
};

export const options = [
  {
    key: 'rare',
    text: 'Rare',
    value: 'rare',
    order: 0,
  },
  {
    key: 'mediumRare',
    text: 'Medium Rare',
    value: 'mediumRare',
    order: 1,
  },
  {
    key: 'medium',
    text: 'Medium',
    value: 'medium',
    order: 2,
  },
  {
    key: 'mediumWell',
    text: 'Medium Well',
    value: 'mediumWell',
    order: 3,
  },
  {
    key: 'well',
    text: 'Well',
    value: 'well',
    order: 4,
  },
  {
    key: 'crispy',
    text: 'Crispy',
    value: 'crispy',
    order: 5,
  },
  {
    key: 'tender',
    text: 'Tender',
    value: 'tender',
    order: 6,
  },
  {
    key: 'lightBrowning',
    text: 'Light Browning',
    value: 'lightBrowning',
    order: 7,
  },
  {
    key: 'heavyBrowning',
    text: 'Heavy Browning',
    value: 'heavyBrowning',
    order: 8,
  },
];
