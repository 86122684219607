import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  Image,
  Input,
  Radio,
  Item,
  Label,
  Checkbox,
  Grid,
} from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dropzone from 'react-dropzone';
import trash from '../../../assets/images/trash.svg';
import upload from '../../../assets/images/upload-file.svg';
import move from '../../../assets/images/move.svg';
import filesApi from '../../../api/filesApi';
import { PROGRAMFORM_UPDATE } from '../../../constants/actions';
import { options } from '../../../utils/sharedFunctions';

const screenWidth = document.documentElement.clientWidth;
const isLargeWideMonitor = screenWidth >= 1200 && screenWidth <= 1440;

const quantityOptions = [
  {
    key: 0,
    text: '0',
    value: 0,
  },
  {
    key: 1,
    text: '1',
    value: 1,
  },
  {
    key: 2,
    text: '2',
    value: 2,
  },
  {
    key: 3,
    text: '3',
    value: 3,
  },
  {
    key: 4,
    text: '4',
    value: 4,
  },
  {
    key: 5,
    text: '5',
    value: 5,
  },
  {
    key: 6,
    text: '6',
    value: 6,
  },
];
const timeOptions = [
  {
    key: 'timeBased',
    text: 'Time Based',
    value: 'timeBased',
  },
  {
    key: 'probeBased',
    text: 'Probe Based',
    value: 'probeBased',
  },
];

function OvenOperationsForm(props) {
  const {
    commonModes,
    units,
    isValid,
    isTypeError,
    isTypeErrorForProbeTime,
  } = props;

  if (units && units.temperature === 'fahrenheit') {
    units.temperature = '\u00b0F';
  } else if (units && units.temperature === 'celsius') {
    units.temperature = '\u00b0C';
  }

  const [selectedRadio, setselectedRadio] = useState();
  const [selectedbaseRadio, setselectedbaseRadio] = useState();
  const [nextStepAlert, setnextStepAlert] = useState([{}]);
  const [preheatAlert, setPreheatAlert] = useState({});
  const [preheatClose, setPreheatClose] = useState(true);
  const [selectedWeight, setselectedWeight] = useState();
  const [selectedThickness, setselectedThickness] = useState();
  const [selectedQuantity, setselectedQuantity] = useState();
  const [extraThickness, setextraThickness] = useState();
  const [extraQuantity, setextraQuantity] = useState();
  const [cookingMode, setCookingMode] = useState([{}]);
  const [selectedOutcome, setselectedOutcome] = useState([]);
  const [displayOutcome] = useState(options);
  const [isDataObtained, setIsDataObtained] = useState(false);
  const [steps, setSteps] = useState([{}]);
  const [outcomeKeys, setOutcomeKeys] = useState([]);
  const [inputValue, setInputValue] = useState([{}]);
  const [scrollClass, setScrollClass] = useState([{}]);
  const [count, setCount] = useState(1);
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.program);

  if (programData && programData.data && programData.data.units) {
    if (programData.data.units.temperature === 'fahrenheit') {
      programData.data.units.temperature = '\u00b0F';
    } else if (programData.data.units.temperature === 'celsius') {
      programData.data.units.temperature = '\u00b0C';
    }
  }

  if (
    !isDataObtained &&
    programData &&
    programData.data &&
    programData.data.inputValue &&
    programData.data.inputValue.length > 0
  ) {
    setIsDataObtained(true);
  }

  const setOperations = () => {
    const outComeArr = [];
    const stepArray = [];
    const outComeKeyArr = [];
    const dataArray =
      programData && programData.data && programData.data.inputValue
        ? programData.data.inputValue
        : [];
    dataArray.forEach((e) => {
      const initObj = {};
      initObj.selectedOutcome = e.outcome;
      initObj.id = e.outcome;
      outComeArr.push(initObj);
      stepArray.push(e.precedingCookingStep);
      outComeKeyArr.push(e.outcome);
    });
    setselectedOutcome(outComeArr);
    setOutcomeKeys(outComeKeyArr);
    setCount(outComeArr.length);
    const list = programData && programData.data && programData.data.inputValue;
    setInputValue(list);
    const temp = [];
    const res = stepArray
      .map((a) => a.length)
      .indexOf(Math.max(...stepArray.map((a) => a.length)));
    const finalStep = stepArray[res] || [];
    finalStep.forEach(() => {
      const initObj = {};
      initObj.step = '';
      initObj.id = '';
      temp.push(initObj);
    });
    setSteps(temp);
    const modeList =
      programData && programData.data && programData.data.cookingMode;
    setCookingMode(modeList);
    const alertArr = programData && programData.data && programData.data.alert;
    setnextStepAlert(alertArr);
  };

  useEffect(() => {
    setOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataObtained]);

  const handleOutcomeChange = (e, index) => {
    const { key } = e.options.find((o) => o.value === e.value);
    const optionkey = [...outcomeKeys];
    optionkey[index] = key;
    setOutcomeKeys(optionkey);
    const { name, value } = e;
    const list = [...selectedOutcome];
    list[index][name] = value;
    list[index].id = key;
    setselectedOutcome(list);
    if (!inputValue[index]) inputValue.push({ outcome: '' });
    inputValue[index].outcome = e.value;
    setInputValue(inputValue);
    dispatch({ type: PROGRAMFORM_UPDATE, payload: { inputValue } });
  };

  const handleRemoveClick = (index) => {
    const list = [...selectedOutcome];
    const keyList = [...outcomeKeys];
    list.splice(index, 1);
    keyList.splice(index, 1);
    setselectedOutcome(list);
    setOutcomeKeys(keyList);
    const inputList = [...inputValue];
    inputList.splice(index, 1);
    setInputValue(inputList);
    dispatch({ type: PROGRAMFORM_UPDATE, payload: { inputValue } });
    setCount(Number(count) - 1);
    isTypeErrorForProbeTime.splice(index, 1);
    dispatch({
      type: PROGRAMFORM_UPDATE,
      payload: { columnCount: count - 1 },
    });
  };

  const checkColumnCount = (index) => {
    if (index >= 3) {
      setScrollClass('add-scroll');
    }
  };

  const handleAddClick = () => {
    const list = [...inputValue];
    list.push({});
    setInputValue(list);
    setCount(Number(count) + 1);
    checkColumnCount(count);
    setselectedOutcome([...selectedOutcome, { selectedOutcome: '', id: '' }]);
    dispatch({
      type: PROGRAMFORM_UPDATE,
      payload: { columnCount: count + 1 },
    });
  };

  const handleRemoveStep = (j) => {
    const temp = [...steps];
    const list = [...inputValue];
    const modelist = [...cookingMode];
    const alertlist = [...nextStepAlert];
    temp.splice(j, 1);
    modelist.splice(j, 1);
    alertlist.splice(j, 1);
    list.map((e) => {
      if (e.precedingCookingStep) return e.precedingCookingStep.splice(j, 1);
      return e;
    });
    setSteps(temp);
    setInputValue(list);
    setCookingMode(modelist);
    setnextStepAlert(alertlist);
    dispatch({
      type: PROGRAMFORM_UPDATE,
      payload: {
        cookingMode: modelist,
        inputValue: list,
        alert: alertlist,
        stepsCount: steps.length - 1,
      },
    });
  };

  const handleAddStep = () => {
    setSteps([...steps, { steps: '', id: '' }]);
    const list = [...inputValue];
    const cookingList = [...cookingMode, { key: '', value: '', name: '' }];
    const alertList = [...nextStepAlert, { text: '', nextStepManual: '' }];
    const list1 = list.map((e) => {
      if (e && e.precedingCookingStep) {
        e.precedingCookingStep = [
          ...e.precedingCookingStep,
          {
            internalTemperature: '',
            temperature: '',
            timeBased: {
              cookingTime: '',
              cookingTimeinMin: '',
            },
          },
        ];
      }
      return e;
    });
    setInputValue(list1);
    setCookingMode(cookingList);
    setnextStepAlert(alertList);
    dispatch({
      type: PROGRAMFORM_UPDATE,
      payload: {
        inputValue: list1,
        cookingMode: cookingList,
        alert: alertList,
        stepsCount: steps.length + 1,
      },
    });
  };

  const reorderSteps = (
    list,
    cookingmodeList,
    alertList,
    startIndex,
    endIndex
  ) => {
    const modelist = [...cookingmodeList];
    const alertlist = [...alertList];
    const shift = modelist.splice(startIndex, 1);
    const shiftAlert = alertlist.splice(startIndex, 1);
    alertlist.splice(endIndex, 0, shiftAlert[0]);
    modelist.splice(endIndex, 0, shift[0]);
    const result = Array.from(list);
    result.map((item) => {
      if (item.precedingCookingStep) {
        const [removed] = item.precedingCookingStep.splice(startIndex, 1);
        item.precedingCookingStep.splice(endIndex, 0, removed);
      }
      return item;
    });
    return { result, modelist, alertlist };
  };

  const onStepsDragged = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorderSteps(
      inputValue,
      cookingMode,
      nextStepAlert,
      result.source.index,
      result.destination.index
    );
    setInputValue(items.result);
    setCookingMode(items.modelist);
    setnextStepAlert(items.alertlist);
    dispatch({
      type: PROGRAMFORM_UPDATE,
      payload: {
        cookingMode: items.modelist,
        inputValue: items.result,
        alert: items.alertlist,
      },
    });
  };

  const getViewStyle = (dragProps) => {
    const style = {
      position: 'static',
      padding: 0,
      margin: 0,
      top: 'auto',
      left: 'auto',
    };
    return { ...dragProps.style, ...style };
  };

  const calculateFinalTime = (i) => {
    let time;
    if (
      programData &&
      programData.data &&
      programData.data.selectedRadio === 'probeBased'
    ) {
      if (
        inputValue &&
        inputValue[i] &&
        inputValue[i].totalTime &&
        inputValue[i].totalTime.probeBased &&
        inputValue[i].totalTime.probeBased.time
      )
        time = `${inputValue[i].totalTime.probeBased.time}h`;
      if (
        inputValue &&
        inputValue[i] &&
        inputValue[i].totalTime &&
        inputValue[i].totalTime.probeBased &&
        inputValue[i].totalTime.probeBased.timeinMin
      )
        time = `${inputValue[i].totalTime.probeBased.timeinMin}m`;
      if (
        inputValue &&
        inputValue[i] &&
        inputValue[i].totalTime &&
        inputValue[i].totalTime.probeBased &&
        inputValue[i].totalTime.probeBased.time &&
        inputValue[i].totalTime.probeBased.timeinMin
      )
        time = `${inputValue[i].totalTime.probeBased.time}h ${inputValue[i].totalTime.probeBased.timeinMin}m`;
    } else if (inputValue[i] && inputValue[i].precedingCookingStep) {
      const totalTime =
        inputValue &&
        inputValue[i] &&
        inputValue[i].precedingCookingStep &&
        inputValue[i].precedingCookingStep.reduce((a, b) => {
          let sum = a;
          if (b && b.timeBased && b.timeBased.cookingTime)
            sum += Number(b.timeBased.cookingTime * 60);
          if (b && b.timeBased && b.timeBased.cookingTimeinMin)
            sum += Number(b.timeBased.cookingTimeinMin);
          return sum;
        }, 0);
      const num = totalTime;
      const hours = num / 60;
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);
      return `${rhours}h ${rminutes}m`;
    }
    return time;
  };

  const removeImage = (j) => {
    const list = [...inputValue];
    delete list[j].image;
    setInputValue(list);
    dispatch({ type: PROGRAMFORM_UPDATE, payload: { inputValue: list } });
  };

  const uploadFile = async (file, index) => {
    const queryParam = {
      type: 'general',
    };
    const formData = new FormData();
    formData.append('file', file);
    const filesResponse = await filesApi.postFiles(queryParam, formData);
    if (filesResponse.error) {
      return;
    }
    const filesData = {};
    if (
      filesResponse &&
      filesResponse.response &&
      filesResponse.response.data
    ) {
      filesData.id = filesResponse.response.data._id;
      filesData.value = filesResponse.response.data.url;
    }
    const list = [...inputValue];
    if (!list[index]) inputValue.push({ image: '' });
    list[index].image = filesData;
    setInputValue(inputValue);
    dispatch({ type: PROGRAMFORM_UPDATE, payload: { inputValue } });
  };
  const getUploadFiles = async (acceptedFiles, index) => {
    acceptedFiles.map(async (file) => {
      uploadFile(file, index);
    });
  };
  return (
    <div>
      <Header as="h5" className="d-flex align-center">
        Base Parameters
        <Header.Subheader className="font-regular ml-10">
          (Below oven operations values are based on these parameters)
        </Header.Subheader>
      </Header>
      <div className="pink-bg base-para-form">
        <Form.Group inline className="m-0">
          <Form.Field
            className="d-flex align-center"
            error={
              programData.data &&
              ((!programData.data.selectedbaseRadioValue && !isValid) ||
                (programData.data.selectedbaseRadioValue &&
                  isTypeError.selectedbaseRadioValue)) &&
              programData.data.selectedbaseRadio === 'weight'
                ? 'error'
                : ''
            }
            width={3}
          >
            <Radio
              label="Weight"
              name="baseParameters"
              value={
                programData.data && programData.data.selectedbaseRadio
                  ? programData.data.selectedbaseRadio
                  : 'weight'
              }
              checked={
                (programData.data &&
                  programData.data.selectedbaseRadio === 'weight') ||
                selectedbaseRadio === 'weight'
              }
              onChange={(e, { value }) => {
                setselectedbaseRadio(value);
                dispatch({
                  type: PROGRAMFORM_UPDATE,
                  payload: { selectedbaseRadio: value },
                });
              }}
            />
            <Input
              selection
              compact
              name="selectedWeight"
              value={
                programData.data &&
                programData.data.selectedbaseRadio === 'weight'
                  ? programData.data.selectedbaseRadioValue
                  : selectedWeight
              }
              onChange={(e) => {
                setselectedbaseRadio('weight');
                setselectedWeight(e.target.value);
                setselectedThickness('');
                dispatch({
                  type: PROGRAMFORM_UPDATE,
                  payload: {
                    selectedbaseRadioValue: e.target.value,
                    selectedbaseRadio: 'weight',
                  },
                });
              }}
            />
            {programData &&
            programData.data &&
            programData.data.selectedbaseRadio === 'weight'
              ? programData &&
                programData.data &&
                programData.data.units &&
                programData.data.units.weight
              : ''}
          </Form.Field>
          <Form.Field
            className="d-flex align-center"
            error={
              programData.data &&
              ((!programData.data.selectedbaseRadioValue && !isValid) ||
                (programData.data.selectedbaseRadioValue &&
                  isTypeError.selectedbaseRadioValue)) &&
              selectedbaseRadio === 'thickness'
                ? 'error'
                : ''
            }
            width={3}
          >
            <Radio
              label="Thickness"
              name="baseParameters"
              value={
                programData.data && programData.data.selectedbaseRadio
                  ? programData.data.selectedbaseRadio
                  : 'thickness'
              }
              checked={
                (programData.data &&
                  programData.data.selectedbaseRadio === 'thickness') ||
                selectedbaseRadio === 'thickness'
              }
              onChange={(e, { value }) => {
                setselectedbaseRadio(value);
                dispatch({
                  type: PROGRAMFORM_UPDATE,
                  payload: { selectedbaseRadio: value },
                });
              }}
            />
            <Input
              compact
              className={`mr-10 ${
                !selectedThickness && !isValid ? 'error' : ''
              }`}
              // type="number"
              value={
                programData.data &&
                programData.data.selectedbaseRadio === 'thickness'
                  ? programData.data.selectedbaseRadioValue
                  : selectedThickness
              }
              onChange={(e) => {
                setselectedbaseRadio('thickness');
                setselectedThickness(e.target.value);
                setselectedWeight('');
                dispatch({
                  type: PROGRAMFORM_UPDATE,
                  payload: {
                    selectedbaseRadioValue: e.target.value,
                    selectedbaseRadio: 'thickness',
                  },
                });
              }}
            />
            {programData &&
            programData.data &&
            programData.data.selectedbaseRadio === 'thickness'
              ? programData &&
                programData.data &&
                programData.data.units &&
                programData.data.units.thickness
              : ''}
          </Form.Field>
          <Form.Field
            className="d-flex align-center quantity-field"
            error={
              programData.data &&
              programData.data.selectedQuantity === undefined &&
              selectedQuantity === undefined &&
              !isValid
                ? 'error'
                : ''
            }
          >
            <label>Quantity</label>
            <Dropdown
              options={quantityOptions}
              selection
              compact
              name="selectedQuantity"
              value={programData.data && programData.data.selectedQuantity}
              onChange={(e, quantityValue) => {
                setselectedQuantity(quantityValue.value);
                dispatch({
                  type: PROGRAMFORM_UPDATE,
                  payload: { selectedQuantity: quantityValue.value },
                });
              }}
            />
          </Form.Field>
        </Form.Group>
      </div>
      <Divider hidden />
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width="3">
            <Form.Field>
              <Checkbox
                label="Preheat alert"
                checked={
                  programData &&
                  programData.data &&
                  programData.data.preheatAlert &&
                  programData.data.preheatAlert.alert
                }
                onChange={() => {
                  const s = { ...preheatAlert };

                  s.alert =
                    programData &&
                    programData.data &&
                    programData.data.preheatAlert
                      ? !programData.data.preheatAlert.alert
                      : !preheatAlert.alert;

                  setPreheatAlert(s);
                  dispatch({
                    type: PROGRAMFORM_UPDATE,
                    payload: { preheatAlert: s },
                  });
                }}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column width="13">
            <Form.Field
              error={
                programData &&
                programData.data &&
                programData.data.preheatAlert &&
                !programData.data.preheatAlert.value &&
                !preheatAlert.value &&
                !isValid
                  ? 'error'
                  : ''
              }
            >
              <Input
                name=""
                label={{
                  basic: true,
                  content: (
                    <Button
                      className="pointer link-button m-0"
                      disabled={preheatClose}
                      onClick={() => {
                        const s = { ...preheatAlert };
                        s.value = '';
                        setPreheatAlert(s);
                        dispatch({
                          type: PROGRAMFORM_UPDATE,
                          payload: { preheat: s },
                        });
                      }}
                    >
                      &#10005;
                    </Button>
                  ),
                }}
                labelPosition="right"
                className="large clearable"
                // disabled={!preheatAlert.alert}
                disabled={
                  programData &&
                  programData.data &&
                  ((programData.data.preheatAlert &&
                    !programData.data.preheatAlert.alert) ||
                    programData.data.preheatAlert === null)
                }
                value={
                  programData &&
                  programData.data &&
                  programData.data.preheatAlert &&
                  programData.data.preheatAlert.value
                    ? programData.data.preheatAlert.value
                    : preheatAlert.value
                }
                onChange={(e) => {
                  setPreheatClose(false);
                  const s = { ...preheatAlert };
                  s.value = e.target.value;
                  s.alert = true;
                  setPreheatAlert(s);
                  dispatch({
                    type: PROGRAMFORM_UPDATE,
                    payload: { preheatAlert: s },
                  });
                }}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Header as="h5" className="d-flex align-center">
        Oven Operations
      </Header>
      <div className="pink-bg oven-operation-form p-0">
        <div className={` oven-operation-wrapper ${scrollClass}`}>
          <div className="row bg-white align-end full-width">
            <div className="label-column text-right p-0" />
            {selectedOutcome.map((x, i) => {
              return (
                <>
                  <div className="input-column bg-white p-0 d-flex justify-end">
                    {selectedOutcome.length !== 1 ? (
                      <div className="delete-icon pointer">
                        <Image
                          src={trash}
                          onClick={() => {
                            handleRemoveClick(i, x);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="delete-icon empty" />
                    )}
                  </div>
                </>
              );
            })}
          </div>
          <div className="row">
            <div className="label-column text-right">
              <label className="heading-lable">Outcome</label>
            </div>
            {selectedOutcome.map((x, i) => {
              return (
                <div className="input-column position-relative">
                  <Form.Field
                    className="mb-0"
                    width="16"
                    error={!x.selectedOutcome && !isValid ? 'error' : ''}
                  >
                    <Dropdown
                      placeholder="Select"
                      fluid
                      selection
                      name="selectedOutcome"
                      value={x.selectedOutcome}
                      options={displayOutcome
                        .map((e) => {
                          if (
                            outcomeKeys
                              .filter((ip, ind) => ind !== i)
                              .indexOf(e.key) === -1
                          ) {
                            return {
                              key: e.key,
                              text: e.text,
                              value: e.value,
                              order: e.order,
                            };
                          }
                          return 'false';
                        })
                        .filter((oc) => oc !== 'false')}
                      onChange={(e, outcomeValue) =>
                        handleOutcomeChange(outcomeValue, i)
                      }
                    />
                  </Form.Field>
                </div>
              );
            })}
            <div className="button-wrap">
              <Button
                icon="add"
                disabled={count >= displayOutcome.length}
                onClick={handleAddClick}
              />
            </div>
          </div>
          <div className="row">
            <Divider fitted className="ml-15" />
          </div>
          <div className="row">
            <div className="label-column">
              <Dropdown
                selection
                value={
                  programData &&
                  programData.data &&
                  programData.data.selectedRadio
                    ? programData.data.selectedRadio
                    : selectedRadio
                }
                options={timeOptions}
                onChange={(e, { value }) => {
                  setselectedRadio(value);
                  dispatch({
                    type: PROGRAMFORM_UPDATE,
                    payload: { selectedRadio: value },
                  });
                }}
                className="time-options"
              />
            </div>
            <div className="input-column empty" />
          </div>
          <div className="row">
            <Divider
              fitted
              className="ml-15"
              style={{ paddingBottom: '15px' }}
            />
          </div>
          <div className="row white-bg">
            <div className="label-column">
              {/* <Form.Field> */}
              <label>Forecast Time</label>
              {/* </Form.Field> */}
            </div>
            {selectedOutcome.map((x, i) => {
              return (
                <div className="input-column d-flex align-end">
                  <Form.Group className="mb-0">
                    <Form.Field
                      width="7"
                      className="pl-0"
                      error={
                        (inputValue &&
                          inputValue[i] &&
                          (inputValue[i].totalTime === undefined ||
                            inputValue[i].totalTime.probeBased === undefined ||
                            (inputValue[i].totalTime.probeBased.time === '' &&
                              inputValue[i].totalTime.probeBased.timeinMin ===
                                '') ||
                            (inputValue[i].totalTime.probeBased.time ===
                              undefined &&
                              inputValue[i].totalTime.probeBased.timeinMin ===
                                '') ||
                            (inputValue[i].totalTime.probeBased.time === '' &&
                              inputValue[i].totalTime.probeBased.timeinMin ===
                                undefined) ||
                            (inputValue[i].totalTime.probeBased.time === 0 &&
                              inputValue[i].totalTime.probeBased.timeinMin ===
                                0)) &&
                          !isValid) ||
                        (inputValue &&
                          inputValue[i] &&
                          inputValue[i].totalTime &&
                          inputValue[i].totalTime.probeBased &&
                          isTypeErrorForProbeTime.length ===
                            inputValue.length &&
                          (inputValue[i].totalTime.probeBased.time ||
                            inputValue[i].totalTime.probeBased.timeinMin) &&
                          isTypeErrorForProbeTime[i] === true)
                          ? 'error'
                          : ''
                      }
                    >
                      <Input
                        // type="number"
                        name="forecastTimeHr"
                        value={
                          inputValue &&
                          inputValue[i] &&
                          inputValue[i].totalTime &&
                          inputValue[i].totalTime.probeBased &&
                          inputValue[i].totalTime.probeBased.time
                        }
                        label={{ basic: true, content: 'hr' }}
                        labelPosition="right"
                        disabled={
                          programData &&
                          programData.data &&
                          programData.data.selectedRadio === 'timeBased'
                        }
                        onChange={(e) => {
                          const list = [...inputValue];
                          if (!list[i]) list.push({ totalTime: {} });
                          if (!list[i].totalTime) list[i].totalTime = {};
                          if (!list[i].totalTime.probeBased)
                            list[i].totalTime.probeBased = {};
                          list[i].totalTime.probeBased.time = e.target.value;
                          setInputValue(list);
                          dispatch({
                            type: PROGRAMFORM_UPDATE,
                            payload: {
                              inputValue: list,
                              selectedRadio: 'probeBased',
                            },
                          });
                        }}
                      />
                    </Form.Field>
                    <Form.Field
                      width="9"
                      className="pr-0"
                      error={
                        (inputValue &&
                          inputValue[i] &&
                          (inputValue[i].totalTime === undefined ||
                            inputValue[i].totalTime.probeBased === undefined ||
                            (inputValue[i].totalTime.probeBased.time === '' &&
                              inputValue[i].totalTime.probeBased.timeinMin ===
                                '') ||
                            (inputValue[i].totalTime.probeBased.time ===
                              undefined &&
                              inputValue[i].totalTime.probeBased.timeinMin ===
                                '') ||
                            (inputValue[i].totalTime.probeBased.time === '' &&
                              inputValue[i].totalTime.probeBased.timeinMin ===
                                undefined) ||
                            (inputValue[i].totalTime.probeBased.time === 0 &&
                              inputValue[i].totalTime.probeBased.timeinMin ===
                                0)) &&
                          !isValid) ||
                        (inputValue &&
                          inputValue[i] &&
                          inputValue[i].totalTime &&
                          inputValue[i].totalTime.probeBased &&
                          isTypeErrorForProbeTime.length ===
                            inputValue.length &&
                          (inputValue[i].totalTime.probeBased.time ||
                            inputValue[i].totalTime.probeBased.timeinMin) &&
                          isTypeErrorForProbeTime[i] === true)
                          ? 'error'
                          : ''
                      }
                    >
                      <Input
                        // type="number"
                        name="forecastTimeMin"
                        // placeholder="min"
                        label={{ basic: true, content: 'min' }}
                        labelPosition="right"
                        disabled={
                          programData &&
                          programData.data &&
                          programData.data.selectedRadio === 'timeBased'
                        }
                        value={
                          inputValue &&
                          inputValue[i] &&
                          inputValue[i].totalTime &&
                          inputValue[i].totalTime.probeBased &&
                          inputValue[i].totalTime.probeBased.timeinMin
                        }
                        onChange={(e) => {
                          const list = [...inputValue];
                          if (!list[i]) list.push({ totalTime: {} });
                          if (!list[i].totalTime) list[i].totalTime = {};
                          if (!list[i].totalTime.probeBased)
                            list[i].totalTime.probeBased = {};
                          list[i].totalTime.probeBased.timeinMin =
                            e.target.value;
                          setInputValue(list);
                          dispatch({
                            type: PROGRAMFORM_UPDATE,
                            payload: {
                              inputValue: list,
                              selectedRadio: 'probeBased',
                            },
                          });
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </div>
              );
            })}
          </div>
        </div>
        {/* </div> */}
        <div className="row" style={{ paddingTop: '15px' }}>
          <Divider fitted className="ml-15" />
        </div>
        <div className="row">
          <div className="label-column">
            <label className="heading-lable">Cooking step(s)</label>
          </div>
          {selectedOutcome.map(() => {
            return <div className="input-column empty large" />;
          })}
        </div>
        <DragDropContext onDragEnd={onStepsDragged}>
          <Droppable droppableId="droppable123">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {steps.map((step, j) => {
                  return (
                    <Draggable
                      key={`drag-key-${j * 101}`}
                      draggableId={`drag-key-${j * 101}`}
                      index={j}
                    >
                      {(provide) => (
                        <div
                          style={getViewStyle(provide.draggableProps)}
                          ref={provide.innerRef}
                          {...provide.draggableProps}
                          {...provide.dragHandleProps}
                        >
                          <div className="row">
                            <div className="label-column ptb-0">
                              <Label className="step-label">Step {j + 1}</Label>
                            </div>
                            {selectedOutcome.map(() => {
                              return (
                                <div className="input-column empty small" />
                              );
                            })}
                          </div>
                          <div className="draggable-wrap">
                            <div className="row white-bg flex-column position-relative">
                              <div className="d-flex align-center">
                                <div className="label-column">
                                  <label>Mode / Program</label>
                                </div>
                                {selectedOutcome.map((x, i) => {
                                  return (
                                    <div className="input-column">
                                      <Form.Field
                                        width="16"
                                        error={
                                          !(
                                            cookingMode[j] &&
                                            cookingMode[j].value
                                          ) ||
                                          (programData.data.selectedCavity
                                            .length === 0 &&
                                            !isValid)
                                            ? 'error'
                                            : ''
                                        }
                                      >
                                        <Dropdown
                                          placeholder="Select"
                                          fluid
                                          selection
                                          name="precedingMode"
                                          disabled={i !== 0}
                                          options={
                                            programData &&
                                            programData.data &&
                                            programData.data.selectedRadio ===
                                              'timeBased'
                                              ? commonModes &&
                                                commonModes.map((e) => {
                                                  return {
                                                    key: e.key,
                                                    text: e.name,
                                                    value: e.name,
                                                  };
                                                })
                                              : commonModes &&
                                                commonModes
                                                  .map((e) => {
                                                    if (e.meatProbe) {
                                                      return {
                                                        key: e.key,
                                                        text: e.name,
                                                        value: e.name,
                                                      };
                                                    }
                                                    return 'false';
                                                  })
                                                  .filter(
                                                    (mode) => mode !== 'false'
                                                  )
                                          }
                                          value={
                                            cookingMode[j] &&
                                            cookingMode[j].name &&
                                            cookingMode[j].name
                                          }
                                          onChange={(e, data) => {
                                            const { value } = data;
                                            const { key } = data.options.find(
                                              (o) => o.value === value
                                            );
                                            const list = [...cookingMode];
                                            if (!list[j]) list[j] = {};
                                            list[j].name = value;
                                            list[j].value = value;
                                            list[j].key = key;
                                            setCookingMode(list);
                                            dispatch({
                                              type: PROGRAMFORM_UPDATE,
                                              payload: { cookingMode: list },
                                            });
                                          }}
                                        />
                                      </Form.Field>
                                    </div>
                                  );
                                })}
                              </div>
                              <Divider fitted />
                              <div className="d-flex align-center">
                                <div className="label-column">
                                  <label>Temperature</label>
                                </div>
                                {selectedOutcome.map((x, i) => {
                                  return (
                                    <div className="input-column">
                                      <Form.Field
                                        width="16"
                                        error={
                                          inputValue &&
                                          inputValue[i] &&
                                          (!inputValue[i]
                                            .precedingCookingStep ||
                                            (inputValue[i]
                                              .precedingCookingStep &&
                                              !inputValue[i]
                                                .precedingCookingStep[j]) ||
                                            (inputValue[i]
                                              .precedingCookingStep &&
                                              inputValue[i]
                                                .precedingCookingStep[j] &&
                                              (inputValue[i]
                                                .precedingCookingStep[j]
                                                .temperature === '' ||
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .temperature === null ||
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .temperature ===
                                                  undefined))) &&
                                          !isValid
                                            ? 'error'
                                            : ''
                                        }
                                      >
                                        <Input
                                          type="number"
                                          name="precedingTemperature"
                                          min="0"
                                          label={{
                                            basic: true,
                                            content: (
                                              <span>
                                                {programData &&
                                                programData.data &&
                                                programData.data.units
                                                  ? programData.data.units
                                                      .temperature
                                                  : units.temperature}
                                              </span>
                                            ),
                                          }}
                                          labelPosition="right"
                                          value={
                                            inputValue &&
                                            inputValue[i] &&
                                            inputValue[i]
                                              .precedingCookingStep &&
                                            inputValue[i].precedingCookingStep[
                                              j
                                            ] &&
                                            inputValue[i].precedingCookingStep[
                                              j
                                            ].temperature &&
                                            inputValue[i].precedingCookingStep[
                                              j
                                            ].temperature
                                          }
                                          onChange={(e) => {
                                            const list = [...inputValue];
                                            if (!list[i])
                                              list.push({
                                                precedingCookingStep: [{}],
                                              });
                                            if (!list[i].precedingCookingStep)
                                              list[i].precedingCookingStep = [
                                                {},
                                              ];
                                            if (
                                              !list[i].precedingCookingStep[j]
                                            )
                                              list[i].precedingCookingStep[
                                                j
                                              ] = {};
                                            list[i].precedingCookingStep[
                                              j
                                            ].temperature = e.target.value;
                                            setInputValue(list);
                                            dispatch({
                                              type: PROGRAMFORM_UPDATE,
                                              payload: { inputValue: list },
                                            });
                                          }}
                                        />
                                      </Form.Field>
                                    </div>
                                  );
                                })}
                              </div>
                              <Divider fitted />
                              {programData &&
                              programData.data &&
                              programData.data.selectedRadio ===
                                'probeBased' ? (
                                <div className="d-flex align-center">
                                  <div className="label-column">
                                    <label>Internal Temperature</label>
                                  </div>
                                  {selectedOutcome.map((x, i) => {
                                    return (
                                      <div className="input-column">
                                        <Form.Field
                                          width="16"
                                          error={
                                            inputValue &&
                                            inputValue[i] &&
                                            (!inputValue[i]
                                              .precedingCookingStep ||
                                              (inputValue[i]
                                                .precedingCookingStep &&
                                                !inputValue[i]
                                                  .precedingCookingStep[j]) ||
                                              (inputValue[i]
                                                .precedingCookingStep &&
                                                inputValue[i]
                                                  .precedingCookingStep[j] &&
                                                (inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .internalTemperature === '' ||
                                                  inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .internalTemperature ===
                                                    null ||
                                                  inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .internalTemperature ===
                                                    undefined))) &&
                                            !isValid
                                              ? 'error'
                                              : ''
                                          }
                                        >
                                          <Input
                                            type="number"
                                            min="0"
                                            name="internalTemperature"
                                            label={{
                                              basic: true,
                                              content: (
                                                <span>
                                                  {programData &&
                                                  programData.data &&
                                                  programData.data.units
                                                    ? programData.data.units
                                                        .temperature
                                                    : units.temperature}
                                                </span>
                                              ),
                                            }}
                                            labelPosition="right"
                                            value={
                                              // programData.data && programData.data.inputValue[i] && programData.data.inputValue[i].precedingCookingStep[j] ?programData.data.inputValue[i].precedingCookingStep[j].internalTemperature :
                                              inputValue &&
                                              inputValue[i] &&
                                              inputValue[i]
                                                .precedingCookingStep &&
                                              inputValue[i]
                                                .precedingCookingStep[j] &&
                                              inputValue[i]
                                                .precedingCookingStep[j]
                                                .internalTemperature &&
                                              inputValue[i]
                                                .precedingCookingStep[j]
                                                .internalTemperature
                                            }
                                            onChange={(e) => {
                                              const list = [...inputValue];
                                              if (!list[i])
                                                list.push({
                                                  precedingCookingStep: [{}],
                                                });
                                              if (!list[i].precedingCookingStep)
                                                list[i].precedingCookingStep = [
                                                  {},
                                                ];
                                              if (
                                                !list[i].precedingCookingStep[j]
                                              )
                                                list[i].precedingCookingStep[
                                                  j
                                                ] = {};
                                              list[i].precedingCookingStep[
                                                j
                                              ].internalTemperature =
                                                e.target.value;
                                              delete list[i]
                                                .precedingCookingStep[j]
                                                .timeBased;
                                              setInputValue(list);
                                              dispatch({
                                                type: PROGRAMFORM_UPDATE,
                                                payload: { inputValue: list },
                                              });
                                            }}
                                          />
                                        </Form.Field>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <div className="d-flex">
                                  <div className="label-column">
                                    <label>Cooking Time</label>
                                  </div>
                                  {selectedOutcome.map((x, i) => {
                                    return (
                                      <div className="input-column">
                                        <Form.Group className="mb-0">
                                          <Form.Field
                                            width="7"
                                            className="pl-0"
                                            error={
                                              (inputValue &&
                                                inputValue[i] &&
                                                (inputValue[i]
                                                  .precedingCookingStep ===
                                                  undefined ||
                                                  inputValue[i]
                                                    .precedingCookingStep[j] ===
                                                    undefined ||
                                                  inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased === undefined ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased.cookingTime ===
                                                    undefined &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      '') ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .cookingTime === '' &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      undefined) ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased.cookingTime ===
                                                    '' &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      '') ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased.cookingTime ===
                                                    undefined &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      undefined) ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased.cookingTime ===
                                                    0 &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      0)) &&
                                                !isValid) ||
                                              (inputValue[i]
                                                .precedingCookingStep &&
                                                inputValue[i]
                                                  .precedingCookingStep[j] &&
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .timeBased &&
                                                ((inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .timeBased.cookingTime &&
                                                  !inputValue[
                                                    i
                                                  ].precedingCookingStep[
                                                    j
                                                  ].timeBased.cookingTime
                                                    .toString()
                                                    .match(/^[1-9][0-9]*$/)) ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased
                                                    .cookingTimeinMin &&
                                                    !inputValue[
                                                      i
                                                    ].precedingCookingStep[
                                                      j
                                                    ].timeBased.cookingTimeinMin
                                                      .toString()
                                                      .match(
                                                        /^[1-9][0-9]*$/
                                                      ))) &&
                                                !isValid)
                                                ? 'error'
                                                : ''
                                            }
                                          >
                                            <Input
                                              // type="number"
                                              name="precedingTimeHr"
                                              label={{
                                                basic: true,
                                                content: 'hr',
                                              }}
                                              labelPosition="right"
                                              value={
                                                inputValue &&
                                                inputValue[i] &&
                                                inputValue[i]
                                                  .precedingCookingStep &&
                                                inputValue[i]
                                                  .precedingCookingStep[j] &&
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .timeBased &&
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .timeBased.cookingTime
                                              }
                                              onChange={(e) => {
                                                const list = [...inputValue];
                                                if (!list[i])
                                                  list.push({
                                                    precedingCookingStep: [
                                                      { timeBased: {} },
                                                    ],
                                                  });
                                                if (
                                                  !list[i].precedingCookingStep
                                                )
                                                  list[
                                                    i
                                                  ].precedingCookingStep = [
                                                    { timeBased: {} },
                                                  ];
                                                if (
                                                  !list[i].precedingCookingStep[
                                                    j
                                                  ]
                                                )
                                                  list[i].precedingCookingStep[
                                                    j
                                                  ] = { timeBased: {} };
                                                if (
                                                  !list[i].precedingCookingStep[
                                                    j
                                                  ].timeBased
                                                )
                                                  list[i].precedingCookingStep[
                                                    j
                                                  ].timeBased = {};
                                                list[i].precedingCookingStep[
                                                  j
                                                ].timeBased.cookingTime =
                                                  e.target.value;
                                                delete list[i]
                                                  .precedingCookingStep[j]
                                                  .internalTemperature;
                                                setInputValue(list);
                                                dispatch({
                                                  type: PROGRAMFORM_UPDATE,
                                                  payload: {
                                                    inputValue: list,
                                                  },
                                                });
                                              }}
                                            />
                                          </Form.Field>
                                          <Form.Field
                                            width="9"
                                            className="pr-0"
                                            error={
                                              (inputValue &&
                                                inputValue[i] &&
                                                (inputValue[i]
                                                  .precedingCookingStep ===
                                                  undefined ||
                                                  inputValue[i]
                                                    .precedingCookingStep[j] ===
                                                    undefined ||
                                                  inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased === undefined ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased.cookingTime ===
                                                    undefined &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      '') ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .cookingTime === '' &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      undefined) ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased.cookingTime ===
                                                    '' &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      '') ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased.cookingTime ===
                                                    undefined &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      undefined) ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased.cookingTime ===
                                                    0 &&
                                                    inputValue[i]
                                                      .precedingCookingStep[j]
                                                      .timeBased
                                                      .cookingTimeinMin ===
                                                      0)) &&
                                                !isValid) ||
                                              (inputValue[i]
                                                .precedingCookingStep &&
                                                inputValue[i]
                                                  .precedingCookingStep[j] &&
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .timeBased &&
                                                ((inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .timeBased.cookingTime &&
                                                  !inputValue[
                                                    i
                                                  ].precedingCookingStep[
                                                    j
                                                  ].timeBased.cookingTime
                                                    .toString()
                                                    .match(/^[1-9][0-9]*$/)) ||
                                                  (inputValue[i]
                                                    .precedingCookingStep[j]
                                                    .timeBased
                                                    .cookingTimeinMin &&
                                                    !inputValue[
                                                      i
                                                    ].precedingCookingStep[
                                                      j
                                                    ].timeBased.cookingTimeinMin
                                                      .toString()
                                                      .match(
                                                        /^[1-9][0-9]*$/
                                                      ))) &&
                                                !isValid)
                                                ? 'error'
                                                : ''
                                            }
                                          >
                                            <Input
                                              // type="number"
                                              name="precedingTimeMin"
                                              label={{
                                                basic: true,
                                                content: 'min',
                                              }}
                                              labelPosition="right"
                                              value={
                                                //  programData.data && programData.data.inputValue[i] && programData.data.inputValue[i].precedingCookingStep[j] ?programData.data.inputValue[i].precedingCookingStep[j].cookingTime :
                                                inputValue &&
                                                inputValue[i] &&
                                                inputValue[i]
                                                  .precedingCookingStep &&
                                                inputValue[i]
                                                  .precedingCookingStep[j] &&
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .timeBased &&
                                                inputValue[i]
                                                  .precedingCookingStep[j]
                                                  .timeBased.cookingTimeinMin
                                              }
                                              onChange={(e) => {
                                                const list = [...inputValue];
                                                if (!list[i])
                                                  list.push({
                                                    precedingCookingStep: [
                                                      { timeBased: {} },
                                                    ],
                                                  });
                                                if (
                                                  !list[i].precedingCookingStep
                                                )
                                                  list[
                                                    i
                                                  ].precedingCookingStep = [
                                                    { timeBased: {} },
                                                  ];
                                                if (
                                                  !list[i].precedingCookingStep[
                                                    j
                                                  ]
                                                )
                                                  list[i].precedingCookingStep[
                                                    j
                                                  ] = { timeBased: {} };
                                                if (
                                                  !list[i].precedingCookingStep[
                                                    j
                                                  ].timeBased
                                                )
                                                  list[i].precedingCookingStep[
                                                    j
                                                  ].timeBased = {};
                                                list[i].precedingCookingStep[
                                                  j
                                                ].timeBased.cookingTimeinMin =
                                                  e.target.value;
                                                delete list[i]
                                                  .precedingCookingStep[j]
                                                  .internalTemperature;
                                                setInputValue(list);
                                                dispatch({
                                                  type: PROGRAMFORM_UPDATE,
                                                  payload: {
                                                    inputValue: list,
                                                  },
                                                });
                                              }}
                                            />
                                          </Form.Field>
                                        </Form.Group>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              <Divider fitted />
                              <div className="d-flex align-center full-width">
                                <div className="label-column">
                                  <label>Next Step Alert</label>
                                </div>
                                <div className="input-column alert-column bg-white">
                                  <Form.Group className="mb-0">
                                    <Form.Field width="11" className="pl-0">
                                      <Input
                                        name=""
                                        label={{
                                          basic: true,
                                          content: (
                                            <Button className="pointer link-button m-0">
                                              &#10005;
                                            </Button>
                                          ),
                                        }}
                                        value={
                                          nextStepAlert[j] &&
                                          nextStepAlert[j].text &&
                                          nextStepAlert[j].text
                                        }
                                        onChange={(e) => {
                                          const list = [...nextStepAlert];
                                          if (!list[j]) list[j] = {};
                                          list[j].text = e.target.value;
                                          setnextStepAlert(list);
                                          dispatch({
                                            type: PROGRAMFORM_UPDATE,
                                            payload: { alert: list },
                                          });
                                        }}
                                        labelPosition="right"
                                        className="clearable"
                                      />
                                    </Form.Field>
                                    <Form.Field
                                      width="5"
                                      className="left-divider d-flex align-center"
                                    >
                                      <Checkbox
                                        label="Require manual step"
                                        checked={
                                          nextStepAlert[j] &&
                                          nextStepAlert[j].nextStepManual
                                        }
                                        onChange={() => {
                                          const list = [...nextStepAlert];
                                          if (!list[j]) list[j] = {};
                                          // if (!list[j].alert)
                                          //   list[j].alert = {};
                                          list[j].nextStepManual = !list[j]
                                            .nextStepManual;
                                          setnextStepAlert(list);
                                          dispatch({
                                            type: PROGRAMFORM_UPDATE,
                                            payload: { alert: list },
                                          });
                                        }}
                                      />
                                    </Form.Field>
                                  </Form.Group>
                                </div>
                              </div>

                              {steps.length !== 1 && (
                                <div className="button-grp">
                                  <Button>
                                    <Image src={move} />
                                  </Button>

                                  <Button onClick={() => handleRemoveStep(j)}>
                                    <Image src={trash} />
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="row mt--half">
          <div className="label-column btn-wrap">
            <Button
              icon
              labelPosition="left"
              secondary
              compact
              onClick={handleAddStep}
            >
              <Icon name="add" />
              Add a step
            </Button>
          </div>
          {selectedOutcome.map(() => {
            return <div className="input-column empty" />;
          })}
        </div>
        <div className="row">
          <Divider className="ml-15 mt-0" />
        </div>
        <div className="row dark-pink-bg">
          <div className="label-column text-right">
            <label className="heading-lable">Final estimated time</label>
          </div>
          {selectedOutcome.map((x, i) => {
            return (
              <div className="input-column">
                <Header as="h3" className="d-flex justify-center">
                  {calculateFinalTime(i)}
                </Header>
              </div>
            );
          })}
        </div>
        <div className="row">
          <Divider className="ml-15 mb-0" />
        </div>
        <div className="row">
          <div className="label-column">
            <label className="heading-lable">
              Cooking time varies by <br />
              <span>(Per extra)</span>
            </label>
          </div>
          {selectedOutcome.map(() => {
            return <div className="input-column empty large" />;
          })}
        </div>
        <div className="row white-bg flex-column">
          <div className="d-flex align-center">
            <div className="label-column">
              <Form.Field
                className={`${
                  isLargeWideMonitor
                    ? 'flex-column justify-center'
                    : 'align-center'
                } d-flex`}
                width="16"
                error={
                  (programData &&
                    programData.data &&
                    !programData.data.extraThickness &&
                    !extraThickness &&
                    !isValid) ||
                  (extraThickness && isTypeError.extraThickness)
                    ? 'error'
                    : ''
                }
              >
                <div className="d-flex align-center">
                  {/* <label className="mr-10 mb-0">Per extra</label> */}
                  <Input
                    // type="number"
                    name="extraThickness"
                    value={
                      programData &&
                      programData.data &&
                      programData.data.extraThickness
                        ? programData.data.extraThickness[0]
                        : extraThickness
                    }
                    onChange={(e) => {
                      setextraThickness(e.target.value);
                      dispatch({
                        type: PROGRAMFORM_UPDATE,
                        payload: { extraThickness: e.target.value },
                      });
                    }}
                  />
                  <span>
                    {programData &&
                    programData.data &&
                    programData.data.selectedbaseRadio === 'weight'
                      ? programData &&
                        programData.data &&
                        programData.data.units &&
                        programData.data.units.weight
                      : programData &&
                        programData.data &&
                        programData.data.units &&
                        programData.data.units.thickness}
                  </span>
                  <label className="mb-0">
                    {programData &&
                    programData.data &&
                    programData.data.selectedbaseRadio === 'thickness'
                      ? 'Thickness'
                      : 'Weight'}
                  </label>
                </div>
              </Form.Field>
            </div>
            {selectedOutcome.map((x, i) => {
              return (
                <div
                  className={`${
                    isLargeWideMonitor && 'large'
                  } input-column d-flex align-center`}
                >
                  <Form.Field
                    className="m-auto"
                    width="10"
                    error={
                      (inputValue &&
                        inputValue[i] &&
                        (inputValue[i].cookingTimeVariation === undefined ||
                          !inputValue[i].cookingTimeVariation.thicknessTime) &&
                        !isValid) ||
                      (inputValue[i].cookingTimeVariation &&
                        inputValue[i].cookingTimeVariation.thicknessTime &&
                        !inputValue[i].cookingTimeVariation.thicknessTime
                          .toString()
                          .match(/^\d+(\.\d{1,2})?$/) &&
                        !isValid)
                        ? 'error'
                        : ''
                    }
                  >
                    <Input
                      // type="number"
                      label={{ basic: true, content: 'mins' }}
                      labelPosition="right"
                      name="thicknessTime"
                      value={
                        // programData && programData.data && programData.data.inputValue[i] &&programData.data.inputValue[i].cookingTimeVariation
                        //  && programData.data.inputValue[i].cookingTimeVariation.thicknessTime?programData.data.inputValue[i].cookingTimeVariation.thicknessTime :
                        inputValue &&
                        inputValue[i] &&
                        inputValue[i].cookingTimeVariation &&
                        inputValue[i].cookingTimeVariation.thicknessTime
                      }
                      onChange={(e) => {
                        const list = [...inputValue];
                        if (!list[i]) list.push({ cookingTimeVariation: {} });
                        if (!list[i].cookingTimeVariation)
                          list[i].cookingTimeVariation = {};
                        list[i].cookingTimeVariation.thicknessTime =
                          e.target.value;
                        setInputValue(list);
                        dispatch({
                          type: PROGRAMFORM_UPDATE,
                          payload: { inputValue: list },
                        });
                      }}
                    />
                  </Form.Field>
                </div>
              );
            })}
          </div>
          <Divider fitted />
          <div className="d-flex align-center">
            <div className="label-column">
              <Form.Field
                className={`${
                  isLargeWideMonitor
                    ? 'flex-column justify-center'
                    : 'align-center'
                } d-flex`}
                width="16"
                error={
                  programData &&
                  programData.data &&
                  programData.data.extraQuantity &&
                  programData.data.extraQuantity[0] === undefined &&
                  extraQuantity === undefined &&
                  !isValid
                    ? 'error'
                    : ''
                }
              >
                <div className="d-flex align-center">
                  {/* <label className="mr-10 mb-0">Per extra</label> */}
                  <Dropdown
                    // placeholder="Select"
                    compact
                    selection
                    name="extraQuantity"
                    options={quantityOptions}
                    value={
                      programData &&
                      programData.data &&
                      programData.data.extraQuantity
                        ? programData.data.extraQuantity[0]
                        : extraQuantity
                    }
                    onChange={(e, quantityValue) => {
                      setextraQuantity(quantityValue.value);
                      dispatch({
                        type: PROGRAMFORM_UPDATE,
                        payload: { extraQuantity: quantityValue.value },
                      });
                    }}
                  />
                  <span className="visibility-hidden">in</span>
                  <label className="mb-0">Quantity</label>
                </div>
              </Form.Field>
            </div>
            {selectedOutcome.map((x, i) => {
              return (
                <div
                  className={`${
                    isLargeWideMonitor && 'large'
                  } input-column d-flex align-center`}
                >
                  <Form.Field
                    className="m-auto"
                    width="10"
                    error={
                      (inputValue &&
                        inputValue[i] &&
                        (inputValue[i].cookingTimeVariation === undefined ||
                          !inputValue[i].cookingTimeVariation.quantityTime) &&
                        !isValid) ||
                      (inputValue[i].cookingTimeVariation &&
                        inputValue[i].cookingTimeVariation.quantityTime &&
                        !inputValue[i].cookingTimeVariation.quantityTime
                          .toString()
                          .match(/^\d+(\.\d{1,2})?$/) &&
                        !isValid)
                        ? 'error'
                        : ''
                    }
                  >
                    <Input
                      label={{ basic: true, content: 'mins' }}
                      labelPosition="right"
                      name="quantityTime"
                      value={
                        // programData && programData.data && programData.data.inputValue[i] && programData.data.inputValue[i].cookingTimeVariation &&programData.data.inputValue[i].cookingTimeVariation.quantityTime?programData.data.inputValue[i].cookingTimeVariation.quantityTime :
                        inputValue &&
                        inputValue[i] &&
                        inputValue[i].cookingTimeVariation &&
                        inputValue[i].cookingTimeVariation.quantityTime
                      }
                      onChange={(e) => {
                        const list = [...inputValue];
                        if (!list[i]) list.push({ cookingTimeVariation: {} });
                        if (!list[i].cookingTimeVariation)
                          list[i].cookingTimeVariation = {};
                        list[i].cookingTimeVariation.quantityTime =
                          e.target.value;
                        setInputValue(list);
                        dispatch({
                          type: PROGRAMFORM_UPDATE,
                          payload: { inputValue: list },
                        });
                      }}
                    />
                  </Form.Field>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="label-column">
            <label className="heading-lable">Upload doneness image</label>
          </div>
          {selectedOutcome.map((x, i) => {
            return (
              <div className="input-column">
                <Form.Field
                  error={
                    !(inputValue[i] && inputValue[i].image) && !isValid
                      ? 'error'
                      : ''
                  }
                >
                  <div className="upload-images">
                    <Dropzone
                      accept="image/*"
                      onDrop={(acceptedFiles) => {
                        getUploadFiles(acceptedFiles, i);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="upload">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {inputValue[i] && inputValue[i].image ? (
                              <Item.Image
                                size="tiny"
                                src={inputValue[i].image.value}
                              />
                            ) : (
                              <span>
                                <Image src={upload} />
                                Click here or
                                <br />
                                Drag and drop file here
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </Form.Field>
                <Image
                  src={trash}
                  className="m-auto"
                  onClick={() => removeImage(i)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default OvenOperationsForm;
