import { put, call, takeLatest } from 'redux-saga/effects';
import userManagementApi from '../../api/userManagementApi';
import { USER_LISTING_REQUESTED } from '../../constants/actions';
import {
  fetchuserFailure,
  fetchuserSuccessful,
} from '../actions/userManagementAction';

function* fetchUser(action) {
  const { response, error } = yield call(
    userManagementApi.fetchUser,
    action.payload
  );
  if (response) {
    yield put(fetchuserSuccessful(response));
  } else {
    yield put(fetchuserFailure(error));
  }
}

export default function* userManagementSaga() {
  yield takeLatest(USER_LISTING_REQUESTED, fetchUser);
}
