/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, Component } from 'react';
import {
  Accordion,
  Button,
  Form,
  Header,
  Icon,
  Image,
  Input,
  Item,
  List,
  // Popup,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import trash from '../../../assets/images/trash.svg';
import upload from '../../../assets/images/upload-file.svg';
import placeholderImg from '../../../assets/images/empty-placeholder.png';
import { fetchFilesRequested } from '../../../state/actions/fileActions';
import filesApi from '../../../api/filesApi';
import CustomFunctions from '../../../utils/customFunctions';
import { programFormUpdate } from '../../../state/actions/programAction';

const defaultFilters = {
  type: 'instructionIcon',
};

class UserInstructionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeIndex: 0,
      imageVideo: [],
      fileBlobUrl: '',
      instruction: '',
      refUrl: '',
      instructionArr: [],
      selectedIconUrl: '',
      selectedIconId: '',
      urlError: false,
      isValid: true,
    };
    this.fetchData(defaultFilters);
  }

  fetchData = (filters) => {
    this.props.fetchFiles(filters);
  };

  // handleClick = (e, titleProps) => {
  //   const { index } = titleProps;
  //   const { activeIndex } = this.state;
  //   const newIndex = activeIndex === index ? -1 : index;
  //   this.setState({ activeIndex: newIndex });
  // };

  onDrop = (acceptedFiles) => {
    this.setState({ imageVideo: acceptedFiles });
    this.uploadFile(acceptedFiles[0]);
  };

  uploadFile = async (file) => {
    const queryParam = {
      type: 'general',
    };
    const formData = new FormData();
    formData.append('file', file);
    const filesResponse = await filesApi.postFiles(queryParam, formData);
    if (filesResponse.error) {
      return;
    }
    const filesData = {};
    if (
      filesResponse &&
      filesResponse.response &&
      filesResponse.response.data
    ) {
      filesData.id = filesResponse.response.data._id;
      filesData.value = filesResponse.response.data.url;
    }
    this.setState({
      fileBlobUrl: filesData,
    });
  };

  validate = () => {
    const { instruction, refUrl, selectedIconId } = this.state;
    let isValid = true;
    let urlError = false;
    if (CustomFunctions.checkIfEmpty(instruction)) {
      isValid = false;
    }
    if (CustomFunctions.checkIfEmpty(selectedIconId)) {
      isValid = false;
    }
    if (
      !CustomFunctions.checkIfEmpty(refUrl) &&
      !CustomFunctions.validateUrl(refUrl)
    ) {
      urlError = true;
      isValid = false;
    }
    this.setState({ isValid, urlError });
    return isValid;
  };

  onClickAddInstruction = async () => {
    const valid = await this.validate();
    const { postFormData, program } = this.props;
    const programsData =
      program && program.data && program.data.instructionArr
        ? program.data.instructionArr
        : [];
    if (programsData) {
      this.setState({ instructionArr: programsData });
    }
    if (valid) {
      const {
        fileBlobUrl,
        instruction,
        refUrl,
        instructionArr,
        selectedIconUrl,
        selectedIconId,
      } = this.state;
      instructionArr.push({
        fileBlobUrl,
        instruction,
        refUrl,
        selectedIconUrl,
        selectedIconId,
      });
      this.setState({ instructionArr });
      postFormData({ instructionArr });
      this.onClickCancel();
    }
  };

  onClickCancel = () => {
    this.setState({
      imageVideo: [],
      fileBlobUrl: '',
      instruction: '',
      refUrl: '',
      selectedIconUrl: '',
      selectedIconId: '',
      urlError: false,
      isValid: true,
    });
  };

  onClickDeleteInstruction = (index) => {
    const { instructionArr } = this.state;
    const { postFormData } = this.props;
    instructionArr.splice(index, 1);
    this.setState({ instructionArr });
    postFormData({ instructionArr });
  };

  render() {
    const {
      // activeIndex,
      imageVideo,
      fileBlobUrl,
      instruction,
      refUrl,
      // selectedIconUrl,
      selectedIconId,
      // instructionArr,
      isValid,
      urlError,
    } = this.state;
    const { files, postFormData, program } = this.props;
    const programData =
      program && program.data && program.data.instructionArr
        ? program.data.instructionArr
        : [];
    return (
      <>
        <Item.Group className="user-instruction">
          {programData &&
            programData.length > 0 &&
            programData.map((item, index) => (
              <Item key={`${item.fileBlobUrl}-instruction`}>
                <Item.Content
                  verticalAlign="middle"
                  className="d-flex align-center"
                >
                  {item.fileBlobUrl ? (
                    <Item.Content>
                      <Item.Image size="tiny" src={item.fileBlobUrl.value} />
                    </Item.Content>
                  ) : null}
                  {item.instruction}
                </Item.Content>
                <Image
                  src={trash}
                  onClick={() => {
                    this.onClickDeleteInstruction(index);
                  }}
                />
              </Item>
            ))}
        </Item.Group>
        <Segment className="no-shadow">
          <Form>
            <Form.Field error={!instruction && !isValid ? 'error' : ''}>
              <label>
                Instruction
                {/* <Popup
                  trigger={<Icon name="info circle" />}
                  content="Hello. This is a popup"
                  inverted
                /> */}
              </label>
              <TextArea
                placeholder="Enter instruction"
                value={instruction}
                onChange={(e) => {
                  this.setState({ instruction: e.target.value });
                  postFormData({ instruction: e.target.value });
                }}
              />
            </Form.Field>
            <Form.Field error={urlError ? 'error' : ''}>
              <label>
                Reference URL
                {/* <Popup
                  trigger={<Icon name="info circle" />}
                  content="Hello. This is a popup"
                  inverted
                /> */}
              </label>
              <Input
                icon={<Icon name="linkify" link />}
                value={refUrl}
                onChange={(e) => {
                  this.setState({ refUrl: e.target.value });
                }}
                // className={error.refUrl ? 'error' : ''}
                placeholder="Enter reference url"
              />
            </Form.Field>
            <Accordion fluid styled className="no-shadow user-instruction">
              <Accordion.Title
                className="no-border"
                active
                index={0}
              // onClick={this.handleClick}
              >
                {/* <Icon name="dropdown" className="pull-right" /> */}
                {/* <Label>Select Icons</Label> */}
                Select Icons
              </Accordion.Title>
              <Accordion.Content active>
                <List horizontal className="user-icon-list">
                  {files &&
                    files.data &&
                    files.data.length > 0 &&
                    files.data.map((item) => (
                      <List.Item
                        className={`${selectedIconId === item._id ? 'selected' : ''
                          } pointer`}
                      >
                        <Image src={placeholderImg} />
                        <span>
                          <Image
                            key={`${item._id}`}
                            src={item.url}
                            onClick={() => {
                              this.setState({
                                selectedIconUrl: item.url,
                                selectedIconId: item._id,
                              });
                            }}
                          />
                        </span>
                      </List.Item>
                    ))}
                </List>
              </Accordion.Content>
              <Header as="h5" className="text-center">
                OR
              </Header>
              <Accordion.Title
                className="no-border"
                active
                index={1}
              // onClick={this.handleClick}
              >
                {/* <Icon name="dropdown" className="pull-right" /> */}
                Upload image/video
              </Accordion.Title>
              <Accordion.Content active>
                <Form.Field>
                  <div className="upload-images no-border">
                    <Dropzone
                      onDrop={this.onDrop}
                      accept="image/*, video/*"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        // eslint-disable-next-line
                        <div {...getRootProps()} className="d-flex flex-column">
                          {/* eslint-disable-next-line */}
                          <input
                            {...getInputProps()}
                          // className={error.fileBlobUrl ? 'error' : ''}
                          />
                          {imageVideo && imageVideo.length ? (
                            <span className="selected-images">
                              {imageVideo[0].name}
                            </span>
                          ) : null}
                          {fileBlobUrl ? (
                            <Item.Image size="tiny" src={fileBlobUrl.value} />
                          ) : (
                              <span>
                                <Image src={upload} />
                              Click here
                                <br />
                              or
                                <br />
                              Drag and drop file here
                              </span>
                            )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </Form.Field>
              </Accordion.Content>
            </Accordion>
            {!selectedIconId && !isValid ? (
              <p className="error-note position-relative text-center">
                Please select icon
              </p>
            ) : (
                ''
              )}
            <div className="button-group mt-35 mb-50">
              <Button
                secondary
                onClick={(e) => {
                  e.preventDefault();
                  this.onClickAddInstruction(e);
                }}
              >
                Add
              </Button>
              <Button
                primary
                onClick={(e) => {
                  e.preventDefault();
                  this.onClickCancel(e);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Segment>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    files: state.files.files,
    program: state.program.program,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFiles: (filters) => dispatch(fetchFilesRequested(filters)),
    postFormData: (data) => dispatch(programFormUpdate(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInstructionForm);
