import {
  PROGRAMS_FAILURE,
  PROGRAMS_REQUESTED,
  PROGRAMS_SUCCESSFUL,
} from '../../constants/actions';

export const fetchProgramsRequested = (data) => {
  return {
    type: PROGRAMS_REQUESTED,
    payload: data,
  };
};

export const fetchProgramsSuccessful = (data) => {
  return {
    type: PROGRAMS_SUCCESSFUL,
    payload: data,
  };
};

export const fetchProgramsFailure = (data) => {
  return {
    type: PROGRAMS_FAILURE,
    payload: data,
  };
};
