import React from 'react';
import { Label, Icon } from 'semantic-ui-react';

export default function LabelWithIcons(props) {
  const { name, onClick, text, classname } = props;
  return (
    <>
      <Label className={`${classname} delete-label`}>
        {text}
        <Icon
          name={name}
          onClick={() => {
            if (onClick) onClick();
          }}
        />
      </Label>
    </>
  );
}
