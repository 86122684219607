/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  // Image,
  List,
  Table,
  Message,
  Checkbox,
} from 'semantic-ui-react';
import { fetchModesRequested } from '../../../state/actions/modesActions';
import { fetchFilesRequested } from '../../../state/actions/fileActions';
import modesApi from '../../../api/modesApi';
import CustomFunctions from '../../../utils/customFunctions';
import { getLocalStorageValue } from '../../../utils/sharedFunctions';

const defaultFilters = {
  type: 'modeIcon',
};

class Modes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modeKey: '',
      modeName: '',
      modeBit: '',
      modeKeyError: '',
      modeNameError: '',
      modeBitError: '',
      selectedImageError: '',
      formError: false,
      isFailed: false,
      isSuccess: false,
      formSuccess: '',
      selectedImage: null,
      modeFastPreHeat: false,
      modeMeatProbe: false,
    };
    this.fetchData(defaultFilters);
  }

  fetchData = (filters) => {
    this.props.fetchModes(filters);
    this.props.fetchFiles(filters);
  };

  onNameChangeHandler = (event, error) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, [error]: '' });
  };

  validateForm = () => {
    const { modeKey, modeName, modeBit, selectedImage } = this.state;
    let isValid = true;
    if (CustomFunctions.checkIfEmpty(modeKey)) {
      this.setState({ modeKeyError: 'Please enter mode key' });
      isValid = false;
    }
    if (CustomFunctions.checkIfEmpty(modeName)) {
      this.setState({ modeNameError: 'Please enter mode name' });
      isValid = false;
    }
    if (CustomFunctions.checkIfEmpty(modeBit)) {
      this.setState({ modeBitError: 'Please enter mode bit' });
      isValid = false;
    }
    if (CustomFunctions.checkIfEmpty(selectedImage)) {
      this.setState({ selectedImageError: 'Please select image' });
      isValid = false;
    }
    return isValid;
  };

  handleSubmit = async () => {
    const {
      modeKey,
      modeName,
      modeBit,
      selectedImage,
      modeFastPreHeat,
      modeMeatProbe,
    } = this.state;

    if (!this.validateForm()) return;
    const payloadData = {
      key: modeKey,
      name: modeName,
      bit: modeBit,
      fastPreHeat: modeFastPreHeat,
      meatProbe: modeMeatProbe,
      image: selectedImage,
    };

    const modesResponse = await modesApi.addModes(payloadData);
    if (modesResponse.error) {
      this.setState({ formError: modesResponse.error.message, isFailed: true });
      setTimeout(() => {
        this.setState({ isFailed: false });
      }, 3000);
      return;
    }
    this.setState({
      isFailed: false,
      isSuccess: true,
      formSuccess: 'Data added successfully',
    });
    this.fetchData(defaultFilters);
    setTimeout(() => {
      this.setState({ isSuccess: false });
    }, 3000);
  };

  render() {
    const { modes, files } = this.props;
    const {
      modeKey,
      modeName,
      modeBit,
      modeFastPreHeat,
      modeMeatProbe,
      selectedImage,
      modeKeyError,
      modeNameError,
      modeBitError,
      selectedImageError,
      formError,
      isFailed,
      formSuccess,
      isSuccess,
    } = this.state;
    const role = getLocalStorageValue('role');

    return (
      <Grid className="mlr-0">
        <Grid.Column width={4} className="category-left-side p-0">
          <div className="inner-wrapper">
            <Form>
              <Form.Field>
                <label htmlFor="modeKey">Mode Key</label>
                <input
                  name="modeKey"
                  type="text"
                  value={modeKey}
                  placeholder="Enter mode key"
                  onChange={(e) => this.onNameChangeHandler(e, 'modeKeyError')}
                  disabled={role !== 'admin'}
                />
                <p className="error-note text-center">{modeKeyError}</p>
              </Form.Field>
              <Form.Field>
                <label htmlFor="modeName">Mode Name</label>
                <input
                  name="modeName"
                  type="text"
                  value={modeName}
                  placeholder="Enter mode name"
                  onChange={(e) => this.onNameChangeHandler(e, 'modeNameError')}
                  disabled={role !== 'admin'}
                />
                <p className="error-note text-center">{modeNameError}</p>
              </Form.Field>
              <Form.Field>
                <label htmlFor="mode">Mode</label>
                <Form.Group inline>
                  <Form.Field>
                    <Checkbox
                      label="FastPreHeat"
                      value={modeFastPreHeat}
                      id="modeFastPreHeat"
                      name="modeFastPreHeat"
                      onChange={(e) => {
                        this.onNameChangeHandler(e);
                        this.setState({ modeFastPreHeat: !modeFastPreHeat });
                      }}
                      checked={modeFastPreHeat}
                      disabled={role !== 'admin'}
                    />
                  </Form.Field>
                  <Form.Field>
                    {/* <input
                      type="checkbox"
                      value={modeMeatProbe}
                      id="modeMeatProbe"
                      name="modeMeatProbe"
                      onChange={(e) => {
                        this.onNameChangeHandler(e);
                        this.setState({ modeMeatProbe: !modeMeatProbe });
                      }}
                      checked={modeMeatProbe}
                    /> */}
                    <Checkbox
                      label="MeatProbe"
                      value={modeMeatProbe}
                      id="modeMeatProbe"
                      name="modeMeatProbe"
                      onChange={(e) => {
                        this.onNameChangeHandler(e);
                        this.setState({ modeMeatProbe: !modeMeatProbe });
                      }}
                      checked={modeMeatProbe}
                      disabled={role !== 'admin'}
                    />
                  </Form.Field>
                </Form.Group>
              </Form.Field>
              <Form.Field>
                <label htmlFor="modeBit">Mode Bit</label>
                <input
                  name="modeBit"
                  type="number"
                  min="0"
                  value={modeBit}
                  placeholder="Enter mode bit"
                  onChange={(e) => this.onNameChangeHandler(e, 'modeBitError')}
                  disabled={role !== 'admin'}
                />
                <p className="error-note text-center">{modeBitError}</p>
              </Form.Field>

              <Divider hidden />
              <label>Select Icon</label>
              <List as="ul" className="category-icon-list mt-15">
                {files &&
                  files.data &&
                  files.data.map((file) => (
                    <List.Item as="li">
                      <input
                        type="checkbox"
                        name={file._id}
                        onChange={() => {
                          this.setState({
                            selectedImage: file._id,
                            selectedImageError: '',
                          });
                        }}
                        checked={selectedImage === file._id}
                        id={`ci${file._id}`}
                        disabled={role !== 'admin'}
                      />
                      <label for={`ci${file._id}`} className="checked-icon">
                        {/* <Image src={file.url} /> */}
                        <div
                          style={{ backgroundImage: `url(${file.url})` }}
                          className="category-icon-wrap"
                        />
                      </label>
                    </List.Item>
                  ))}
                <List.Item as="li" className="full-width error-note">
                  {selectedImageError}
                </List.Item>
              </List>

              <div className="button-wrapper d-flex justify-center mt-70">
                <Button
                  icon
                  labelPosition="left"
                  secondary
                  onClick={() => this.handleSubmit()}
                  disabled={role !== 'admin'}
                >
                  <Icon name="add" />
                  Add Modes
                </Button>
              </div>
            </Form>
            {isFailed && (
              <Message attached="bottom" error className="text-center">
                <p className="error-note">{formError}</p>
              </Message>
            )}
            {isSuccess && (
              <Message attached="bottom" success className="text-center">
                <p className="success-note">{formSuccess}</p>
              </Message>
            )}
          </div>
        </Grid.Column>
        <Grid.Column width={12} className="pr-0">
          <Table striped basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>Mode icon</Table.HeaderCell>
                <Table.HeaderCell width={5}>Mode key</Table.HeaderCell>
                <Table.HeaderCell width={3}>Mode name</Table.HeaderCell>
                <Table.HeaderCell width={1}>FastPreHeat</Table.HeaderCell>
                <Table.HeaderCell width={1}>MeatProbe</Table.HeaderCell>
                <Table.HeaderCell width={2}>Mode bit</Table.HeaderCell>

                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {modes && modes.data ? (
                modes.data.map((mod) => (
                  <Table.Row>
                    <Table.Cell>
                      <div
                        style={{
                          backgroundImage: `url(${
                            mod.image ? mod.image.url : ''
                          })`,
                        }}
                        className="category-icon-wrap"
                      />
                    </Table.Cell>
                    <Table.Cell>{mod.key}</Table.Cell>
                    <Table.Cell>{mod.name}</Table.Cell>
                    <Table.Cell>
                      {mod.fastPreHeat ? 'true' : 'false'}
                    </Table.Cell>
                    <Table.Cell>{mod.meatProbe ? 'true' : 'false'}</Table.Cell>
                    <Table.Cell>{mod.bit}</Table.Cell>
                    <Table.Cell textAlign="right"></Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="8" textAlign="center">
                    <div className="no-data mt-125">No records found</div>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    );
  }
}

// At the end of the file
const mapStateToProps = (state) => {
  return {
    modes: state.modes.modes,
    files: state.files.files,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchModes: (filters) => dispatch(fetchModesRequested(filters)),
    fetchFiles: (filters) => dispatch(fetchFilesRequested(filters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modes);
