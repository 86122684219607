import axios from 'axios';
import { normalize } from 'normalizr';
import { filtersList } from '../state/schemas';
import ApiUtils from './apiUtils';
import { getLocalStorageValue } from '../utils/sharedFunctions';

const fetchFilter = async () => {
  try {
    const response = await ApiUtils.get(`api/filters`);

    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, filtersList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const addFilter = (filter) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/filters`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .post(tokenUrl, filter, {
      headers: {
        source: 'web',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      // eslint-disable-next-line no-underscore-dangle
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

const deleteFilter = async (id) => {
  try {
    const response = await ApiUtils.del(`api/filters/${id}`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, filtersList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchFilter,
  addFilter,
  deleteFilter,
};
