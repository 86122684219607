import { combineReducers } from 'redux';
import {
  DASHBOARD_SUMMARY_REQUESTED,
  DASHBOARD_SUMMARY_SUCCESSFUL,
  DASHBOARD_SUMMARY_FAILURE,
} from '../../constants/actions';

const dashboardSummaryReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_SUMMARY_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case DASHBOARD_SUMMARY_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case DASHBOARD_SUMMARY_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  dashboardSummary: dashboardSummaryReducer,
});
