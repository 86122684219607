import { put, call, takeLatest } from 'redux-saga/effects';
import programUsersApi from '../../api/programUsersApi';
import { PROGRAM_USER_REQUESTED } from '../../constants/actions';
import {
  programUsersFailure,
  programUsersSuccessful,
} from '../actions/programUsersAction';

function* fetchProgramUsers(action) {
  const { response, error } = yield call(
    programUsersApi.fetchProgramVariationUsers,
    action.payload
  );
  if (response) {
    yield put(programUsersSuccessful(response));
  } else {
    yield put(programUsersFailure(error));
  }
}

export default function* programUsersSaga() {
  yield takeLatest(PROGRAM_USER_REQUESTED, fetchProgramUsers);
}
