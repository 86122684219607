import axios from 'axios';
import { normalize } from 'normalizr';
import { regionList } from '../state/schemas';
import ApiUtils from './apiUtils';
import { getLocalStorageValue } from '../utils/sharedFunctions';

const fetchRegion = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);

  try {
    const response = await ApiUtils.get(`api/regions?${queryString}`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, regionList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const addRegions = (region) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/regions`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .post(tokenUrl, region, {
      headers: {
        source: 'web',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      // eslint-disable-next-line no-underscore-dangle
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

const deleteRegions = async (id) => {
  // const queryString = ApiUtils.mapObjectToQueryString(param);

  try {
    const response = await ApiUtils.del(`api/regions/${id}`);
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, regionList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const updateRegions = (region, id) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/regions/${id}`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .put(tokenUrl, region, {
      headers: {
        source: 'web',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      // eslint-disable-next-line no-underscore-dangle
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

const addCountries = (country, id) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/regions/${id}/countries`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .post(tokenUrl, country, {
      headers: {
        source: 'web',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      // eslint-disable-next-line no-underscore-dangle
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

const deleteCountry = async (regionId, countryId) => {
  // const queryString = ApiUtils.mapObjectToQueryString(param);
  try {
    const response = await ApiUtils.del(
      `api/regions/${regionId}/countries/${countryId}`
    );
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, regionList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};
const updateCountry = (region, regionId, countryId) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/regions/${regionId}/countries/${countryId}`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .put(tokenUrl, region, {
      headers: {
        source: 'web',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      // eslint-disable-next-line no-underscore-dangle
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

export default {
  fetchRegion,
  addRegions,
  deleteRegions,
  addCountries,
  updateRegions,
  deleteCountry,
  updateCountry,
};
