import React, { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import LabelWithIcons from './LabelWithIcons';

export default function MultiSelectDropdown(props) {
  const { labelValue, placeholder, defaultSelectedOptions, disabled } = props;
  const [selectedOptions, setSelectedOptions] = useState(
    defaultSelectedOptions
  );

  const getOptions = () => {
    const { options } = props;
    if (!options) return [];
    return options;
  };

  const generateSelectedOptionList = () => {
    const { options, onChange } = props;
    const list = options.filter((op) => {
      return selectedOptions.includes(op.value);
    });
    if (onChange) onChange(list);
  };

  const onChangeHandler = (data) => {
    setSelectedOptions([...data.value]);
  };

  const onRemoveHandler = (index) => {
    const options = JSON.parse(JSON.stringify(selectedOptions));
    options.splice(index, 1);
    setSelectedOptions(options);
  };

  useEffect(generateSelectedOptionList, [selectedOptions]); // eslint-disable-line

  return (
    <>
      <label htmlFor={labelValue}>{labelValue}</label>
      <Dropdown
        placeholder={placeholder}
        options={getOptions()}
        fluid
        multiple
        search
        selection
        onChange={(e, data) => {
          onChangeHandler(data);
        }}
        value={defaultSelectedOptions.map((op) => op.value)}
        className="large"
        disabled={disabled}
      />
      <div className="multi-selected-label">
        {defaultSelectedOptions.map((ol, index) => {
          return (
            <LabelWithIcons
              key={`${ol.key}`}
              name="delete"
              onClick={() => {
                onRemoveHandler(index);
              }}
              text={ol.text}
            />
          );
        })}
      </div>
    </>
  );
}
