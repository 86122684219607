// Actions for Login
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// Actions for validating tokens
export const VALIDATE_TOKEN_REQUESTED = 'VALIDATE_TOKEN_REQUESTED';
export const VALIDATE_TOKEN_SUCCESSFULL = 'VALIDATE_TOKEN_SUCCESSFULL';
export const VALIDATE_TOKEN_FAILURE = 'VALIDATE_TOKEN_FAILURE';

// Actions for Logout
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_SUCCESSFULL = 'LOGOUT_SUCCESSFULL';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// Actions for Dashboard programs
export const DASHBOARD_PROGRAMS_REQUESTED = 'DASHBOARD_PROGRAMS_REQUESTED';
export const DASHBOARD_PROGRAMS_SUCCESSFUL = 'DASHBOARD_PROGRAMS_SUCCESSFUL';
export const DASHBOARD_PROGRAMS_FAILURE = 'DASHBOARD_PROGRAMS_FAILURE';

// Actions for User Management
export const USER_LISTING_REQUESTED = 'USER_LISTING_REQUESTED';
export const USER_LISTING_SUCCESSFUL = 'USER_LISTING_SUCCESSFUL';
export const USER_LISTING_FAILURE = 'USER_LISTING_FAILURE';

// Actions for Category Management
export const CATEGORY_LISTING_REQUESTED = 'CATEGORY_LISTING_REQUESTED';
export const CATEGORY_LISTING_SUCCESSFUL = 'CATEGORY_LISTING_SUCCESSFUL';
export const CATEGORY_LISTING_FAILURE = 'CATEGORY_LISTING_FAILURE';

// Actions for Brands
export const BRANDS_REQUESTED = 'BRANDS_REQUESTED';
export const BRANDS_SUCCESSFUL = 'BRANDS_SUCCESSFUL';
export const BRANDS_FAILURE = 'BRANDS_FAILURE';

// Actions for Regions
export const REGION_LISTING_REQUESTED = 'REGION_LISTING_REQUESTED';
export const REGION_LISTING_SUCCESSFUL = 'REGION_LISTING_SUCCESSFUL';
export const REGION_LISTING_FAILURE = 'REGION_LISTING_FAILURE';

// Actions for Modes
export const MODES_LISTING_REQUESTED = 'MODES_LISTING_REQUESTED';
export const MODES_LISTING_SUCCESSFUL = 'MODES_LISTING_SUCCESSFUL';
export const MODES_LISTING_FAILURE = 'MODES_LISTING_FAILURE';

// Actions for Filters
export const FILTER_LISTING_REQUESTED = 'FILTER_LISTING_REQUESTED';
export const FILTER_LISTING_SUCCESSFUL = 'FILTER_LISTING_SUCCESSFUL';
export const FILTER_LISTING_FAILURE = 'FILTER_LISTING_FAILURE';

// Actions for Files
export const FILES_LISTING_REQUESTED = 'FILES_LISTING_REQUESTED';
export const FILES_LISTING_SUCCESSFUL = 'FILES_LISTING_SUCCESSFUL';
export const FILES_LISTING_FAILURE = 'FILES_LISTING_FAILURE';

// Actions for tags
export const TAGS_LISTING_REQUESTED = 'TAGS_LISTING_REQUESTED';
export const TAGS_LISTING_SUCCESSFUL = 'TAGS_LISTING_SUCCESSFUL';
export const TAGS_LISTING_FAILURE = 'TAGS_LISTING_FAILURE';

// Actions for Program
export const PROGRAM_REQUESTED = 'PROGRAM_REQUESTED';
export const PROGRAM_SUCCESSFUL = 'PROGRAM_SUCCESSFUL';
export const PROGRAM_FAILURE = 'PROGRAM_FAILURE';

// Actions for programForm
export const PROGRAMFORM_REQUESTED = 'PROGRAMFORM_REQUESTED';
export const PROGRAMFORM_UPDATE = 'PROGRAMFORM_UPDATE';

// ACtions for Programs
export const PROGRAMS_REQUESTED = 'PROGRAMS_REQUESTED';
export const PROGRAMS_SUCCESSFUL = 'PROGRAMS_SUCCESSFUL';
export const PROGRAMS_FAILURE = 'PROGRAMS_FAILURE';

// Actions for ProgramUsers
export const PROGRAM_USER_REQUESTED = 'PROGRAM_USER_REQUESTED';
export const PROGRAM_USER_SUCCESSFUL = 'PROGRAM_USER_SUCCESSFUL';
export const PROGRAM_USER_FAILURE = 'PROGRAM_USER_FAILURE';

//  Action for userFeedbackUpdate
export const USER_FEEDBACKUPDATE = 'USER_FEEDBACKUPDATE';
export const PROGRAM_USERFEEDBACKUPDATE = 'PROGRAM_USERFEEDBACKUPDATE';
export const SESSION_USERFEEDBACKUPDATE = 'SESSION_USERFEEDBACKUPDATE';
export const USER_STATUS = 'USERSTATUS';

// Actions for Dashboard summary
export const DASHBOARD_SUMMARY_REQUESTED = 'DASHBOARD_SUMMARY_REQUESTED';
export const DASHBOARD_SUMMARY_SUCCESSFUL = 'DASHBOARD_SUMMARY_SUCCESSFUL';
export const DASHBOARD_SUMMARY_FAILURE = 'DASHBOARD_SUMMARY_FAILURE';

// Actions for Dashboard programs
export const DASHBOARD_CHARTDATA_REQUESTED = 'DASHBOARD_CHARTDATA_REQUESTED';
export const DASHBOARD_CHARTDATA_SUCCESSFUL = 'DASHBOARD_CHARTDATA_SUCCESSFUL';
export const DASHBOARD_CHARTDATA_FAILURE = 'DASHBOARD_CHARTDATA_FAILURE';
