import { put, call, takeLatest } from 'redux-saga/effects';
import brandsApi from '../../api/brandsApi';
import { BRANDS_REQUESTED } from '../../constants/actions';
import {
  fetchBrandsFailure,
  fetchBrandsSuccessful,
} from '../actions/brandsActions';

function* fetchBrands(action) {
  const { response, error } = yield call(brandsApi.fetchBrands, action.payload);
  if (response) {
    yield put(fetchBrandsSuccessful(response));
  } else {
    yield put(fetchBrandsFailure(error));
  }
}

export default function* brandsSagas() {
  yield takeLatest(BRANDS_REQUESTED, fetchBrands);
}
