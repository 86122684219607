import React, { useState } from 'react';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import CustomFunctions from '../../../utils/customFunctions';
import usersApi from '../../../api/addUsersApi';

const AddUser = (props) => {
  const initialState = {
    uname: '',
    email: '',
  };
  const [userData, setUserData] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [isFailed, setIsFailed] = useState(false);
  const [formError, setFormError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [formSuccess, setFormSuccess] = useState('');

  const { isOpen, closeModal, userType } = props;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((inputValues) => {
      return { ...inputValues, [name]: value };
    });
  };

  const validateForm = () => {
    let isValid = true;
    const inputErrors = {};
    if (CustomFunctions.checkIfEmpty(userData.uname)) {
      inputErrors.uname = 'Please enter your name';
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(userData.email)) {
      inputErrors.email = 'Please enter email';
      isValid = false;
    } else if (!CustomFunctions.validateEmail(userData.email)) {
      inputErrors.email = 'Invalid email';
      isValid = false;
    }
    setErrors(inputErrors);
    return isValid;
  };

  const submitForm = async () => {
    if (!validateForm()) return;
    const payload = {
      data: {
        email: userData.email,
        name: userData.uname,
        type: userType,
      },
    };
    const userResponse = await usersApi.addUsers(payload);
    if (userResponse.error) {
      setFormError(userResponse.error.error.message);
      setIsFailed(true);
      return;
    }
    setIsFailed(false);
    setIsSuccess(true);
    setFormSuccess('User Added Successfully... ');
    setErrors(initialState);
    setUserData(initialState);
    setTimeout(() => {
      setIsSuccess(false);
    }, 3000);
    // give api call to listings
  };

  const closeModalCallback = () => {
    setErrors(initialState);
    setIsSuccess(false);
    setUserData(initialState);
    closeModal();
  };

  return (
    <Modal
      closeIcon
      className="custom-modal"
      onClose={() => closeModalCallback()}
      open={isOpen}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Add User</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label htmlFor="username">User Name</label>
              <input
                name="uname"
                value={userData.uname}
                onChange={(e) => handleInputChange(e)}
                type="text"
              />
              {errors.uname && (
                <p className="error-note text-center">{errors.uname}</p>
              )}
            </Form.Field>
            <Form.Field required>
              <label htmlFor="email">Email</label>
              <input
                name="email"
                value={userData.email}
                onChange={(e) => handleInputChange(e)}
                type="email"
              />
              {errors.email && (
                <p className="error-note text-center">{errors.email}</p>
              )}
            </Form.Field>
            <div className="button-wrapper text-center">
              <Button type="submit" onClick={() => submitForm()}>
                Add User
              </Button>
            </div>
          </Form>
        </Modal.Description>
      </Modal.Content>
      {isFailed && (
        <Message attached="bottom" error className="text-center">
          <p className="error-note">{formError}</p>
        </Message>
      )}
      {isSuccess && (
        <Message attached="bottom" success className="text-center">
          <p className="success-note">{formSuccess}</p>
        </Message>
      )}
    </Modal>
  );
};

export default AddUser;
