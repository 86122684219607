import { put, call, takeLatest } from 'redux-saga/effects';
import programsApi from '../../api/programsApi';
import { PROGRAM_REQUESTED } from '../../constants/actions';
import { programFailure, programSuccessful } from '../actions/programAction';

function* fetchProgram(action) {
  const { response, error } = yield call(
    programsApi.fetchProgram,
    action.payload
  );
  if (response) {
    yield put(programSuccessful(response));
  } else {
    yield put(programFailure(error));
  }
}

export default function* programSaga() {
  yield takeLatest(PROGRAM_REQUESTED, fetchProgram);
}
