import React, { Component } from 'react';

// TODO: Improve the component
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch() {
    // Display fallback UI
    this.setState({
      hasError: true,
    });
    // You can also log the error to an error reporting service
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return <h1> Something went wrong. </h1>;
    }
    return children;
  }
}

export default ErrorBoundary;
