import { put, call, takeLatest } from 'redux-saga/effects';
import filtersApi from '../../api/filtersApi';
import { FILTER_LISTING_REQUESTED } from '../../constants/actions';
import {
  fetchFilterFailure,
  fetchFilterSuccessful,
} from '../actions/filterAction';

function* fetchFilter(action) {
  const { response, error } = yield call(
    filtersApi.fetchFilter,
    action.payload
  );
  if (response) {
    yield put(fetchFilterSuccessful(response));
  } else {
    yield put(fetchFilterFailure(error));
  }
}

export default function* filterSagas() {
  yield takeLatest(FILTER_LISTING_REQUESTED, fetchFilter);
}
