/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  Image,
  List,
  Table,
} from 'semantic-ui-react';
import moment from 'moment';
import { fetchCategoryRequested } from '../../../state/actions/categoryActions';
import trash from '../../../assets/images/trash-blue.svg';
// import edit from '../../../assets/images/edit.svg';
import { fetchFilesRequested } from '../../../state/actions/fileActions';
import categoryApi from '../../../api/categoryApi';
import CustomFunctions from '../../../utils/customFunctions';
import { getLocalStorageValue } from '../../../utils/sharedFunctions';

const defaultIcons = {
  type: 'category',
};
class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: '',
      categoryName: '',
      nameError: '',
      fileError: '',
      deleteError: '',
    };
    this.fetchData();
    this.fetchIcon(defaultIcons);
  }

  fetchData = () => {
    this.props.fetchCategory();
  };

  fetchIcon = (icon) => {
    this.props.fetchFile(icon);
  };

  handleInputChange = (event) => {
    this.setState({ categoryName: event.target.value, nameError: '' });
  };

  handleCheckboxChange = (event) => {
    this.setState({ selectedFile: event.target.name, fileError: '' });
  };

  validation = () => {
    const { categoryName, selectedFile } = this.state;
    let isValid = true;

    if (CustomFunctions.checkIfEmpty(categoryName)) {
      this.setState({ nameError: 'Please enter the category name' });
      isValid = false;
    } else if (!categoryName.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
      this.setState({ nameError: 'Only letters are expected' });
      isValid = false;
    } else if (categoryName.length > 28) {
      this.setState({ nameError: 'Maximum length should be 28 characters' });
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(selectedFile)) {
      this.setState({ fileError: 'Please select the category icon' });
      isValid = false;
    }
    return isValid;
  };

  handleSubmit = async () => {
    if (!this.validation()) return;
    const payload = {
      name: this.state.categoryName,
      image: this.state.selectedFile,
    };
    const response = await categoryApi.addCategory(payload);

    if (response.error) {
      this.setState({ nameError: response.error.message });
      return;
    }
    this.setState({ selectedFile: '', categoryName: '' });
    this.props.fetchCategory();
    this.fetchIcon(defaultIcons);
  };

  deleteCategory = async (category) => {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    const categoryId = category._id;
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete category?')) {
      const response = await categoryApi.deleteCategory(categoryId);
      if (response.error) {
        this.setState({ deleteError: response.error.message });
        return;
      }
      this.props.fetchCategory();
      this.fetchIcon(defaultIcons);
    }
  };

  render() {
    const { files, categories } = this.props;
    const {
      selectedFile,
      nameError,
      fileError,
      categoryName,
      deleteError,
    } = this.state;
    const role = getLocalStorageValue('role');

    return (
      <Grid className="mlr-0">
        <Grid.Column width={4} className="category-left-side p-0">
          <div className="inner-wrapper">
            <Form>
              <Form.Field>
                <label htmlFor="categoryname">Category name</label>
                <input
                  name="categoryname"
                  type="text"
                  placeholder="Enter Category Name"
                  value={categoryName}
                  onChange={this.handleInputChange}
                  disabled={role !== 'admin'}
                />
                {nameError ? <p className="error-note">{nameError}</p> : null}
              </Form.Field>
              <Divider hidden />
              <label>Select Icon</label>

              <List as="ul" className="category-icon-list mt-15">
                {files && files.data && files.data.length > 0 ? (
                  files.data.map((file, index) => (
                    <List.Item as="li">
                      <input
                        type="checkbox"
                        id={`ci${index + 1}`}
                        name={file._id}
                        checked={selectedFile === file._id}
                        onChange={this.handleCheckboxChange}
                        disabled={role !== 'admin'}
                      />
                      <label for={`ci${index + 1}`} className="checked-icon">
                        <div
                          style={{ backgroundImage: `url(${file.url})` }}
                          className="category-icon-wrap"
                        />
                      </label>
                    </List.Item>
                  ))
                ) : (
                  <List.Item as="li" className="full-width">
                    No icons available
                  </List.Item>
                )}
                {fileError ? (
                  <List.Item as="li" className="full-width error-note">
                    {fileError}
                  </List.Item>
                ) : null}
              </List>
              <div className="button-wrapper d-flex justify-center mt-70">
                <Button
                  icon
                  labelPosition="left"
                  secondary
                  onClick={this.handleSubmit}
                  disabled={role !== 'admin'}
                >
                  <Icon name="add" />
                  Add Category
                </Button>
              </div>
            </Form>
          </div>
        </Grid.Column>
        <Grid.Column width={12} className="pr-0">
          <Table striped basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>Category icon</Table.HeaderCell>
                <Table.HeaderCell width={3}>Category name</Table.HeaderCell>
                <Table.HeaderCell>Last updated</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {categories && categories.data && categories.data.length > 0 ? (
                categories.data.map((item) => (
                  <Table.Row>
                    <Table.Cell>
                      <div
                        style={{ backgroundImage: `url(${item.image.url})` }}
                        className="category-icon-wrap"
                      />
                    </Table.Cell>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>
                      {moment(item.updatedAt).format('DD MMM YYYY')}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Image.Group className="mr-10">
                        {/* <Image src={edit} /> */}
                        <Image
                          src={trash}
                          onClick={() => this.deleteCategory(item)}
                          className="pointer"
                        />
                      </Image.Group>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan="3" textAlign="center">
                    <div className="no-data mt-125">No data available</div>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          {deleteError ? <p className="error-note">{deleteError}</p> : null}
        </Grid.Column>
      </Grid>
    );
  }
}

// At the end of the file
const mapStateToProps = (state) => {
  return {
    categories: state.categories.categories,
    files: state.files.files,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategory: () => dispatch(fetchCategoryRequested()),
    fetchFile: (icon) => dispatch(fetchFilesRequested(icon)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
