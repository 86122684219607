import {
  MODES_LISTING_REQUESTED,
  MODES_LISTING_SUCCESSFUL,
  MODES_LISTING_FAILURE,
} from '../../constants/actions';

export const fetchModesRequested = (data) => {
  return {
    type: MODES_LISTING_REQUESTED,
    payload: data,
  };
};

export const fetchModesSuccessful = (data) => {
  return {
    type: MODES_LISTING_SUCCESSFUL,
    payload: data,
  };
};

export const fetchModesFailure = (data) => {
  return {
    type: MODES_LISTING_FAILURE,
    payload: data,
  };
};
