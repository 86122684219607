/* eslint-disable no-use-before-define */
import { schema } from 'normalizr';

// For single program
export const dashboardProgram = new schema.Entity('dashboardPrograms');

export const user = new schema.Entity('user');

export const category = new schema.Entity('category');

export const brands = new schema.Entity('brands');

export const region = new schema.Entity('region');

export const modes = new schema.Entity('modes');

export const filter = new schema.Entity('filter');

export const files = new schema.Entity('files');
export const tags = new schema.Entity('tags');

export const program = new schema.Entity('program');

export const programUser = new schema.Entity('programUser');

// For list of programs
export const dashboardProgramList = [dashboardProgram];

export const userList = [user];

export const categoryList = [category];

export const brandsList = [brands];

export const modesList = [modes];

// For list of regions
export const regionList = [region];

// For list of filters
export const filtersList = [filter];

// For list of files
export const filesList = [files];

export const tagsList = [tags];

export const programsList = [program];

export const programUsersList = [programUser];

export const dashboardProgramsResponse = new schema.Entity(
  'dashboardProgramsResponse',
  {
    dashboardPrograms: dashboardProgramList,
  }
);

export const usersResponse = new schema.Entity('usersResponse', {
  users: userList,
});

export const categoriesResponse = new schema.Entity('categoriesResponse', {
  categories: categoryList,
});

export const brandsResponse = new schema.Entity('brandsResponse', {
  brands: brandsList,
});

// For regionResponse
export const regionsResponse = new schema.Entity('regionsResponse', {
  regions: regionList,
});

export const modesResponse = new schema.Entity('modesResponse', {
  modes: modesList,
});
// For filtersResponse
export const filtersResponse = new schema.Entity('filtersResponse', {
  filters: filtersList,
});

// For filesResponse
export const filesResponse = new schema.Entity('filesResponse', {
  files: filesList,
});

// For tagsResponse
export const tagsResponse = new schema.Entity('tagsResponse', {
  tags: tagsList,
});

export const programsResponse = new schema.Entity('programsResponse', {
  programs: programsList,
});

export const programUsersResponse = new schema.Entity('programUsersResponse', {
  programUsers: programUsersList,
});
