import ApiUtils from './apiUtils';

const addUsers = async (param) => {
  try {
    const response = await ApiUtils.post(`api/users/`, param);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  addUsers,
};
