/* eslint-disable jsx-a11y/label-has-for */
import { React, Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  List,
  Modal,
  Segment,
  Sidebar,
  Label,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import Select from 'react-select';
import { find, get } from 'lodash';
import MainHeader from '../../ui/MainHeader';
import Navbar from '../../ui/Navbar';
import MainBreadcrumb from '../../ui/MainBreadcrumb';
import TopSectionCreateProgram from './TopSectionCreateProgram';
import UserInstructionForm from './UserInstructionForm';
import OvenOperationsForm from './OvenOperationsForm';
import { fetchregionRequested } from '../../../state/actions/regionAction';
import { fetchBrandsRequested } from '../../../state/actions/brandsActions';
import CustomFunctions from '../../../utils/customFunctions';
import {
  programFormUpdate,
  programRequested,
} from '../../../state/actions/programAction';
import programsApi from '../../../api/programsApi';

class EditProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      visible: true,
      selectedRegion: '',
      selectedCountry: '',
      selectedCavity: [],
      commonModes: [],
      cavityModes: {},
      isValid: true,
      units: {},
      setCommonModes: false,
      isTypeError: {},
      isTypeErrorForProbeTime: [],
      urlError: {
        url1: false,
        url2: false,
      },
    };
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { program, brands } = this.props;
    const programData = get(program, 'data.selectedCavity');
    const brandData = get(brands, 'data');
    const { selectedCavity, setCommonModes } = this.state;
    if (selectedCavity.length !== prevState.selectedCavity.length) {
      this.handleCommonModes();
    }
    if (get(programData, '[0]') && get(brandData, '[0]') && !setCommonModes) {
      this.renderCommonModes(brandData, programData);
    }
  }

  renderCommonModes = (brandData, programData) => {
    const cavityModes = this.getCavityandModes(brandData);
    this.setState({
      cavityModes,
      selectedCavity: programData,
      setCommonModes: true,
    });
  };

  handleCommonModes = () => {
    const count = {};
    const common = [];
    const { postFormData } = this.props;
    const { selectedCavity, cavityModes } = this.state;
    selectedCavity.map((itemId) =>
      itemId in cavityModes
        ? cavityModes[itemId].forEach((ele) => {
            if (count[ele.name]) {
              count[ele.name].count += 1;
            } else {
              count[ele.name] = {
                count: 1,
                name: ele.name,
                meatProbe: ele.meatProbe,
                key: ele.key,
              };
            }
          })
        : ''
    );
    Object.keys(count).forEach((key) => {
      if (count[key].count === selectedCavity.length) common.push(count[key]);
    });
    this.setState({ commonModes: common });
    postFormData({ commonModes: common });
  };

  fetchData = () => {
    this.props.fetchRegion();
    this.props.fetchBrands();
    this.props.fetchProgram(this.props.match.params.id, true);
  };

  getCavityandModes = (data) => {
    const cavityModes = {};
    data.forEach((element) => {
      element.cavities.forEach((cavity) => {
        cavityModes[cavity.id] = cavity.modes;
      });
    });
    return cavityModes;
  };

  listOfModes = () => {
    const { commonModes } = this.state;
    const modesOption =
      commonModes && commonModes.length > 0 ? (
        commonModes.map((item) => (
          <List.Item>
            {/* below code is required for developer */}
            {/* <Image src={} verticalAlign='middle' /> */}
            <List.Content>{item.name}</List.Content>
          </List.Item>
        ))
      ) : (
        <p>No modes</p>
      );
    return modesOption;
  };

  getbrands = (brands, programData) => {
    const { selectedCavity } = this.state;
    const cavityModes = this.getCavityandModes(brands);
    const formatGroupLabel = (data) => (
      <div>
        <span>{data.label}</span>
      </div>
    );
    const groupOption =
      brands && brands.length > 0
        ? brands.map((brand) => {
            return {
              label: brand.name,
              options:
                brand.cavities && brand.cavities.length > 0
                  ? brand.cavities.map((cavity) => {
                      return { value: cavity.id, label: cavity.name };
                    })
                  : '',
            };
          })
        : '';
    let optionArr = [];
    groupOption.forEach((val) => {
      optionArr = [...optionArr, ...val.options];
    });
    const resultArr = [];
    const cavityLabel = get(programData, 'selectedCavity') || [];
    cavityLabel.forEach((idVal) => {
      resultArr.push(find(optionArr, (o) => o.value === idVal));
    });

    const brandsOption =
      brands && brands.length > 0 ? (
        <Select
          options={groupOption}
          formatGroupLabel={formatGroupLabel}
          isMulti="true"
          value={
            programData && programData.selectedCavity
              ? resultArr
              : selectedCavity
          }
          onChange={(e) => {
            this.handleCavityChange(e, cavityModes);
          }}
        />
      ) : (
        <Label basic color="red" pointing>
          No cavities
        </Label>
      );
    return brandsOption;
  };

  getcountries = () => {
    const { regions, program } = this.props;
    const { selectedRegion } = this.state;
    if (!regions || !regions.data) return [];
    const countriesOptions = [];
    const programData = program && program.data ? program.data : 0;
    regions.data.map((region) => {
      if (
        selectedRegion === region._id ||
        (programData && programData.selectedRegion === region._id)
      ) {
        if (region.countries && region.countries.length > 0) {
          region.countries.map((country) => {
            return countriesOptions.push({
              key: country.id,
              text: country.name,
              value: country.id,
            });
          });
        }
      }
      return [];
    });
    return countriesOptions;
  };

  getregions = () => {
    const { regions } = this.props;
    if (!regions || !regions.data) return [];
    const regionsOptions = [];
    regions.data.map((region) => {
      return regionsOptions.push({
        key: region._id,
        text: region.name,
        value: region._id,
      });
    });
    return regionsOptions;
  };

  validateData = () => {
    const { data } = this.props.program;
    let isValid = true;
    const isTypeError = {};
    const isTypeErrorForProbeTime = [];
    const urlError = {};
    const { selectedRadio } = data;

    const stepCount =
      data && data.stepsCount
        ? data.stepsCount
        : data.inputValue[0].precedingCookingStep.length;
    const columnCount =
      data && data.columnCount ? data.columnCount : data.inputValue.length;

    if (CustomFunctions.checkIfEmpty(data.programTitle)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.internalTitle)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.categoryValue)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.description)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.fileBlobUrl)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.selectedRegion)) {
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.selectedCountry)) {
      isValid = false;
    }

    if (
      CustomFunctions.checkIfEmpty(data.selectedCavity) ||
      data.selectedCavity.length === 0
    ) {
      isValid = false;
    }

    if (
      !CustomFunctions.checkIfEmpty(data.refUrlOne) &&
      !CustomFunctions.validateUrl(data.refUrlOne)
    ) {
      urlError.url1 = true;
      isValid = false;
    }

    if (
      !CustomFunctions.checkIfEmpty(data.refUrlTwo) &&
      !CustomFunctions.validateUrl(data.refUrlTwo)
    ) {
      urlError.url2 = true;
      isValid = false;
    }

    if (CustomFunctions.checkIfEmpty(data.selectedbaseRadioValue)) {
      isValid = false;
    }
    if (
      !CustomFunctions.checkIfEmpty(data.selectedbaseRadioValue) &&
      !data.selectedbaseRadioValue.toString().match(/^\d+(\.\d{1,2})?$/)
    ) {
      isTypeError.selectedbaseRadioValue = true;
      isValid = false;
    }

    if (data.selectedQuantity === undefined) {
      isValid = false;
    }

    if (
      data.preheatAlert &&
      data.preheatAlert.alert === true &&
      CustomFunctions.checkIfEmpty(data.preheatAlert.value)
    ) {
      isValid = false;
    }
    if (CustomFunctions.checkIfEmpty(data.extraThickness)) {
      isValid = false;
    }

    if (
      data.extraThickness[0] &&
      !data.extraThickness[0].toString().match(/^\d+(\.\d{1,2})?$/)
    ) {
      isTypeError.extraThickness = true;
      isValid = false;
    }

    if (
      typeof data.extraQuantity[0] === 'undefined' &&
      typeof data.extraQuantity === 'object'
    ) {
      isValid = false;
    }

    if (data.inputValue && data.inputValue.length === columnCount) {
      data.inputValue.forEach((inputValue) => {
        if (CustomFunctions.checkIfEmpty(inputValue.outcome)) {
          isValid = false;
        }
        if (CustomFunctions.checkIfEmpty(inputValue.image)) {
          isValid = false;
        }

        if (inputValue.cookingTimeVariation) {
          if (
            CustomFunctions.checkIfEmpty(
              inputValue.cookingTimeVariation.thicknessTime
            )
          ) {
            isValid = false;
          } else if (
            !inputValue.cookingTimeVariation.thicknessTime
              .toString()
              .match(/^\d+(\.\d{1,2})?$/)
          ) {
            isValid = false;
          }

          if (
            CustomFunctions.checkIfEmpty(
              inputValue.cookingTimeVariation.quantityTime
            )
          ) {
            isValid = false;
          } else if (
            !inputValue.cookingTimeVariation.quantityTime
              .toString()
              .match(/^\d+(\.\d{1,2})?$/)
          ) {
            isValid = false;
          }
        } else {
          isValid = false;
        }

        if (
          inputValue.precedingCookingStep &&
          inputValue.precedingCookingStep.length === stepCount
        ) {
          inputValue.precedingCookingStep.forEach((cookingStep, i) => {
            if (
              cookingStep.temperature === null ||
              cookingStep.temperature === '' ||
              cookingStep.temperature === undefined
            ) {
              isValid = false;
            }

            if (
              CustomFunctions.checkIfEmpty(data.cookingMode[i].name) ||
              data.cookingMode.length !== stepCount
            ) {
              isValid = false;
            }

            if (selectedRadio === 'timeBased') {
              if (cookingStep.timeBased) {
                if (
                  (cookingStep.timeBased.cookingTime === undefined &&
                    cookingStep.timeBased.cookingTimeinMin === undefined) ||
                  (cookingStep.timeBased.cookingTime === '' &&
                    cookingStep.timeBased.cookingTimeinMin === '') ||
                  (cookingStep.timeBased.cookingTime === '' &&
                    cookingStep.timeBased.cookingTimeinMin === undefined) ||
                  (cookingStep.timeBased.cookingTime === undefined &&
                    cookingStep.timeBased.cookingTimeinMin === '') ||
                  (cookingStep.timeBased.cookingTime === 0 &&
                    cookingStep.timeBased.cookingTimeinMin === 0)
                ) {
                  isValid = false;
                } else if (
                  (cookingStep.timeBased.cookingTime &&
                    !cookingStep.timeBased.cookingTime
                      .toString()
                      .match(/^[1-9][0-9]*$/)) ||
                  (cookingStep.timeBased.cookingTimeinMin &&
                    !cookingStep.timeBased.cookingTimeinMin
                      .toString()
                      .match(/^[1-9][0-9]*$/)) ||
                  (cookingStep.timeBased.cookingTime &&
                    cookingStep.timeBased.cookingTimeinMin &&
                    !cookingStep.timeBased.cookingTime
                      .toString()
                      .match(/^[1-9][0-9]*$/) &&
                    !cookingStep.timeBased.cookingTimeinMin
                      .toString()
                      .match(/^[1-9][0-9]*$/))
                ) {
                  isValid = false;
                }
              } else {
                isValid = false;
              }
            }

            if (
              selectedRadio === 'probeBased' &&
              (cookingStep.internalTemperature === null ||
                cookingStep.internalTemperature === '' ||
                cookingStep.internalTemperature === undefined)
            ) {
              isValid = false;
            }
          });
        } else {
          isValid = false;
        }

        if (selectedRadio === 'probeBased') {
          if (
            Object.keys(inputValue).length > 0 &&
            inputValue.totalTime === undefined
          ) {
            isValid = false;
          }

          if (inputValue.totalTime && inputValue.totalTime.probeBased) {
            if (
              (inputValue.totalTime.probeBased.time === '' &&
                inputValue.totalTime.probeBased.timeinMin === '') ||
              (inputValue.totalTime.probeBased.time === '' &&
                inputValue.totalTime.probeBased.timeinMin === undefined) ||
              (inputValue.totalTime.probeBased.time === undefined &&
                inputValue.totalTime.probeBased.timeinMin === '') ||
              (inputValue.totalTime.probeBased.time === 0 &&
                inputValue.totalTime.probeBased.timeinMin === 0)
            ) {
              isValid = false;
            } else if (
              (inputValue.totalTime.probeBased.time &&
                !inputValue.totalTime.probeBased.time
                  .toString()
                  .match(/^[1-9][0-9]*$/)) ||
              (inputValue.totalTime.probeBased.timeinMin &&
                !inputValue.totalTime.probeBased.timeinMin
                  .toString()
                  .match(/^[1-9][0-9]*$/)) ||
              (inputValue.totalTime.probeBased.time &&
                inputValue.totalTime.probeBased.timeinMin &&
                !inputValue.totalTime.probeBased.time
                  .toString()
                  .match(/^[1-9][0-9]*$/) &&
                !inputValue.totalTime.probeBased.timeinMin
                  .toString()
                  .match(/^[1-9][0-9]*$/))
            ) {
              isTypeErrorForProbeTime.push(true);
              isValid = false;
            } else {
              isTypeErrorForProbeTime.push(false);
            }
          } else if (
            inputValue.totalTime &&
            inputValue.totalTime.probeBased === undefined
          ) {
            isValid = false;
          }
        }
      });
    } else {
      isValid = false;
    }

    this.setState({
      isValid,
      isTypeError,
      urlError,
      isTypeErrorForProbeTime,
    });
    return isValid;
  };

  resetData = () => {
    this.setState({
      selectedRegion: '',
      selectedCountry: '',
      selectedCavity: [],
      isValid: false,
    });
  };

  handleChange = (e, { value }) => {
    const { postFormData, regions } = this.props;
    const item = regions.data.find((x) => x._id === value);
    this.setState({ selectedRegion: value, units: item.units });
    postFormData({ selectedRegion: value, units: item.units });
  };

  handleCountryChange = (e, { value }) => {
    const { postFormData } = this.props;
    this.setState({ selectedCountry: value });
    postFormData({ selectedCountry: value });
  };

  calculateTimeinMin = (time) => {
    const timeInhr = get(time, 'totalTime.probeBased.time') || 0;
    const timeInMin = get(time, 'totalTime.probeBased.timeinMin') || 0;
    const total = Number(timeInMin) + Number(timeInhr) * 60;
    return total;
  };

  updateProgram = async () => {
    if (!this.validateData()) return;
    const { data } = this.props.program;
    const extraQuantity =
      typeof data.extraQuantity === 'object'
        ? data.extraQuantity[0]
        : data.extraQuantity;
    const payload = {
      title: data.programTitle,
      internalTitle: data.internalTitle,
      categoryId:
        (data.categoryValue && data.categoryValue.id) || data.categoryValue,
      tags: data.tags,
      filters: data.filterValue,
      reference: {
        description: data.description2,
        urls: [data.refUrlOne, data.refUrlTwo],
      },
      description: data.description,
      images: data.fileBlobUrl && data.fileBlobUrl.map((item) => item.id),
      regionId: data.selectedRegion,
      countryId: data.selectedCountry,
      cavities: data.selectedCavity,
      userInstructions:
        data.instructionArr &&
        data.instructionArr.map((item, index) => {
          const image = item.fileBlobUrl ? item.fileBlobUrl.id : null;
          return {
            instruction: item.instruction,
            url: item.refUrl,
            icon: item.selectedIconId,
            image,
            order: index,
          };
        }),
      baseParameters: {
        key: data.selectedbaseRadio,
        value: data.selectedbaseRadioValue,
        quantity: data.selectedQuantity,
      },
      type: data.selectedRadio === 'timeBased' ? 'time' : 'probe',
      preheat:
        data.preheatAlert && data.preheatAlert.alert === true
          ? { text: data.preheatAlert.value }
          : null,
      operations:
        data.inputValue &&
        data.inputValue.map((e) => {
          return {
            outcome: e.outcome,
            image: e.image && e.image.id,
            time:
              data.type === 'timeBased'
                ? e.precedingCookingStep &&
                  e.precedingCookingStep.reduce((a, b) => {
                    let sum = a;
                    if (b && b.timeBased && b.timeBased.cookingTime)
                      sum += Number(b.timeBased.cookingTime * 60);
                    if (b && b.timeBased && b.timeBased.cookingTimeinMin)
                      sum += Number(b.timeBased.cookingTimeinMin);
                    return sum;
                  }, 0)
                : this.calculateTimeinMin(e),
            variations: {
              key: data.selectedbaseRadio,
              variation:
                e.cookingTimeVariation && e.cookingTimeVariation.thicknessTime,
              value: data.extraThickness[0],
              quantity: {
                variation:
                  e.cookingTimeVariation && e.cookingTimeVariation.quantityTime,
                value: extraQuantity,
              },
            },
            steps:
              e.precedingCookingStep &&
              e.precedingCookingStep.map((step, i) => {
                return {
                  mode: data.cookingMode && data.cookingMode[i].key,
                  temperature: step.temperature,
                  cookingTime:
                    data.selectedRadio === 'timeBased'
                      ? Number(step.timeBased.cookingTime * 60) +
                        Number(step.timeBased.cookingTimeinMin)
                      : 0,
                  internalTemperature: step.internalTemperature,
                  alert: data.alert && data.alert[i],
                  order: i,
                };
              }),
          };
        }),
    };
    const response = await programsApi.updateProgram(
      payload,
      this.props.match.params.id
    );
    if (response.response) {
      const { id } = this.props.match.params;
      this.props.history.push(`/programs/${id}`);
    }
    if (response.error) {
      // console.log(response.error);
      // return;
    }
  };

  cancelProgram = () => {
    this.props.history.push('/programs');
  };

  handleCavityChange = (e, cavityModes) => {
    const { postFormData } = this.props;
    this.setState({ cavityModes });
    const cavities = e.map((cavity) => {
      return cavity.value;
    });
    this.setState({ selectedCavity: cavities });
    postFormData({ selectedCavity: cavities });
  };

  render() {
    const { brands, program, isLoading } = this.props;
    const {
      selectedRegion,
      selectedCountry,
      commonModes,
      selectedCavity,
      openModal,
      visible,
      isValid,
      units,
      isTypeError,
      isTypeErrorForProbeTime,
      urlError,
    } = this.state;
    const data = brands && brands.data ? brands : 0;
    const programData = program && program.data ? program.data : 0;
    const id = programData && programData.id;

    if (isLoading) {
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
    }
    return (
      <Sidebar.Pushable as={Segment} className="d-flex">
        <Navbar name="createPrograms" />
        <Sidebar.Pusher>
          <MainHeader name="Edit Programs" />
          {/* below commented code is needed */}
          <MainBreadcrumb
            step1="Dashboard"
            step2={programData && programData.programTitle}
            step2Link={`/programs/${id}`}
            step3="Edit Program"
          />
          <div className="inner-wrapper">
            <Form>
              <Grid>
                <TopSectionCreateProgram
                  isValid={isValid}
                  urlError={urlError}
                />
                <Grid.Row>
                  <Grid.Column verticalAlign="middle" width={16}>
                    <Form.Group>
                      <Form.Field
                        width="4"
                        error={
                          programData &&
                          !programData.selectedRegion &&
                          !selectedRegion &&
                          !isValid
                            ? 'error'
                            : ''
                        }
                      >
                        <label>Region</label>
                        <Dropdown
                          className="large"
                          options={[...this.getregions()]}
                          placeholder="Region"
                          selection
                          fluid
                          name="selectedRegion"
                          value={
                            programData && programData.selectedRegion
                              ? programData.selectedRegion
                              : selectedRegion
                          }
                          onChange={(e, regionValue) =>
                            this.handleChange(e, regionValue)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        width="4"
                        error={
                          programData &&
                          !programData.selectedCountry &&
                          !selectedCountry &&
                          !isValid
                            ? 'error'
                            : ''
                        }
                      >
                        <label>Country</label>
                        <Dropdown
                          className="large"
                          options={[...this.getcountries()]}
                          placeholder="Country"
                          selection
                          fluid
                          name="selectedCountry"
                          value={
                            programData && programData.selectedCountry
                              ? programData.selectedCountry
                              : selectedCountry
                          }
                          onChange={(e, countryValue) =>
                            this.handleCountryChange(e, countryValue)
                          }
                        />
                      </Form.Field>
                      <Form.Field
                        width="7"
                        error={
                          programData &&
                          programData.selectedCavity &&
                          !programData.selectedCavity.length > 0 &&
                          !selectedCavity.length > 0 &&
                          !isValid
                            ? 'error'
                            : ''
                        }
                        className="select-cavity-drpdown"
                      >
                        <label>Select Cavity</label>
                        {data && data.data ? (
                          <div>{this.getbrands(data.data, programData)}</div>
                        ) : (
                          <Dropdown.Menu>
                            <Dropdown.Item text="No brands" />
                          </Dropdown.Menu>
                        )}
                      </Form.Field>
                      <Form.Field width="1" className="text-center plr-0">
                        <label className="visibility-hidden">View</label>
                        <Modal
                          size="tiny"
                          closeIcon
                          onClose={() => this.setState({ openModal: false })}
                          onOpen={() => this.setState({ openModal: true })}
                          open={openModal}
                          trigger={
                            <Button
                              className="link-button"
                              disabled={
                                commonModes && commonModes.length ? '' : 'false'
                              }
                            >
                              View modes
                            </Button>
                          }
                        >
                          <Modal.Header>List ofAvailable Modes</Modal.Header>
                          <Modal.Content>
                            {commonModes && commonModes.length ? (
                              <List divided relaxed>
                                {this.listOfModes()}
                              </List>
                            ) : (
                              <div>No modes</div>
                            )}
                          </Modal.Content>
                        </Modal>
                      </Form.Field>
                    </Form.Group>
                    <Sidebar.Pushable
                      as={Segment}
                      className="create-program-sidebar"
                    >
                      <div
                        className={`${
                          visible === true ? 'visible' : ''
                        } collapsible-header`}
                      >
                        <Button
                          icon={`angle double ${
                            visible === true ? 'right' : 'left'
                          }`}
                          onClick={() => this.setState({ visible: !visible })}
                        />
                        <Header as="h4">User Instructions</Header>
                      </div>
                      <Sidebar
                        // animation="push"
                        // onHide={() => this.setState({ visible: false })}
                        visible={visible}
                        direction="right"
                      >
                        <UserInstructionForm />
                      </Sidebar>

                      <Sidebar.Pusher>
                        <OvenOperationsForm
                          units={units}
                          commonModes={
                            commonModes && commonModes.length
                              ? commonModes
                              : null
                          }
                          isValid={isValid}
                          isTypeError={isTypeError}
                          isTypeErrorForProbeTime={isTypeErrorForProbeTime}
                        />
                        <Divider section />
                        {/* <TimeAlertsForm /> */}
                      </Sidebar.Pusher>
                    </Sidebar.Pushable>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="16">
                    {isValid === false ? (
                      <p className="error-note text-center position-relative">
                        Please fill correct data
                      </p>
                    ) : (
                      ''
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider hidden section />
              <div className="button-wrapper text-center">
                <Button secondary compact onClick={() => this.updateProgram()}>
                  Update
                </Button>
                <Button compact primary onClick={() => this.cancelProgram()}>
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.program.program.isLoading,
    regions: state.regions.regions,
    brands: state.brands.brands,
    programs: state.programs.programs,
    program: state.program.program,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRegion: () => dispatch(fetchregionRequested()),
    fetchBrands: () => dispatch(fetchBrandsRequested()),
    fetchProgram: (id) => dispatch(programRequested({ id, isTransform: true })),
    postFormData: (data) => dispatch(programFormUpdate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProgram);
