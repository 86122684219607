/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, Component } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Grid,
  Header,
  Input,
  Menu,
  Tab,
  Table,
  Dropdown,
  Button,
} from 'semantic-ui-react';
import CustomFunctions from '../../../utils/customFunctions';
import { fetchregionRequested } from '../../../state/actions/regionAction';
import regionApi from '../../../api/regionApi';
import InputWithIcons from '../../ui/InputWithIcons';
import { getLocalStorageValue } from '../../../utils/sharedFunctions';

const temperature = [
  {
    text: 'Fahrenheit',
    value: 'fahrenheit',
  },
  {
    text: 'Celsius',
    value: 'celsius',
  },
];

const weight = [
  {
    text: 'Pounds',
    value: 'lb',
  },
  {
    text: 'Grams',
    value: 'gm',
  },
];

const thickness = [
  {
    text: 'Inch',
    value: 'in',
  },
  {
    text: 'Centimeter',
    value: 'cm',
  },
];

class RegionCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputRegion: '',
      inputCountry: '',
      regionError: '',
      countryError: '',
      regionid: '',
      setIndex: null,
      setCountryIndex: null,
      temperatureUnit: '',
      weightUnit: '',
      thicknessUnit: '',
      updateUnitError: '',
    };
    this.fetchData();
  }

  fetchData = () => {
    this.props.fetchRegion();

    setTimeout(() => {
      const { regions } = this.props;
      if (regions && regions.data && regions.data.length > 0) {
        this.setState({
          temperatureUnit: regions.data[0].units.temperature,
          weightUnit: regions.data[0].units.weight,
          thicknessUnit: regions.data[0].units.thickness,
        });
      }
    }, 1000);
  };

  onHandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      regionError: '',
      countryError: '',
      updateUnitError: '',
    });
  };

  validateForm = (input) => {
    let isValid = true;
    if (CustomFunctions.checkIfEmpty(input)) {
      this.setState({ regionError: 'Please enter region name' });
      isValid = false;
    } else if (!input.match(/^[a-zA-Z-'&]+(\s{0,1}[a-zA-Z-'& ])*$/)) {
      this.setState({ regionError: `Only letters and -, ', & are accepted` });
      isValid = false;
    } else if (input.length > 50) {
      this.setState({ regionError: 'Maximum length should be 50 characters' });
      isValid = false;
    }
    return isValid;
  };

  validateCountryForm = (input) => {
    let isValid = true;
    if (CustomFunctions.checkIfEmpty(input)) {
      this.setState({ countryError: 'Please enter countryname' });
      isValid = false;
    } else if (!input.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
      this.setState({ countryError: 'Only letters are expected' });
      isValid = false;
    }
    return isValid;
  };

  onEditHandler = (index, inputRegion) => {
    this.setState({ setIndex: index, inputRegion, updateUnitError: '' });
  };

  onEditCountryHandler = (index, inputCountry) => {
    this.setState({ setCountryIndex: index, inputCountry });
  };

  onCheckHandler = async () => {
    const {
      inputRegion,
      regionid,
      temperatureUnit,
      weightUnit,
      thicknessUnit,
    } = this.state;
    if (inputRegion && !this.validateForm(inputRegion)) return;

    /* eslint-disable eqeqeq */
    if ((temperatureUnit && weightUnit && thicknessUnit) != '') {
      /* eslint-enable eqeqeq */
      const payload = {
        name: inputRegion,
        units: {
          temperature: temperatureUnit,
          weight: weightUnit,
          thickness: thicknessUnit,
        },
      };
      const response = await regionApi.updateRegions(payload, regionid);
      if (response.error) {
        this.setState({ regionError: response.error.message });
        return;
      }
      this.setState({
        regionError: '',
        inputRegion: '',
        setIndex: null,
        updateUnitError: '',
      });
      this.props.fetchRegion();
    } else {
      this.setState({ updateUnitError: 'Click on any one region.' });
    }
  };

  onCheckCountryHandler = async (countryid) => {
    const { inputCountry, regionid } = this.state;
    if (!this.validateCountryForm(inputCountry)) return;
    const payload = {
      name: inputCountry,
    };
    const response = await regionApi.updateCountry(
      payload,
      regionid,
      countryid
    );
    if (response.error) {
      this.setState({ countryError: response.error.errors[0].detail });
      return;
    }
    this.setState({
      countryError: '',
      inputCountry: '',
      setCountryIndex: null,
    });
    this.props.fetchRegion();
  };

  onCloseHandler = () => {
    this.setState({ setIndex: null });
  };

  onCloseCountryHandler = () => {
    this.setState({ setCountryIndex: null });
  };

  addNewRegion = async () => {
    const { inputRegion } = this.state;
    if (!this.validateForm(inputRegion)) return;
    const payload = {
      name: inputRegion,
    };
    const response = await regionApi.addRegions(payload);
    if (response.error) {
      this.setState({ regionError: response.error.errors[0].detail });
      if (response.error.message === 'Invalid token') {
        localStorage.clear();
        window.location.href = '/login';
      }
      return;
    }
    this.setState({
      regionError: '',
      inputRegion: '',
      temperatureUnit: 'fahrenheit',
      weightUnit: 'lb',
      thicknessUnit: 'in',
      updateUnitError: '',
    });
    this.props.fetchRegion();
  };

  addNewCountry = async () => {
    const { inputCountry, regionid } = this.state;
    if (!this.validateCountryForm(inputCountry)) return;
    const country = inputCountry.split(',');
    const payload = {
      countries: country,
    };
    const response = await regionApi.addCountries(payload, regionid);
    if (response.error) {
      this.setState({ countryError: response.error.errors[0].detail });

      if (response.error.message === 'Invalid token') {
        localStorage.clear();
        window.location.href = '/login';
      }
      return;
    }
    this.setState({ countryError: '', inputCountry: '' });
    this.props.fetchRegion();
  };

  onDeleteHandler = async (region, index) => {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    const regionId = region._id;
    const { regions } = this.props;

    /* eslint-disable no-alert, no-console */
    if (window.confirm('Do you really want to delete this region?')) {
      /* eslint-enable no-alert, no-console */
      const response = await regionApi.deleteRegions(regionId);
      if (response.error) {
        this.setState({ regionError: response.error.errors[0].detail });
        return;
      }
      this.setState({
        regionError: '',
        inputRegion: '',
        updateUnitError: '',
      });
      // eslint-disable-next-line eqeqeq
      if (regions.data.length - 1 == index) {
        this.setState({
          temperatureUnit: '',
          weightUnit: '',
          thicknessUnit: '',
        });
        this.props.fetchRegion();
      } else {
        this.fetchData();
      }
    }
  };

  onDeleteCountryHandler = async (countryId) => {
    /* eslint-disable no-alert, no-console */
    if (window.confirm('Do you really want to delete this country?')) {
      /* eslint-enable no-alert, no-console */
      const response = await regionApi.deleteCountry(
        this.state.regionid,
        countryId
      );
      if (response.error) {
        this.setState({ countryError: response.error.message.detail });
        return;
      }
      this.setState({ countryError: '', inputCountry: '' });
      this.props.fetchRegion();
    }
  };

  temperatureChange = (e, { value }) =>
    this.setState({ temperatureUnit: value, updateUnitError: '' });

  weightChange = (e, { value }) =>
    this.setState({ weightUnit: value, updateUnitError: '' });

  thicknessChange = (e, { value }) =>
    this.setState({ thicknessUnit: value, updateUnitError: '' });

  setCountries = (regions) => {
    const { setCountryIndex, inputCountry } = this.state;
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    this.state.regionid = regions._id;
    return (
      <>
        <Table striped basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Countries</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {regions && regions.countries && regions.countries.length > 0 ? (
              regions.countries.map((el, indexValue) => (
                <>
                  {setCountryIndex === indexValue ? (
                    <Table.Row>
                      <Table.Cell width="16">
                        <InputWithIcons
                          value={inputCountry}
                          onInputchange={(e) =>
                            this.setState({ inputCountry: e.target.value })
                          }
                          onClickCheck={() => this.onCheckCountryHandler(el.id)}
                          onClickClose={() => this.onCloseCountryHandler()}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    <>
                      <Table.Row>
                        <Table.Cell width="16">
                          <div className="input-with-icons">
                            <div className="input-wrap">{el.name}</div>
                            <div className="icon-wrap">
                              <span
                                className="edit-icon"
                                onClick={() =>
                                  this.onEditCountryHandler(indexValue, el.name)
                                }
                              />
                              <span
                                className="trash-icon"
                                onClick={() =>
                                  this.onDeleteCountryHandler(el.id, el.name)
                                }
                              />
                            </div>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    </>
                  )}
                </>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan="8" textAlign="center">
                  <div className="no-data">No records found</div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </>
    );
  };

  setRegions = (regionList) => {
    const { setIndex, inputRegion } = this.state;
    const regiontabs =
      regionList && regionList.length > 0 ? (
        regionList.map((region, index) => ({
          menuItem: (
            <Menu.Item
              className="d-flex justify-content-between align-center"
              // eslint-disable-next-line no-param-reassign, no-underscore-dangle
              onClick={() =>
                this.setState({
                  regionid: region._id,
                  temperatureUnit: region.units.temperature,
                  weightUnit: region.units.weight,
                  thicknessUnit: region.units.thickness,
                  updateUnitError: '',
                  countryError: '',
                  inputCountry: '',
                })
              }
            >
              {setIndex === index ? (
                <>
                  <InputWithIcons
                    value={inputRegion}
                    onInputchange={(e) =>
                      this.setState({ inputRegion: e.target.value })
                    }
                    onClickCheck={() => this.onCheckHandler(index)}
                    onClickClose={() => this.onCloseHandler()}
                  />
                </>
              ) : (
                <>
                  <div className="input-with-icons">
                    <div className="input-wrap">{region.name}</div>
                    <div className="icon-wrap">
                      <span
                        className="edit-icon"
                        onClick={() => this.onEditHandler(index, region.name)}
                      />
                      <span
                        className="trash-icon"
                        onClick={() => this.onDeleteHandler(region, index)}
                      />
                    </div>
                  </div>
                </>
              )}
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane className="plr-0 no-border">
              {this.setCountries(region)}
            </Tab.Pane>
          ),
        }))
      ) : (
        <div className="no-data mt-125">No regions</div>
      );
    return regiontabs;
  };

  render() {
    const { regions } = this.props;
    const {
      regionError,
      countryError,
      inputRegion,
      inputCountry,
      temperatureUnit,
      weightUnit,
      thicknessUnit,
      updateUnitError,
    } = this.state;
    const data = regions && regions.data ? regions : 0;

    const role = getLocalStorageValue('role');

    return (
      <div
        className={`${!data && 'pb-50'} select-region-tabs regions-tab-wrap`}
      >
        <Grid className="m-0 region-grid">
          <Grid.Column width={4} className="bg-grey">
            <Header as="h4" className="font-regular">
              Region
            </Header>
            <Form className="mt--half">
              <Form.Field>
                <label className="visibility-hidden">Hidden</label>
                <Input
                  name="inputRegion"
                  value={inputRegion}
                  action={{
                    icon: 'plus',
                    onClick: () => this.addNewRegion(),
                    disabled: role !== 'admin',
                  }}
                  disabled={role !== 'admin'}
                  onChange={(e) => this.onHandleChange(e)}
                  placeholder="Add Region"
                  className="large"
                />
                {regionError ? <p className="error-note">{regionError}</p> : ''}
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width={12}>
            <Header as="h4" className="font-regular">
              Country
            </Header>
            <Form className="mt--half">
              <Form.Group>
                <Form.Field width="8">
                  <label className="visibility-hidden">Hidden</label>
                  <Input
                    name="inputCountry"
                    value={inputCountry}
                    action={{
                      icon: 'plus',
                      onClick: () => this.addNewCountry(),
                      disabled: role !== 'admin',
                    }}
                    disabled={role !== 'admin'}
                    onChange={(e) => this.onHandleChange(e)}
                    placeholder="Add multiple countries by comma-separated"
                    className="large"
                  />
                  {countryError ? (
                    <p className="error-note">{countryError}</p>
                  ) : (
                    ''
                  )}
                </Form.Field>
                <Form.Field width="3" className="left-divider">
                  <label htmlFor="tempraturename">Temperature</label>
                  <Dropdown
                    selection
                    options={temperature}
                    value={temperatureUnit}
                    disabled={role !== 'admin'}
                    onChange={(e, value) => this.temperatureChange(e, value)}
                    className="large"
                    fluid
                  />
                  {updateUnitError ? (
                    <p className="error-note">{updateUnitError}</p>
                  ) : null}
                </Form.Field>
                <Form.Field width="2">
                  <label htmlFor="weightename">Weight</label>
                  <Dropdown
                    selection
                    options={weight}
                    value={weightUnit}
                    disabled={role !== 'admin'}
                    onChange={(e, value) => this.weightChange(e, value)}
                    className="large"
                    fluid
                  />
                </Form.Field>
                <Form.Field width="3">
                  <label htmlFor="thicknessname">Thickness</label>
                  <Dropdown
                    selection
                    options={thickness}
                    value={thicknessUnit}
                    disabled={role !== 'admin'}
                    onChange={(e, value) => this.thicknessChange(e, value)}
                    className="large"
                    fluid
                  />
                </Form.Field>
                <div className="button-wrapper text-center">
                  <Button
                    type="submit"
                    secondary
                    disabled={role !== 'admin'}
                    onClick={() => this.onCheckHandler()}
                    className="m-0 mr--10"
                  >
                    Update
                  </Button>
                </div>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
        {data && data.data ? (
          <Tab
            menu={{
              pointing: true,
              fluid: true,
              vertical: true,
              tabular: true,
            }}
            panes={this.setRegions(data.data)}
            className="region-country-tab"
          />
        ) : (
          <div className="no-data">No records found</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regions: state.regions.regions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRegion: () => dispatch(fetchregionRequested()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegionCountry);
