import ApiUtils from './apiUtils';

const fetchModes = async (param) => {
  const queryString = ApiUtils.mapObjectToQueryString(param);

  try {
    const response = await ApiUtils.get(`api/modes?${queryString}`);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const postModes = async (BRAND_ID, CAVITY_ID, param) => {
  try {
    const response = await ApiUtils.post(
      `api/brands/${BRAND_ID}/cavities/${CAVITY_ID}/modes`,
      param
    );
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const deleteModes = async (brandId, cavityId, key) => {
  try {
    const response = await ApiUtils.del(
      `api/brands/${brandId}/cavities/${cavityId}/modes/${key}`
    );
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const addModes = async (param) => {
  try {
    const response = await ApiUtils.post('api/modes', param);
    const { data } = response;
    return {
      response: {
        ...data,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

export default {
  fetchModes,
  postModes,
  deleteModes,
  addModes,
};
