import {
  BRANDS_REQUESTED,
  BRANDS_SUCCESSFUL,
  BRANDS_FAILURE,
} from '../../constants/actions';

export const fetchBrandsRequested = (data) => {
  return {
    type: BRANDS_REQUESTED,
    payload: data,
  };
};

export const fetchBrandsSuccessful = (data) => {
  return {
    type: BRANDS_SUCCESSFUL,
    payload: data,
  };
};

export const fetchBrandsFailure = (data) => {
  return {
    type: BRANDS_FAILURE,
    payload: data,
  };
};
