import axios from 'axios';
import { normalize } from 'normalizr';
import { programUsersList } from '../state/schemas';
import ApiUtils from './apiUtils';
import { getLocalStorageValue } from '../utils/sharedFunctions';

const fetchProgramUsers = async (programId, versionId) => {
  try {
    const response = await ApiUtils.get(
      `api/programs/${programId}/versions/${versionId}/users`
    );
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, programUsersList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const fetchProgramVariationUsers = async ({
  programId,
  variationId,
  userId,
}) => {
  try {
    const response = await ApiUtils.get(
      `api/programs/${programId}/versions/${variationId}/users/${userId}`
    );
    const { data } = response;
    // Normalize the rules
    const rules =
      data.rules && data.rules.length > 0
        ? normalize(data.rules, programUsersList)
        : [];
    return {
      response: {
        ...data,
        rules,
      },
    };
  } catch (error) {
    return {
      error: {
        ...error.data,
      },
    };
  }
};

const userUpdateFeedback = (feedback, id) => {
  const tokenUrl = `${process.env.REACT_APP_API_ROOT}/api/programs/${id}/properties`;
  const token = getLocalStorageValue('accessToken');
  return axios
    .put(tokenUrl, feedback, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
    .then((response) => {
      const { data } = response.data;
      return {
        response: {
          ...data,
        },
      };
    })
    .catch((e) => {
      const { data } = e.response;
      return {
        error: {
          ...data,
        },
      };
    });
};

export default {
  fetchProgramUsers,
  fetchProgramVariationUsers,
  userUpdateFeedback,
};
