import { all } from 'redux-saga/effects';
import authSagas from './authSagas';
import dashboardSaga from './dashboardSaga';
import userManagementSaga from './userManagementSaga';
import categorySagas from './categorySagas';
import brandsSagas from './brandsSagas';
import regionSaga from './regionSaga';
import modesSagas from './modesSagas';
import filterSaga from './filterSagas';
import filesSagas from './filesSagas';
import tagsSagas from './tagsSagas';
import programSagas from './programSaga';
import programsSagas from './programsSagas';
import programUsersSaga from './programUsersSaga';
import dashboardSummarySagas from './dashboardSummarySagas';

export default function* rootSaga() {
  yield all([
    // Add sagas here
    authSagas(),
    dashboardSaga(),
    userManagementSaga(),
    categorySagas(),
    brandsSagas(),
    regionSaga(),
    modesSagas(),
    filterSaga(),
    filesSagas(),
    tagsSagas(),
    programSagas(),
    programsSagas(),
    programUsersSaga(),
    dashboardSummarySagas(),
  ]);
}
