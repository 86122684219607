import { combineReducers } from 'redux';
import {
  PROGRAM_USER_REQUESTED,
  PROGRAM_USER_FAILURE,
  PROGRAM_USER_SUCCESSFUL,
  SESSION_USERFEEDBACKUPDATE,
} from '../../constants/actions';

const programUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case PROGRAM_USER_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case PROGRAM_USER_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    case PROGRAM_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case SESSION_USERFEEDBACKUPDATE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default combineReducers({
  programUsers: programUsersReducer,
});
