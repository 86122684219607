import {
  DASHBOARD_PROGRAMS_FAILURE,
  DASHBOARD_PROGRAMS_REQUESTED,
  DASHBOARD_PROGRAMS_SUCCESSFUL,
  DASHBOARD_SUMMARY_REQUESTED,
  DASHBOARD_SUMMARY_SUCCESSFUL,
  DASHBOARD_SUMMARY_FAILURE,
  DASHBOARD_CHARTDATA_REQUESTED,
  DASHBOARD_CHARTDATA_SUCCESSFUL,
  DASHBOARD_CHARTDATA_FAILURE,
} from '../../constants/actions';

export const fetchDashboardProgramsRequested = (data) => {
  return {
    type: DASHBOARD_PROGRAMS_REQUESTED,
    payload: data,
  };
};

export const fetchDashboardProgramsSuccessful = (data) => {
  return {
    type: DASHBOARD_PROGRAMS_SUCCESSFUL,
    payload: data,
  };
};

export const fetchDashboardProgramsFailure = (data) => {
  return {
    type: DASHBOARD_PROGRAMS_FAILURE,
    payload: data,
  };
};

export const fetchDashboardSummaryRequested = (data) => {
  return {
    type: DASHBOARD_SUMMARY_REQUESTED,
    payload: data,
  };
};

export const fetchDashboardSummarySuccessful = (data) => {
  return {
    type: DASHBOARD_SUMMARY_SUCCESSFUL,
    payload: data,
  };
};

export const fetchDashboardSummaryFailure = (data) => {
  return {
    type: DASHBOARD_SUMMARY_FAILURE,
    payload: data,
  };
};

export const fetchDashboardChartDataRequested = (data) => {
  return {
    type: DASHBOARD_CHARTDATA_REQUESTED,
    payload: data,
  };
};

export const fetchDashboardChartDataSuccessful = (data) => {
  return {
    type: DASHBOARD_CHARTDATA_SUCCESSFUL,
    payload: data,
  };
};

export const fetchDashboardChartDataFailure = (data) => {
  return {
    type: DASHBOARD_CHARTDATA_FAILURE,
    payload: data,
  };
};
