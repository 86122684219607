import { React, Component } from 'react';
import { connect } from 'react-redux';
import { Form, Grid, Header, Image, Input, List } from 'semantic-ui-react';
import trashBlue from '../../../assets/images/trash-blue.svg';
import { fetchFilterRequested } from '../../../state/actions/filterAction';
import filtersApi from '../../../api/filtersApi';
import CustomFunctions from '../../../utils/customFunctions';
import { getLocalStorageValue } from '../../../utils/sharedFunctions';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      error: '',
    };
    this.fetchData();
  }

  fetchData = () => {
    this.props.fetchFilter();
  };

  onChange = (event) => {
    this.setState({ filterValue: event.target.value, error: '' });
  };

  validation = () => {
    const { filterValue } = this.state;
    let isValid = true;
    if (CustomFunctions.checkIfEmpty(filterValue)) {
      this.setState({ error: 'Please enter filter name' });
      isValid = false;
    } else if (!filterValue.match(/^[a-zA-Z]+$/)) {
      this.setState({ error: 'Only letters are expected' });
      isValid = false;
    }
    return isValid;
  };

  addNewFilter = async () => {
    if (!this.validation()) return;
    const payload = {
      name: this.state.filterValue,
    };
    const response = await filtersApi.addFilter(payload);
    if (response.error) {
      this.setState({ error: response.error.message });
      return;
    }
    this.setState({ filterValue: '', error: '' });
    this.props.fetchFilter();
  };

  onDeleteHandler = async (filter) => {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    const filterId = filter._id;
    const response = await filtersApi.deleteFilter(filterId);
    if (response.error) {
      this.setState({ error: response.error.message });
      return;
    }
    this.props.fetchFilter();
  };

  render() {
    const { filters } = this.props;
    const { filterValue, error } = this.state;
    const role = getLocalStorageValue('role');

    return (
      <div className="select-region-tabs">
        <Grid className="m-0 region-grid">
          <Grid.Column width={5} className="p-0">
            <Header as="h5" className="font-regular">
              Filter
            </Header>
            <Form>
              <Form.Field>
                <Input
                  action={{
                    icon: 'plus',
                    onClick: this.addNewFilter,
                    disabled: role !== 'admin',
                  }}
                  value={filterValue}
                  onChange={this.onChange}
                  placeholder="Enter Filter Name"
                  disabled={role !== 'admin'}
                />
                {error ? <p className="error-note">{error}</p> : ''}
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h5" block className="no-border list-header m-0">
                Filters
              </Header>
              <List
                divided
                verticalAlign="middle"
                relaxed
                className="striped-list"
              >
                {filters && filters.data && filters.data.length > 0 ? (
                  filters.data.map((filter) => (
                    <List.Item>
                      <List.Content floated="right">
                        <Image
                          src={trashBlue}
                          onClick={() => this.onDeleteHandler(filter)}
                          className="pointer"
                        />
                      </List.Content>
                      <List.Content>{filter.name}</List.Content>
                    </List.Item>
                  ))
                ) : (
                  <List.Item>
                    <List.Content className="text-center no-data">
                      No filters available
                    </List.Content>
                  </List.Item>
                )}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

// At the end of the file
const mapStateToProps = (state) => {
  return {
    filters: state.filters.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFilter: () => dispatch(fetchFilterRequested()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
